import React, { Component } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";

interface TableCellCheckboxProps {
    onChangeClick: any;
    value: any;
    enabled: boolean;
}

class TableCellCheckbox extends Component<TableCellCheckboxProps> {
    public render() {
        return (
            <TableCell align="center">
                <Checkbox color="primary" onChange={this.handleClick} checked={this.props.enabled} />
            </TableCell>
        );
    }

    private handleClick = () => {
        this.props.onChangeClick(this.props.value);
    }

}

export default TableCellCheckbox;
