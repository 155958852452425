import moment from 'moment-timezone';

export default class DaysOff {
    /**
     * Return le jour férié si la date est un jour férié de la liste daysOff, sinon retourne undefined
     * @param date Date
     * @param daysOff List of days off
     */
    public static getDayOff(date: moment.Moment, daysOff: IDayOff[]): IDayOff | undefined {
        return daysOff.find(dayOff => dayOff.date.isSame(date, "day"));
    }

    /**
     * Récupère les jours fériés de l'année
     * @param an Année
     */
    public static getDaysOff(an: number): IDayOff[] {
        // Fixed days off
        const JourAn: IDayOff = { date: moment.tz(an + "-01-01 00:00", this.zone), title: "Jour de l'an" };
        const FeteTravail: IDayOff = { date: moment.tz(an + "-05-01 00:00", this.zone), title: "Fête du travail" };
        const Victoire1945: IDayOff = { date: moment.tz(an + "-05-08 00:00", this.zone), title: "Victoire 1945" };
        const FeteNationale: IDayOff = { date: moment.tz(an + "-07-14 00:00", this.zone), title: "Fête nationale" };
        const Assomption: IDayOff = { date: moment.tz(an + "-08-15 00:00", this.zone), title: "Assomption" };
        const Toussaint: IDayOff = { date: moment.tz(an + "-11-01 00:00", this.zone), title: "Toussaint" };
        const Armistice: IDayOff = { date: moment.tz(an + "-11-11 00:00", this.zone), title: "Armistice" };
        const Noel: IDayOff = { date: moment.tz(an + "-12-25 00:00", this.zone), title: "Noël" };

        // Unfixed days off
        const { JourPaques, MoisPaques } = this.getJourMoisPaques(an);
        const Paques: IDayOff = { date: moment.tz(an + "-0" + MoisPaques + "-" + JourPaques + " 00:00", this.zone), title: "Pâques" };
        const LundiPaques: IDayOff = { date: moment.tz(an + "-0" + MoisPaques + "-" + JourPaques + " 00:00", this.zone).add(1, "days"), title: "Lundi de Pâques" };
        const Ascension: IDayOff = { date: moment.tz(an + "-0" + MoisPaques + "-" + JourPaques + " 00:00", this.zone).add(39, "days"), title: "Ascension" };
        const Pentecote: IDayOff = { date: moment.tz(an + "-0" + MoisPaques + "-" + JourPaques + " 00:00", this.zone).add(49, "days"), title: "Pentecôte" };

        return [JourAn, Paques, LundiPaques, FeteTravail, Victoire1945, Ascension, Pentecote, FeteNationale, Assomption, Toussaint, Armistice, Noel];
    }

    /**
     * Retourne le jour et le mois de pâques
     * @param year Année
     */
    public static getJourMoisPaques(year: number) {
        const G = year % 19;
        const C = Math.floor(+year / 100);
        const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
        const I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
        const J = (year * 1 + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
        const L = I - J;
        const MoisPaques = 3 + Math.floor((L + 40) / 44);
        const paques = L + 28 - 31 * Math.floor(MoisPaques / 4);
        let JourPaques = "" + paques;

        if (paques < 10) {
            JourPaques = "0" + paques;
        }

        return { JourPaques, MoisPaques };
    }

    private static zone: string = 'Europe/Paris';
}

export interface IDayOff {
    title: string;
    date: moment.Moment;
}