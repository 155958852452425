import React from "react";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import deepOrange from '@material-ui/core/colors/deepOrange';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';

import App from './App';

import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'gta365:*');
}


const theme = createTheme({
  palette: {
    primary: blue,
    secondary: red,
  },
  // typography: {
  //   useNextVariants: true,
  // }
});

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
