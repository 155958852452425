import React, { Component } from "react";

import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import API from '../../services/Api';

const styles = (theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      padding: theme.spacing(3),
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(),
  }
});

interface IProps extends WithStyles<typeof styles> {
  clientCreated: () => void;
}

interface IState {
  nomClient: string;
  compte: string;
  numClient: string;
}

class CreateClient extends Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      compte: "",
      nomClient: "",
      numClient: ""
    };
  }

  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" gutterBottom>Ajouter un nouveau client</Typography>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                id="client-name"
                label="Nom du client"
                onChange={this.onChange('nomClient')}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="client-compte"
                label="Client final"
                onChange={this.onChange('compte')}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="client-num"
                label="Numéro du client"
                onChange={this.onChange('numClient')}
                variant="outlined"
                placeholder="CXXX"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={this.handleClick} className={classes.button}>Ajouter</Button>
          </div>
        </Paper>
      </div>
    );
  }

  /**
   * Gestion des inputs
   */
  private onChange = (name: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [name]: event.currentTarget.value } as Pick<IState, keyof IState>);
  }

  /**
   * Création du client dans la liste lors du clic de validation
   */
  private handleClick = () => {
    const url = "sites/" + process.env.REACT_APP_SITEID + "/lists/" + process.env.REACT_APP_CLIENTS + "/items";
    const data = {
      Compte: this.state.compte,
      NumClient: this.state.numClient,
      Title: this.state.nomClient
    };

    API.post(url, { fields: data }).then(() => this.props.clientCreated()).catch(error => {
      // TO DO handle error
    });
  }
}

export default withStyles(styles)(CreateClient);
