import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import {
    AppBar,
    Avatar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@material-ui/core'
import deepPurple from '@material-ui/core/colors/deepPurple'
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles'

import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import HelpIcon from '@material-ui/icons/Help'
import HistoryIcon from '@material-ui/icons/History'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ListIcon from '@material-ui/icons/List'
import MenuIcon from '@material-ui/icons/Menu'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import USBIcon from '@material-ui/icons/Usb'
import TimelineIcon from '@material-ui/icons/Timeline'

import packageJson from '../../package.json'

import Auth from '../services/Auth'
import { inherits } from 'util'

const drawerWidth = 360

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            justifyContent: 'flex-end',
        },
        grow: {
            flexGrow: 1,
        },
        menuLink: {
            color: 'white',
            textDecoration: 'none',
        },
        hide: {
            display: 'none',
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        bigAvatar: {
            margin: 10,
            width: 60,
            height: 60,
        },
        purpleAvatar: {
            color: '#fff',
            backgroundColor: deepPurple[500],
        },
        blackText: {
            color: 'black',
        },
        MuiListItemAvatarRoot: {
            minWidth: "inherit",
            flexShrink: 0,
        }
    })

export interface IProps extends WithStyles<typeof styles, true> {
    theme: Theme
    access: string
    userPhoto: string
    isProjectManager: boolean
}

export interface IState {
    accessLevelToken: string
    anchorEl: null | HTMLElement
    anchorManage: null | HTMLElement
    superviseur: any
    manager: any
    openDrawer: boolean
    cdp: any
    contributeur: any
    user: any
    idTeams: any[]
    ids: string
    isOpen: boolean
    tabIds: string[]
}

class Searchbar extends Component<IProps, IState> {
    private authService: Auth

    constructor(props: Readonly<IProps>) {
        super(props)

        this.state = {
            accessLevelToken: localStorage.getItem(
                'accessLevelToken'
            ) as string,
            anchorEl: null,
            anchorManage: null,
            cdp: null,
            contributeur: null,
            idTeams: [],
            ids: '',
            isOpen: false,
            manager: null,
            openDrawer: false,
            superviseur: null,
            tabIds: [],
            user: null,
        }

        this.authService = new Auth()
    }

    public render() {
        const { classes, theme } = this.props
        const { anchorEl, anchorManage, openDrawer } = this.state
        const open = Boolean(anchorEl)
        const menu: any[] = []
        const managerLinks: any[] = []
        const superviseurLinks: any[] = []
        const projectManagerLinks: any[] = []

        if (this.props.access === 'superviseur') {
            superviseurLinks.push(
                <React.Fragment key={'superviseur'}>
                    {/* <Link to="/exportSuperviseur" className={classes.menuLink}>
                        <MenuItem onClick={this.handleCloseManage}>
                            Export
                        </MenuItem>
                    </Link> */}
                    <Link to="/ManageProfils" className={classes.menuLink}>
                        <MenuItem onClick={this.handleCloseManage} className={classes.blackText}>
                            Profils
                        </MenuItem>
                    </Link>
                    <Link to="/ManageTechno" className={classes.menuLink}>
                        <MenuItem onClick={this.handleCloseManage} className={classes.blackText}>
                            Technologies
                        </MenuItem>
                    </Link>
                </React.Fragment>
            )
        }

        if (
            this.props.access === 'superviseur' ||
            this.props.access === 'manager'
        ) {
            managerLinks.push(
                <React.Fragment key={'superviseurandmanager'}>
                    <Link to="/mc" className={classes.menuLink}>
                        <MenuItem onClick={this.handleCloseManage} className={classes.blackText}>
                            Gérer les clients
                        </MenuItem>
                    </Link>
                    <Link to="/mp" className={classes.menuLink}>
                        <MenuItem onClick={this.handleCloseManage} className={classes.blackText}>
                            Gérer les projets
                        </MenuItem>
                    </Link>
                </React.Fragment>
            )
        }

        var merged = projectManagerLinks.concat(managerLinks).concat(superviseurLinks);
        if (
            merged.length > 0 && 
            (this.props.access === 'superviseur' ||
            this.props.access === 'manager' ||
            this.props.isProjectManager)
        ) {
            menu.push(
                <React.Fragment key={'menu'}>
                    <IconButton
                        aria-owns={
                            anchorManage ? 'menu-manage-appbar' : undefined
                        }
                        aria-haspopup="true"
                        onClick={this.openManage}
                        color="inherit"
                    >
                        <SettingsIcon />
                    </IconButton>
                    <Menu
                        id="menu-manage-appbar"
                        anchorEl={anchorManage}
                        open={Boolean(anchorManage)}
                        onClose={this.handleCloseManage}
                    >
                        {projectManagerLinks}
                        {managerLinks}
                        {superviseurLinks}
                    </Menu>
                </React.Fragment>
            )
        }

        const username = this.authService.getAccount().userName
        const displayName = this.authService.getAccount().name

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.grow}
                            noWrap={true}
                        >
                            GTA 365
                        </Typography>
                        <a
                            href={
                                process.env.PUBLIC_URL +
                                "/Guide d'utilisation - GTA 365.pdf"
                            }
                            target="_blank"
                        >
                            <IconButton
                                color="inherit"
                                className={classes.menuLink}
                            >
                                <HelpIcon />
                            </IconButton>
                        </a>
                        <Link to="/" color="inherit">
                            <IconButton
                                color="inherit"
                                className={classes.menuLink}
                            >
                                <CalendarTodayIcon />
                            </IconButton>
                        </Link>
                        {this.props.isProjectManager && this.props.access === 'manager' && (
                            <Link to="/ProjectConsumption" color="inherit">
                                <IconButton
                                    color="inherit"
                                    className={classes.menuLink}
                                >
                                    <TimelineIcon />
                                </IconButton>
                            </Link>
                        )}
                        {menu}
                        <IconButton
                            aria-owns={open ? 'menu-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                        >
                            <ListItemAvatar className={classes.MuiListItemAvatarRoot}>
                                {this.props.userPhoto ? (
                                    <Avatar
                                        alt={displayName}
                                        src={
                                            'data:image/png;base64,' +
                                            this.props.userPhoto
                                        }
                                    />
                                ) : (
                                    <Avatar className={classes.purpleAvatar}>
                                        {displayName.split(' ')[0][0] +
                                            displayName.split(' ')[1][0]}
                                    </Avatar>
                                )}
                            </ListItemAvatar>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            color="inherit"
                            open={open}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={this.logout}>
                                Se déconnecter
                            </MenuItem>
                            <MenuItem color="textSecondary" disabled={true}>
                                {username}
                            </MenuItem>
                            <MenuItem color="textSecondary" disabled={true}>
                                GTA v{packageJson.version}
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer
                    open={openDrawer}
                    onClose={this.toggleDrawer(false)}
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer(false)}
                        onKeyDown={this.toggleDrawer(false)}
                    >
                        <div className={classes.drawerHeader}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {this.props.userPhoto ? (
                                        <Avatar
                                            alt={displayName}
                                            src={
                                                'data:image/png;base64,' +
                                                this.props.userPhoto
                                            }
                                        />
                                    ) : (
                                        <Avatar
                                            className={classes.purpleAvatar}
                                        >
                                            {displayName.split(' ')[0][0] +
                                                displayName.split(' ')[1][0]}
                                        </Avatar>
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={displayName}
                                    secondary={
                                        <Typography
                                            component="span"
                                            color="textPrimary"
                                        >
                                            {username}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'ltr' ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </div>
                        <Divider />
                        <List
                            subheader={
                                <ListSubheader component="div">
                                    CRAM
                                </ListSubheader>
                            }
                        >
                            <Link to="/" className={classes.menuLink}>
                                <ListItem
                                    button={true}
                                    onClick={this.handleDrawerClose}
                                >
                                    <ListItemIcon>
                                        <CalendarTodayIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={'Calendrier'}
                                        className={classes.blackText}
                                    />
                                </ListItem>
                            </Link>
                        </List>

                        {(this.props.access === 'superviseur' ||
                            this.props.access === 'manager') && (
                            <React.Fragment>
                                <Divider />
                                <List
                                    subheader={
                                        <ListSubheader component="div">
                                            Gestion
                                        </ListSubheader>
                                    }
                                >
                                    <Link
                                        to="/ProjectConsumption"
                                        className={classes.menuLink}
                                    >
                                        <ListItem
                                            button={true}
                                            onClick={this.handleDrawerClose}
                                        >
                                            <ListItemIcon>
                                                <TimelineIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Reporting projet'}
                                                className={classes.blackText}
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/mp" className={classes.menuLink}>
                                        <ListItem button={true}>
                                            <ListItemIcon>
                                                <ListIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Gérer les projets'}
                                                className={classes.blackText}
                                                color="black"
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/mc" className={classes.menuLink}>
                                        <ListItem button={true}>
                                            <ListItemIcon>
                                                <PeopleIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Gérer les clients'}
                                                className={classes.blackText}
                                                color="black"
                                            />
                                        </ListItem>
                                    </Link>
                                </List>
                            </React.Fragment>
                        )}

                        {this.props.access === 'superviseur' && (
                            <React.Fragment>
                                <Divider />
                                <List
                                    subheader={
                                        <ListSubheader component="div">
                                            Administration
                                        </ListSubheader>
                                    }
                                >
                                    {/* <Link
                                        to="/exportSuperviseur"
                                        className={classes.menuLink}
                                    >
                                        <ListItem button={true}>
                                            <ListItemIcon>
                                                <ImportExportIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Export'}
                                                color="black"
                                                className={classes.blackText}
                                            />
                                        </ListItem>
                                    </Link> */}
                                    <Link
                                        to="/ManageProfils"
                                        className={classes.menuLink}
                                    >
                                        <ListItem button={true}>
                                            <ListItemIcon>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Profils'}
                                                color="black"
                                                className={classes.blackText}
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link
                                        to="/ManageTechno"
                                        className={classes.menuLink}
                                    >
                                        <ListItem button={true}>
                                            <ListItemIcon>
                                                <USBIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Technologies'}
                                                color="black"
                                                className={classes.blackText}
                                            />
                                        </ListItem>
                                    </Link>
                                </List>
                            </React.Fragment>
                        )}
                    </div>
                </Drawer>
            </div>
        )
    }

    private toggleDrawer = (open: boolean) => () => {
        this.setState({ openDrawer: open })
    }

    private logout = (event: React.MouseEvent<any, MouseEvent>): void => {
        event.preventDefault()
        this.authService.logout()
    }

    private handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    private openManage = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorManage: event.currentTarget })
    }

    private handleClose = () => {
        this.setState({ anchorEl: null })
    }

    private handleCloseManage = () => {
        this.setState({ anchorManage: null })
    }

    private handleDrawerOpen = () => {
        this.setState({ openDrawer: true })
    }

    private handleDrawerClose = () => {
        this.setState({ openDrawer: false })
    }
}

export default withStyles(styles, { withTheme: true })(Searchbar)
