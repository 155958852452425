// tslint:disable-next-line:no-var-requires
const debug = require('debug');
const BASE = 'gta365';
interface COLOURS {
    error?: 'red',
    info?: 'blue',
    trace?: 'lightblue',
    warn?: 'pink',
    [key: string]: any;
}; // choose better colours :)

class Log {
    public trace(message: any, source: any) {
        return this.generateMessage('trace', message, source);
    }

    public info(message: any, source: any) {
        return this.generateMessage('info', message, source);
    }

    public warn(message: any, source: any) {
        return this.generateMessage('warn', message, source);
    }

    public error(message: any, source: any) {
        return this.generateMessage('error', message, source);
    }

    private generateMessage(level: string, message: any, source: any) {
        const namespace = `${BASE}:${level}`;
        const createDebug = debug(namespace);

        // Set the colour of the message based on the level
        const colour: COLOURS = {
            error: 'red',
            info: 'blue',
            trace: 'lightblue',
            warn: 'pink',
        };
        createDebug.color = colour[level];

        if (source) { createDebug(source, message); }
        else { createDebug(message); }
    }
}

export default new Log();
