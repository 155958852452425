import React, { Component } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";

interface TableCellCheckboxTitleProps {
    enabled: boolean;
    onChangeClick: any;
    title: string;
    value: any
}

class TableCellCheckboxTitle extends Component<TableCellCheckboxTitleProps> {
    public render() {
        return (
            <TableCell align="center">
                {this.props.title}
                <Checkbox color="primary" onChange={this.handleClick} checked={this.props.enabled} />
            </TableCell>
        );
    }

    private handleClick = () => {
        this.props.onChangeClick(this.props.value);
    }

}

export default TableCellCheckboxTitle;
