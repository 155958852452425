import { UserAgentApplication, AuthError, AuthResponse } from 'msal';
import { Promise as PM } from 'es6-promise';
import { msalApp } from './auth-utils';
import Log from './Log';

export default class PowerBiService {
    private static SCOPES = [
        'https://analysis.windows.net/powerbi/api/Report.Read.All',
    ];

    private static ENDPOINTS = {
        REFRESHPERMISSIONS:
            'https://api.powerbi.com/v1.0/myorg/refreshPermissions',
    };

    private static PBI_REPORTID = process.env.REACT_APP_PBI_REPORT_ID;

    public static instance: PowerBiService;

    private access_token: string;
    private embedUrl: string;
    private isAuth: boolean;
    public ready: Promise<any>;

    public static GetInstance(): PowerBiService {
        if (!this.instance) {
            this.instance = new PowerBiService();
        }
        return this.instance;
    }

    private constructor() {
        // attribute initialization
        this.access_token = '';
        this.embedUrl = '';
        this.isAuth = false;

        // Authenticate the service
        this.ready = this.authenticate();
    }

    public authenticate(): Promise<void> {
        const thisInstance = this;

        const authenticationRequest = {
            scopes: PowerBiService.SCOPES,
        };

        const msalInstance: UserAgentApplication = msalApp;

        function successHandler(response: AuthResponse) {
            if (response.tokenType == 'id_token') {
                thisInstance.authenticate();
            } else if (response.tokenType == 'access_token') {
                thisInstance.access_token = response.accessToken;
                thisInstance.isAuth = true;

                thisInstance.tryRefreshUserPermissions();
                thisInstance.getEmbedUrl();
            } else {
                Log.error(
                    'Unknown token type: ' + response.tokenType,
                    'PowerBiService:authenticate:successHandler'
                );
            }
        }

        function errorHandler(error: AuthError) {
            Log.error(error.message, 'PowerBIService::authenticate');
        }

        msalInstance.handleRedirectCallback(successHandler, errorHandler);

        if (msalInstance.getAccount()) {
            return msalInstance
                .acquireTokenSilent(authenticationRequest)
                .then(function (response: AuthResponse) {
                    thisInstance.access_token = response.accessToken;
                    thisInstance.isAuth = true;

                    return thisInstance.getEmbedUrl();
                })
                .catch(function (error: AuthError) {
                    if (error.name == 'InteractionRequiredAuthError') {
                        msalInstance.acquireTokenRedirect(
                            authenticationRequest
                        );
                    } else {
                        Log.error(
                            'Error: ' + error,
                            'PowerBiService::authenticate'
                        );
                    }
                }) as Promise<void>;
        } else {
            msalInstance.loginRedirect(authenticationRequest);
            return Promise.resolve(void 0);
        }
    }

    private tryRefreshUserPermissions() {
        fetch(PowerBiService.ENDPOINTS.REFRESHPERMISSIONS, {
            headers: {
                Authorization: 'Bearer ' + this.access_token,
            },
            method: 'POST',
        })
            .then((response) => {
                if (response.ok) {
                    Log.trace(
                        'Successfully refreshed user permissions',
                        'PowerBiService::tryRefreshUserPermissions'
                    );
                } else {
                    // Too many requests in an hour will cause the api to fail
                    if (response.status === 429) {
                        console.error(
                            'Permissions will be available in an hour'
                        );
                    } else {
                        Log.error(
                            'Error ' +
                                response.status +
                                ': ' +
                                response.statusText,
                            'PowerBiService::tryRefreshUserPermissions'
                        );
                    }
                }
            })
            .catch((error) => {
                Log.error(error, 'PowerBiService::tryRefreshUserPermissions');
            });
    }

    public getEmbedUrl(): Promise<string> {
        const thisInstance = this;

        if (this.embedUrl === '') {
            return fetch(
                'https://api.powerbi.com/v1.0/myorg/reports/' +
                    PowerBiService.PBI_REPORTID,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.access_token,
                    },
                    method: 'GET',
                }
            ).then((response) => {
                return response
                    .json()
                    .catch((error) => {
                        console.error(error);
                    })
                    .then((body) => {
                        if (response.ok) {
                            thisInstance.embedUrl = body['embedUrl'];
                            return thisInstance.embedUrl;
                        } else {
                            Log.error(
                                'Error ' +
                                    response.status +
                                    ': ' +
                                    response.statusText,
                                'PowerBiService::getEmbedUrl'
                            );
                            throw new Error(
                                'Error ' +
                                    response.status +
                                    ': ' +
                                    response.statusText
                            );
                        }
                    });
            }) as Promise<string>;
        } else {
            return new Promise((resolve, reject) => {
                resolve(this.embedUrl);
            });
        }
    }

    public getAccessToken(): string {
        return this.access_token;
    }

    public isAuthenticated(): boolean {
        return this.isAuth;
    }
}
