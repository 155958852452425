import React, { Component } from "react";

import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import SearchIcon from "@material-ui/icons/Search";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import TableCellDelete from "../Table/TableCellDelete";
import TableCellEdit from "../Table/TableCellEdit";
import TableDisplay from "../Table/TableDisplay";

import API from "../../services/Api";

import ProfilCigref from "../../interfaces/profil-cigref";

import MySnackbar from "../MySnackbar";

import Loading from "../Loader";

const styles = (theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(),
    },
    root: {
      flexGrow: 1,
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 400,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    paper: {
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      marginTop: 15,
    },
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: 200,
    },
    input: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface State {
  confirme: boolean;
  debutant: boolean;
  expert: boolean;
  filter: string;
  filtered: boolean;
  junior: boolean;
  senior: boolean;
  loading: boolean;
  maxNumProfils: number;
  messageSnackBar: string;
  nameProfil: string;
  nameProfilModal: string;
  modal: boolean;
  openSnackBar: boolean;
  profils: ProfilCigref[];
  profilsFilter: ProfilCigref[];
  selectedProfil: null | ProfilCigref;
  typeSnackBar: "success" | "warning" | "error" | "info";
  [key: string]: any;
}

class ManageProfils extends Component<Props, State> {
  private columns: any[];

  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      confirme: false,
      debutant: false,
      expert: false,
      filter: "",
      filtered: false,
      junior: false,
      senior: false,
      loading: true,
      maxNumProfils: 1,
      messageSnackBar: "",
      nameProfil: "",
      nameProfilModal: "",
      modal: false,
      openSnackBar: false,
      profils: [],
      profilsFilter: [],
      selectedProfil: null,
      typeSnackBar: "error",
    };
    this.columns = ["Profil", "Modifier", "Supprimer"];
    this.columns = this.columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public componentDidMount() {
    this.refreshData();
  }

  public render() {
    const { classes } = this.props;
    const rows: any[] = [];
    let i: number = 0;
    let profils: ProfilCigref[];

    if (this.state.filtered) {
      profils = this.state.profils
        .filter((item) =>
          item.nomProfil
            .toLocaleLowerCase()
            .includes(this.state.filter.toLocaleLowerCase())
        )
        .sort(this.compare);
    } else {
      profils = this.state.profils.sort(this.compare);
    }

    for (const profil of profils) {
      if (profil.createdDateTime) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const isNew = new Date(profil.createdDateTime) >= yesterday;
        rows.push(
          <TableRow key={i}>
            <TableCell align="center">
              {profil.nomProfil}{" "}
              {isNew ? (
                <Chip
                  label="Nouveau"
                  color="secondary"
                  className={classes.chip}
                />
              ) : null}
            </TableCell>
            <TableCellEdit value={profil} onEditClick={this.editProfil} />
            <TableCellDelete value={profil} onDeleteClick={this.deleteProfil} />
          </TableRow>
        );
        i++;
      }
    }
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Ajouter un nouveau profil cigref
        </Typography>
        <Grid container direction="column">
          <Grid item md={12}>
            <TextField
              id="nomProfil"
              label="Nom du profil"
              className={classes.textField}
              value={this.state.nameProfil}
              onChange={this.onChangeNameProfil}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12}>
            <Label for="niveau" className="mr-sm-2">
              Niveau :{" "}
            </Label>
            <FormControlLabel
              label="Débutant"
              control={
                <Switch
                  checked={this.state.debutant}
                  onChange={this.onChangeLevel("debutant")}
                  value="debutant"
                />
              }
            />
            <FormControlLabel
              label="Junior"
              control={
                <Switch
                  checked={this.state.junior}
                  onChange={this.onChangeLevel("junior")}
                  value="junior"
                />
              }
            />
            <FormControlLabel
              label="Confirmé"
              control={
                <Switch
                  checked={this.state.confirme}
                  onChange={this.onChangeLevel("confirme")}
                  value="confirme"
                />
              }
            />
            <FormControlLabel
              label="Sénior"
              control={
                <Switch
                  checked={this.state.senior}
                  onChange={this.onChangeLevel("senior")}
                  value="senior"
                />
              }
            />
            <FormControlLabel
              label="Expert"
              control={
                <Switch
                  checked={this.state.expert}
                  onChange={this.onChangeLevel("expert")}
                  value="expert"
                />
              }
            />
          </Grid>
          <Grid item md={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.createProfils}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
        {this.state.loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Paper className={classes.root} elevation={1}>
              <InputBase
                className={classes.input}
                placeholder="Rechercher..."
                onChange={this.onChangeFilter}
              />
              <IconButton className={classes.iconButton} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <TableDisplay rows={rows} columns={this.columns} />
          </React.Fragment>
        )}
        {this.state.selectedProfil && (
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Modification</ModalHeader>
            <ModalBody>
              <Label for="nomProfilModal" className="mr-sm-2">
                Nom du profil :{" "}
              </Label>
              <Input
                type="text"
                name="nomProfilModal"
                id="nomProfilModal"
                value={this.state.nameProfilModal}
                onChange={this.onChangeNameProfilModal}
                required={true}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.changeProfil}>
                Enregistrer
              </Button>
              <Button color="secondary" onClick={this.toggle}>
                Annuler
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={this.onCloseSnackBar}
        >
          <MySnackbar
            variant={this.state.typeSnackBar}
            message={this.state.messageSnackBar}
            onClose={this.onClickSnackBar}
          />
        </Snackbar>
      </Paper>
    );
  }

  private compare(a: ProfilCigref, b: ProfilCigref): number {
    if (a.nomProfil.toLocaleLowerCase() < b.nomProfil.toLocaleLowerCase()) {
      return -1;
    } else if (
      a.nomProfil.toLocaleLowerCase() > b.nomProfil.toLocaleLowerCase()
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  private refreshData = () => {
    this.getMaxNumProfil().then(() => this.getProfils());
  };

  private getProfils() {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PROFILS +
      "/items?";
    const expand =
      "expand=fields(select=id,numProfil,nomProfil,createdDateTime)";

    API.get(url + expand)
      .then((response) => {
        const profils: ProfilCigref[] = [];

        for (const profil of response.data.value) {
          const createdDateTime: Date = profil.createdDateTime;
          profils.push({
            createdDateTime,
            id: +profil.fields.id,
            nomProfil: profil.fields.nomProfil,
            numProfil: profil.fields.numProfil,
          });
        }

        this.setState({ loading: false, profils });
      })
      .catch((error) => {
        this.openSnackBar(
          "Erreur lors du chargement des profils, veuillez rafraichir la page",
          "error"
        );
      });
  }

  private addLevel(): ProfilCigref[] {
    let maxNumProfils = this.state.maxNumProfils;
    const nameProfil = this.state.nameProfil;
    const levelProfils: ProfilCigref[] = [];
    const today = new Date();

    if (
      !this.state.debutant &&
      !this.state.junior &&
      !this.state.confirme &&
      !this.state.junior &&
      !this.state.senior &&
      !this.state.expert
    ) {
      levelProfils.push({
        createdDateTime: today,
        nomProfil: nameProfil,
        numProfil: maxNumProfils,
      });
      return levelProfils;
    }

    if (this.state.debutant) {
      levelProfils.push({
        createdDateTime: today,
        nomProfil: nameProfil + " - Débutant",
        numProfil: maxNumProfils++,
      });
    }

    if (this.state.junior) {
      levelProfils.push({
        createdDateTime: today,
        nomProfil: nameProfil + " - Junior",
        numProfil: maxNumProfils++,
      });
    }

    if (this.state.confirme) {
      levelProfils.push({
        createdDateTime: today,
        nomProfil: nameProfil + " - Confirmé",
        numProfil: maxNumProfils++,
      });
    }

    if (this.state.senior) {
      levelProfils.push({
        createdDateTime: today,
        nomProfil: nameProfil + " - Sénior",
        numProfil: maxNumProfils++,
      });
    }

    if (this.state.expert) {
      levelProfils.push({
        createdDateTime: today,
        nomProfil: nameProfil + " - Expert",
        numProfil: maxNumProfils++,
      });
    }

    return levelProfils;
  }

  private createProfils = () => {
    const profils: ProfilCigref[] = this.addLevel();
    const promises: Array<Promise<any>> = [];
    const errorsProfils: ProfilCigref[] = [];

    for (const profil of profils) {
      const url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        process.env.REACT_APP_PROFILS +
        "/items";
      const data = {
        nomProfil: profil.nomProfil,
        numProfil: profil.numProfil,
      };

      promises.push(
        API.post(url, { fields: data })
          .then()
          .catch((error) => {
            errorsProfils.push(profil);
          })
      );
    }

    Promise.all(promises).then(() =>
      this.snackBarCreateProfils(profils, errorsProfils)
    );
  };

  private snackBarCreateProfils = (
    profils: ProfilCigref[],
    errorsProfils: ProfilCigref[]
  ) => {
    let message: string = profils.length === 1 ? "Le profil " : "Les profils ";

    if (errorsProfils.length === 0) {
      for (const profil of profils) {
        message = message + profil.nomProfil + " ";
      }

      message =
        profils.length === 1
          ? message + " a bien été ajouté"
          : message + " ont bien été ajoutés";
      this.openSnackBar(message, "success");
    } else {
      for (const profil of errorsProfils) {
        message = message + profil.nomProfil + " ";
      }

      message =
        errorsProfils.length === 1
          ? message + " n'a pas été ajouté, veuillez recommencer."
          : message + " n'ont pas été ajoutés, veuillez recommencer.";
      this.openSnackBar(message, "error");
    }

    this.refreshData();
  };

  private async getMaxNumProfil(): Promise<number> {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PROFILS +
      "/items?expand=fields(select=numProfil)";
    const orderby = "&$orderby=id asc";

    return API.get(url + orderby).then((response) => {
      const numProfils: number[] = response.data.value.map(
        (value: { fields: { numProfil: string | number } }) =>
          +value.fields.numProfil
      );
      const maxNumProfils =
        numProfils.length === 0 ? 1 : Math.max(...numProfils) + 1;

      this.setState({ maxNumProfils });

      return response.data.value;
    });
  }

  private deleteProfil = (profil: ProfilCigref) => {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PROFILS +
      "/items/" +
      profil.id;

    API.delete(url)
      .then(() => this.succesDeleteProfil(profil))
      .catch((error) => {
        this.openSnackBar(
          "La suppression du profil " +
            profil.nomProfil +
            " n'a pas été effectuée, veuillez recommencer.",
          "error"
        );
      });
  };

  private succesDeleteProfil = (profil: ProfilCigref) => {
    this.refreshData();
    this.openSnackBar(
      "Le profil " + profil.nomProfil + " a bien été supprimé.",
      "success"
    );
  };

  private changeProfil = () => {
    if (this.state.selectedProfil) {
      const url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        process.env.REACT_APP_PROFILS +
        "/items/" +
        this.state.selectedProfil.id;
      const data = {
        nomProfil: this.state.nameProfilModal,
      };

      API.patch(url, { fields: data })
        .then(() => this.succesChangeProfil())
        .catch((error) => {
          this.openSnackBar(
            "La modification n'a pas été prise en compte, veuillez recommencer.",
            "error"
          );
        });
    }
  };

  private succesChangeProfil = () => {
    this.refreshData();
    this.toggle();
    this.openSnackBar("Le profil a bien été modifié.", "success");
  };

  private editProfil = (profil: ProfilCigref) => {
    this.setState({
      modal: true,
      nameProfilModal: profil.nomProfil,
      selectedProfil: profil,
    });
  };

  private toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  private onChangeLevel = (name: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({ [name]: event.currentTarget.checked });
  };

  private onChangeNameProfil = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ nameProfil: event.currentTarget.value });
  };

  private onChangeNameProfilModal = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({ nameProfilModal: event.currentTarget.value });
  };

  private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filtered: boolean = event.currentTarget.value === "" ? false : true;
    this.setState({ filter: event.currentTarget.value, filtered });
  };

  private openSnackBar(
    messageSnackBar: string,
    typeSnackBar: "success" | "warning" | "error" | "info"
  ) {
    this.setState({ openSnackBar: true, typeSnackBar, messageSnackBar });
  }

  private onCloseSnackBar = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    reason: string
  ) => {
    this.setState({ openSnackBar: false });
  };

  private onClickSnackBar = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ openSnackBar: false });
  };
}

export default withStyles(styles)(ManageProfils);
