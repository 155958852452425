import React, { Component } from 'react';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import TableDisplay from '../Table/TableDisplay';

import API from '../../services/Api';
import { SharePointDataService } from '../../services/SharePointDataService';

import { IClient } from '../../interfaces/client';
import { IProject } from '../../interfaces/project';
import { ITechnologie } from '../../interfaces/technologie';

import Loading from '../Loader';
import IPropale from '../../interfaces/IPropale';

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      width: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
        padding: theme.spacing(3),
      },
    },
    formControl: {
      margin: theme.spacing(),
      minWidth: 200,
    },
  });

interface IInfoGeneraleProps extends WithStyles<typeof styles> {
  projet: IProject;
  refreshProject: () => Promise<IProject>;
}

interface IInfoGeneraleState {
  numPropale: string;
  objetPropale: string;
  idClient: number;
  loading: boolean;
  nomClient: string;
  numClient: string;
  clients: IClient[];
  technologie: string;
  technologies: ITechnologie[];
  propales : IPropale[];
}

class InfoGenerale extends Component<IInfoGeneraleProps, IInfoGeneraleState> {
  constructor(props: Readonly<IInfoGeneraleProps>) {
    super(props);

    this.state = {
      clients: [],
      idClient: this.props.projet.idClient,
      loading: false,
      nomClient: this.props.projet.nomClient,
      numClient: this.props.projet.numClient,
      numPropale: '',
      objetPropale: '',
      technologie: this.props.projet.technologie,
      technologies: [],
      propales : [],
    };
  }

  public componentDidMount() {
    this.getClients();
    this.getTechnologies();
    this.getPropales();
  }

  public render() {
    const { classes } = this.props;
    const clients: any[] = [];
    const technos: any[] = [];
    const rows: any[] = [];
    let i = 0;

    if (this.state.loading) {
      return <Loading />;
    }

    for (const client of this.state.clients) {
      clients.push({
        label: client.nomClient + ' / ' + client.compte,
        value: client.id,
      });
    }

    for (const technologie of this.state.technologies) {
      technos.push({
        label: technologie.nomTechnologie,
        value: technologie.nomTechnologie,
      });
    }

    for (const propale of this.state.propales) {
      rows.push(
        <TableRow key={i}>
          <TableCell component="th" scope="row" align="center">{propale.id}</TableCell>
          <TableCell align="center">{propale.Title}</TableCell>
          <TableCell align="center">{propale.PObjet}</TableCell>
          <TableCell align="center"><Switch checked={propale.enabled} onChange={this.handlePropaleActivationChange(i)} /></TableCell>
        </TableRow>
      );
      i++;
    }

    let columns: any[] = ['ID', 'PNum', 'PObjet', 'Activé'];
    columns = columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={8} xs={8}>
          <Paper className={classes.paper}>
            <Grid container justify="center" alignItems="center">
              <Grid item md={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  gutterBottom
                >
                  Modifier les informations du projet
                </Typography>
              </Grid>
              <Grid item md="auto" xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="client-label">
                    Client / Client final :
                  </InputLabel>
                  <Select
                    id="client-select"
                    label="Client / Client final :"
                    value={this.state.idClient}
                    onChange={this.onClientChange}
                  >
                    <MenuItem key={0} value={0} />
                    {clients.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md="auto" xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="technologie-label">Technologie :</InputLabel>
                  <Select
                    id="technologie-select"
                    label="Technologie :"
                    value={this.state.technologie}
                    onChange={this.onChangeTechnologie}
                  >
                    <MenuItem key="" value="">
                      <em>Aucune</em>
                    </MenuItem>
                    {technos.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md="auto" xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleModify}
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item md={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  gutterBottom
                >
                  Ajouter une propale
                </Typography>
              </Grid>
              <Grid item md="auto" xs={12}>
                <FormControl variant="outlined">
                  <TextField
                    id="numPropale-name"
                    label="Numéro de la propale"
                    onChange={this.onChangeNumPropale}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md="auto" xs={12}>
                <FormControl variant="outlined">
                  <TextField
                    id="objetPropale-name"
                    label="Objet de la propale"
                    onChange={this.onChangeObjetPropale}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md="auto" xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleAddNumPropale}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={8} xs={8}>
          <TableDisplay columns={columns} rows={rows} />
        </Grid>
      </Grid>
    );
  }

  private getClients = async () => {
    const clients: IClient[] = await SharePointDataService.getClients();
    this.setState({ clients });
  };

  private getTechnologies = async () => {
    const technologies: ITechnologie[] = await SharePointDataService.getTechnologies();
    this.setState({ technologies });
  };

  private handleModify = () => {
    const data = {
      NumClientLookupId: this.state.idClient,
      NumProjet: this.state.numClient,
      Technologie: this.state.technologie,
    };

    if (+data.NumClientLookupId !== 0) {
      this.setState({ loading: true });

      SharePointDataService.updateProject(
        this.props.projet.idProjet, 
        data
      ).then((response => {
        this.props
            .refreshProject()
            .then(() => this.setState({ loading: false }))
      })).catch(error => {
        // TO DO handle error
      });
    }
  };

  private handleAddNumPropale = () => {
    let url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PROPALES +
      '/items';
    const dataPropale = {
      Title: this.state.numPropale,
      PObjet: this.state.objetPropale,
    };
    const numPropalesIDs = this.props.projet.numPropales.map(
      (numPropale) => numPropale.LookupId
    );

    this.setState({ loading: true });
    API.post(url, { fields: dataPropale })
      .then((response) => {
        numPropalesIDs.push(response.data.id);
        const dataProject = {
          PNumsLookupId: numPropalesIDs,
          'PNumsLookupId@odata.type': 'Collection(Edm.Int32)',
        };

        url =
          'sites/' +
          process.env.REACT_APP_SITEID +
          '/lists/' +
          process.env.REACT_APP_PROJETS +
          '/items/' +
          this.props.projet.idProjet;
        API.patch(url, { fields: dataProject })
          .then(() =>
            this.props
              .refreshProject()
              .then((project: IProject) => {
                this.setState({
                  loading: false
                });
                this.getPropales();
              })
          )
          .catch((error) => {
            // TO DO handle error
          });
      })
      .catch((error) => {
        // TO DO handle error
      });
  };

  private onChangeNumPropale = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ numPropale: event.target.value });
  };

  private onChangeObjetPropale = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ objetPropale: event.target.value });
  };

  //Déclenche une erreur a cause du React.ChangeEvent
  private onChangeClient = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const client = this.state.clients.find(
      (item) => item.id === +event.target.value
    );

    if (client) {
      this.setState({
        idClient: +event.target.value,
        numClient: client.numClient,
      });
    }
  };


  //Déclenche une erreur a cause du React.ChangeEvent
  // (event: React.ChangeEvent<HTMLSelectElement>)
  private onChangeTechnologie = (event: any) => {
    this.setState({ technologie: event.target.value });
  };

  private onClientChange = (event : any) => {
    // Get client
    const client = this.state.clients.find(
      (item) => item.id === +event.target.value
    );

    // Null check
    if (!client) {
      return;
    }

    // Set state
    this.setState({
      idClient: client.id,
      numClient: client.numClient,
    });
  };

  private getPropales() {
    const thisInstance = this;
    const propales: Promise<IPropale>[] = [];
    for (const propale of this.props.projet.numPropales) {
      propales.push(SharePointDataService.getPropaleById(propale.LookupId));
    }
    Promise.all(propales).then((values) => {
      thisInstance.setState({ propales: values });
    });
  }

  private handlePropaleActivationChange(index: number) {
    const propaleId = this.state.propales[index].id;
    const thisInstance = this;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      SharePointDataService.changePropaleStatus(propaleId, event.target.checked)
        .catch (error => {
          console.log(error);
        })
        .then(_ => {
          const propales = thisInstance.state.propales;
          propales[index].enabled = !propales[index].enabled;
          thisInstance.setState({
            propales: propales,
          });
        })
    }
  }

}

export default withStyles(styles)(InfoGenerale);
