import React, { Component } from "react";

import { TableCell, TableRow } from "@material-ui/core";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import classNames from "classnames";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import API from "../../../services/Api";

import Loading from "../../Loader";
import TableCellCheckbox from "../../Table/TableCellCheckbox";
import TableCellCheckboxTitle from "../../Table/TableCellCheckboxTitle";
import TableDisplay from "../../Table/TableDisplay";
import LiaisonPhase from "./LiaisonPhase";

import Phase from "../../../interfaces/phase";
import Profil from "../../../interfaces/profil";
import { IProject } from "../../../interfaces/project";

interface IPhaseProps {
  projet: IProject;
  profils: Profil[];
  refreshProject: () => Promise<IProject>;
}

interface IPhaseState {
  activeTab: number;
  content: any[];
  nameChannelsNotValidate: string[];
  nbChannels: number;
  nbPhases: number;
  phases: Phase[];
  maxNumPhase: number;
  loading: boolean;
}

class ManagePhases extends Component<IPhaseProps, IPhaseState> {
  public validation: any[] = [];
  public nbValidation: number = 0;
  private teamsChannelsColumns: any[];

  constructor(props: Readonly<IPhaseProps>) {
    super(props);

    this.state = {
      activeTab: 0,
      content: [],
      nameChannelsNotValidate: [],
      nbChannels: 0,
      nbPhases: 0,
      loading: true,
      maxNumPhase: 1,
      phases: [],
    };

    this.teamsChannelsColumns = ["Canal Teams", "Valider", "Informations"];
    this.teamsChannelsColumns = this.teamsChannelsColumns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public componentDidMount() {
    this.refreshData();
  }

  public render() {
    const { activeTab, loading, nbChannels } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (nbChannels > 0) {
      const enabled = this.state.nbPhases === this.state.nbChannels;
      const project = this.props.projet;
      this.teamsChannelsColumns[1] = (
        <TableCellCheckboxTitle
          onChangeClick={this.validateAllPhases}
          value={{ enabled, project }}
          enabled={enabled}
          title={"Valider"}
        />
      );
    }

    const style = { marginTop: "25px" };

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={activeTab}
            onChange={this.handleChange}
            indicatorColor="secondary"
            textColor="primary"
            centered={true}
            variant="fullWidth"
          >
            <Tab label="Phases du projet" />
            <Tab label="Liaison phase/profil" />
          </Tabs>
        </AppBar>
        <div style={style}>
          {activeTab === 0 && (
            <TableDisplay
              columns={this.teamsChannelsColumns}
              rows={this.state.content}
            />
          )}
          {activeTab === 1 && (
            <LiaisonPhase
              projet={this.props.projet}
              phases={this.state.phases}
              linkChanged={this.getPhases}
              refreshInfo={this.getChannels}
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  private handleChange = (event: any, activeTab: any) => {
    this.setState({ activeTab });
  };

  private refreshData = () => {
    this.refreshProject().then(() =>
      this.getMaxNumPhase().then(() =>
        this.getPhases().then(() => this.getChannels())
      )
    );
  };

  private refreshProject = (): Promise<IProject> => {
    return this.props.refreshProject();
  };

  /**
   * Requête récupérant les canaux Teams du projet
   */
  private getChannels = () => {
    const channelsUrl = "teams/" + this.props.projet.idTeams + "/channels";
    let i = 0;

    API.get(channelsUrl)
      .then((response) => {
        const channels: any[] = response.data.value;
        const nbChannels = channels.length;
        const content: any[] = [];
        const nameChannelsNotValidate: string[] = [];
        let nbPhases = 0;

        for (const channel of channels) {
          const phase: Phase | undefined = this.state.phases.find(
            (item) => item.nomPhase === channel.displayName
          );
          let message: string = "";
          let color: string = "#000000";

          if (!phase) {
            nameChannelsNotValidate.push(channel.displayName);
          }

          if (phase && phase.enabled) {
            if (phase.numProfils.length === 0) {
              message = "Veuillez affecter au moins un profil";
              color = "#ff0000";
            } else {
              message =
                phase.numProfils.length === 1
                  ? +phase.numProfils.length + " profil affecté"
                  : phase.numProfils.length + " profils affectés";
            }
          }

          const enabled: boolean = phase ? (phase.enabled as boolean) : false;

          if (enabled) {
            nbPhases++;
          }
          content.push(
            <TableRow key={i}>
              <TableCell align="center">{channel.displayName}</TableCell>
              <TableCellCheckbox
                onChangeClick={this.handleCheckbox}
                value={{ channel, phase, enabled }}
                enabled={enabled}
                key={i}
              />
              <TableCell align="center" style={{ color }}>
                {message}
              </TableCell>
            </TableRow>
          );
          i++;
        }

        this.setState({
          content,
          loading: false,
          nameChannelsNotValidate,
          nbChannels,
          nbPhases,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        // TO DO : handle error
      });
  };

  private handleCheckbox = (value: {
    channel: any;
    phase: Phase | undefined;
    enabled: boolean;
  }) => {
    let url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PHASES +
      "/items";

    this.setState({ loading: true });

    if (!value.phase) {
      const data = {
        IdProjetLookupId: this.props.projet.idProjet,
        enabled: true,
        nomPhase: value.channel.displayName,
        numPhase: this.state.maxNumPhase,
      };

      API.post(url, { fields: data })
        .then((response) => this.postPhase(response.data.fields.id))
        .catch((error) => {
          // TO DO : handle error
        });
    } else {
      const data = {
        IdProjetLookupId: this.props.projet.idProjet,
        enabled: !value.enabled,
      };

      url += "/" + value.phase.id;
      API.patch(url, { fields: data })
        .then(() => this.refreshData())
        .catch((error) => {
          // TO DO : handle error
        });
    }
  };

  /**
   * fonction pour valider ou non toutes les phases
   */
  private validateAllPhases = (value: {
    project: IProject;
    enabled: boolean;
  }) => {
    this.setState({ loading: true });

    if (value.enabled) {
      //  Partie désactivant les phases
      for (const phase of value.project.phases) {
        const url =
          "sites/" +
          process.env.REACT_APP_SITEID +
          "/lists/" +
          process.env.REACT_APP_PHASES +
          "/items/" +
          phase.LookupId;
        const data = { enabled: false };
        API.patch(url, { fields: data })
          .then(() => this.refreshData())
          .catch((error) => {
            // TO DO handle error
          });
      }
    } else {
      // Partie validant les phases desactivées
      for (const phase of value.project.phases) {
        const url =
          "sites/" +
          process.env.REACT_APP_SITEID +
          "/lists/" +
          process.env.REACT_APP_PHASES +
          "/items/" +
          phase.LookupId;
        const data = { enabled: true };
        API.patch(url, { fields: data })
          .then(() => this.refreshData())
          .catch((error) => {
            // TO DO handle error
          });
      }

      // Parite validant les canals teams en tant que phases
      let url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        process.env.REACT_APP_PHASES +
        "/items";
      const promises: Array<Promise<any>> = [];
      const idPhases = value.project.phases.map((item) => item.LookupId);
      let maxNumPhase = this.state.maxNumPhase;
      for (const nameChannel of this.state.nameChannelsNotValidate) {
        const data = {
          IdProjetLookupId: this.props.projet.idProjet,
          enabled: true,
          nomPhase: nameChannel,
          numPhase: maxNumPhase++,
        };
        promises.push(API.post(url, { fields: data }));
      }
      url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        process.env.REACT_APP_PROJETS +
        "/items/" +
        value.project.idProjet;
      Promise.all(promises).then((responses) => {
        for (const response of responses) {
          idPhases.push(response.data.fields.id);
        }
        const data = {
          numPhasesLookupId: idPhases,
          "numPhasesLookupId@odata.type": "Collection(Edm.Int32)",
        };
        API.patch(url, { fields: data })
          .then(() => this.refreshData())
          .catch((error) => {
            // TO DO handle error
          });
      });
    }
  };

  private postPhase = (idPhase: number) => {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PROJETS +
      "/items/" +
      this.props.projet.idProjet;
    const phases = this.props.projet.phases.map((item) => item.LookupId);
    phases.push(idPhase);

    const data = {
      numPhasesLookupId: phases,
      "numPhasesLookupId@odata.type": "Collection(Edm.Int32)",
    };

    API.patch(url, { fields: data })
      .then(() => this.refreshData())
      .catch((error) => {
        // TO DO handle error
      });
  };

  private getPhases = (): Promise<void> => {
    const expand =
      "?expand=fields(select=id,numPhase,nomPhase,numProfils,enabled)";
    const phases: Phase[] = [];
    const promises: Array<Promise<any>> = [];

    for (const phase of this.props.projet.phases) {
      const url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        process.env.REACT_APP_PHASES +
        "/items/" +
        phase.LookupId;
      promises.push(API.get(url + expand));
    }

    return Promise.all(promises).then((responses) => {
      for (const response of responses) {
        phases.push({
          enabled: response.data.fields.enabled,
          id: response.data.fields.id,
          nomPhase: response.data.fields.nomPhase,
          numPhase: response.data.fields.numPhase,
          numProfils: response.data.fields.numProfils,
        });
      }

      this.setState({ phases });
    });
  };

  private async getMaxNumPhase(): Promise<number> {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PHASES +
      "/items?$expand=fields(select=numPhase)";
    const top = "&$top=1";
    const orderby = "&$orderby=id asc";

    return API.get(url + orderby).then((response) => {
      const numPhases: number[] = response.data.value.map(
        (value: { fields: { numPhase: string | number } }) =>
          +value.fields.numPhase
      );
      const maxNumPhase =
        numPhases.length === 0 ? 1 : Math.max(...numPhases) + 1;

      this.setState({ maxNumPhase });

      return response.data.value;
    });
  }
}

export default ManagePhases;
