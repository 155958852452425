import React, { Component } from 'react';

import { AppBar, Button, Grid, Tab, Tabs } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import API from '../../services/Api';

import InfoGenerale from './InfoGenerale';
import Members from './Members/Members';
import ManagePhases from './Phases/ManagePhases';
import ManageProfils from './Profils/ManageProfils';

import Profil from '../../interfaces/profil';
import ProfilTJM from '../../interfaces/profil-tjm';
import { IProject } from '../../interfaces/project';

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
  });

interface IState {
  activeTab: number;
  profilsTJM: ProfilTJM[];
  project: IProject;
}

interface IProps extends WithStyles<typeof styles> {
  project: IProject;
  profils: Profil[];
  goBackToProjects: () => void;
}

class ManageProject extends Component<IProps, IState> {
  public content: any = [];

  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      activeTab: 0,
      profilsTJM: [],
      project: this.props.project,
    };
  }

  public componentDidMount() {
    this.refreshProfilTjm();
  }

  public render() {
    const { classes } = this.props;
    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={activeTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered={true}
          >
            <Tab label="Informations générales" />
            <Tab label="Profils" />
            <Tab label="Phases" />
            <Tab label="Membres" />
          </Tabs>
        </AppBar>

        <div className={classes.layout}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={1}>
              <Button variant="contained" onClick={this.goBackToProjects}>
                Retour
              </Button>
            </Grid>
            <Grid item xs={11}>
              <h1 style={{ width: '100%', textAlign: 'center' }}>
                Gestion du projet : {this.state.project.nomProjet}
              </h1>
            </Grid>
            {activeTab === 0 && (
              <InfoGenerale
                projet={this.state.project}
                refreshProject={this.refreshProject}
              />
            )}
            {activeTab === 1 && (
              <ManageProfils
                profils={this.props.profils}
                profilsTJM={this.state.profilsTJM}
                refreshProfilTJM={this.refreshProfilTjm}
                projet={this.state.project}
                refreshProject={this.refreshProject}
              />
            )}
            {activeTab === 2 && (
              <ManagePhases
                profils={this.props.profils}
                projet={this.state.project}
                refreshProject={this.refreshProject}
              />
            )}
            {activeTab === 3 && <Members project={this.state.project} />}
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  private handleChange = (event: any, activeTab: any) => {
    this.setState({ activeTab });
  };

  private goBackToProjects = () => {
    this.props.goBackToProjects();
  };

  private refreshProject = (): Promise<IProject> => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PROJETS +
      '/items/' +
      this.props.project.idProjet;
    const expand =
      '?expand=fields(select=ID,IdTeams,idList,Title,NumProjet,PNums,PNums_x003a_PObjet,NumClient,NumClientLookupId,NumClient_x003a_Title,enabled,numPhases_x003a_nomPhase,numProfils_x003a_nomProfil,NumClient_x003a_Compte,Technologie)';

    return API.get(url + expand).then((response) => {
      const fields = response.data.fields;
      const project: IProject = {
        clientFinal: fields.NumClient_x003a_Compte,
        enabled: fields.enabled,
        idClient: fields.NumClientLookupId,
        idList: fields.idList,
        idProjet: fields.id,
        idTeams: fields.IdTeams,
        nomClient: fields.NumClient_x003a_Title,
        nomProjet: fields.Title,
        numClient: fields.NumClient,
        numProjet: fields.NumProjet,
        numPropales: fields.PNums,
        titlePropales: fields.PNums_x003a_PObjet,
        phases: fields.numPhases_x003a_nomPhase,
        profils: fields.numProfils_x003a_nomProfil,
        technologie: fields.Technologie,
        typeProjet: '',
      };

      this.setState({ project });
      return project;
    });
  };

  private refreshProfilTjm = () => {
    const profilsTJM: ProfilTJM[] = [];
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_TJM +
      '/items?';
    const expand =
      'expand=fields(select=numTJMProfil,prix,numProfil,numProfil_x003a_nomProfil,numProjetLookupId,id)';
    const filter = `&filter=fields/numProjetLookupId eq ${this.state.project.idProjet}`;

    API.get(url + expand + filter)
      .then((response) => {
        for (const profil of response.data.value) {
          profilsTJM.push({
            id: +profil.fields.id,
            nomProfil: profil.fields.numProfil_x003a_nomProfil,
            numProfil: Math.trunc(profil.fields.numProfil),
            numTJMProfil: profil.fields.numTJMProfil,
            prix: profil.fields.prix,
          });
        }
        this.setState({ profilsTJM });
      })
      .catch((error) => {
        // TO DO : handle error
      });
  };
}

export default withStyles(styles)(ManageProject);
