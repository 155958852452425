import React, { Component } from "react";

import { AxiosRequestConfig } from "axios";

import classNames from "classnames";

import { DropdownList } from "react-widgets";

import {
  Badge,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { blue, orange, pink } from "@material-ui/core/colors";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { AddCircleTwoTone, SettingsTwoTone } from "@material-ui/icons";

import moment, { Moment } from "moment";

import utf8 from "utf8";

import API from "../../services/Api";
import Auth from "../../services/Auth";
import { SharePointDataService } from "../../services/SharePointDataService";

import Export from "../Export/Export";
import Loading from "../Loader";
import MySnackbar from "../MySnackbar";

import Activite from "../../interfaces/activite";
import { IActivitePost } from "../../interfaces/activite-post";
import { IProject } from "../../interfaces/project";

import Lookup from "../../interfaces/lookup";
import TypeActivite from "../../interfaces/type-activite";

import DaysOff, { IDayOff } from "../../classes/days-off";

import "fullcalendar";
import { EventObjectInput } from "fullcalendar";

import "./Calendar.css";

import $ from "jquery";
import IPropale from "../../interfaces/IPropale";

const amFacturable = blue[500];
const pmFacturable = blue[900];
const amImputable = orange[500];
const pmImputable = orange[900];
const amAbsence = pink[500];
const pmAbsence = pink[900];

const styles = (theme: Theme) =>
  createStyles({
    amFacturable: {
      backgroundColor: amFacturable,
    },
    pmFacturable: {
      backgroundColor: pmFacturable,
    },
    amImputable: {
      backgroundColor: amImputable,
    },
    pmImputable: {
      backgroundColor: pmImputable,
    },
    amAbsence: {
      backgroundColor: amAbsence,
    },
    pmAbsence: {
      backgroundColor: pmAbsence,
    },
    colorBlock: {
      width: 50,
      height: 20,
      margin: 5,
      marginRight: 50,
      border: "1px solid rgba(0, 0, 0, .2)",
      display: "inline-block",
    },
    colorBlockTitle: {
      display: "inline-block",
      width: 90,
    },
    colorBlockText: {
      margin: 0,
      lineHeight: "18px",
      paddingLeft: 55,
    },
    dialogTitle: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(),
    },
    marginBadge: {
      margin: theme.spacing(),
    },
    paper: {
      ...theme.mixins.gutters(),
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    calendar: {
      marginBottom: theme.spacing(2),
    },
  });

interface IProps extends WithStyles<typeof styles> {

}

type SnackBarType = "success" | "error" | "warning" | "info";

interface ICalendarState {
  AM: boolean;
  PM: boolean;
  chargeActivities: number;
  chargeActivitiesAbsences: number;
  chargeActivitiesFacturable: number;
  chargeActivitiesImputable: number;
  commentaire: string;
  dateEnd: Date;
  dateStart: Date;
  deleteOnClick: boolean;
  facturable: boolean;
  loading: boolean;
  modal: boolean;
  messageSnackBar: string;
  businessDays: number;
  openSnackBar: boolean;
  profils: Lookup[];
  charge: string;
  selectedAbsence: boolean;
  selectedActivity: Activite | null;
  selectedPhase: Lookup | null;
  selectedProfil: Lookup | null;
  selectedProject: IProject | null;
  selectedProjectName: string;
  selectedPropale: IPropale | null;
  selectedPropaleTitle: Lookup | null;
  selectedTypeActivite: TypeActivite;
  showLibre: boolean;
  typesActivites: TypeActivite[];
  dateClicked: string;
  disabledFacturable: boolean;
  projects: IProject[];
  propales: IPropale[];
  typeSnackBar: SnackBarType;
  [key: string]: any;
}

class Calendar extends Component<IProps, ICalendarState> {
  private auth: Auth = new Auth();
  private calendarOptions: object;
  private calendar: JQuery | null;
  private activites: Activite[];
  private idNewEvent: number;

  constructor(props: Readonly<IProps>) {
    super(props);

    this.activites = [];
    this.idNewEvent = -1;

    this.state = {
      AM: true,
      PM: false,
      facturable: true,
      commentaire: "",
      dateEnd: new Date(),
      dateStart: new Date(),
      deleteOnClick: false,
      chargeActivities: 0,
      chargeActivitiesAbsences: 0,
      chargeActivitiesFacturable: 0,
      chargeActivitiesImputable: 0,
      loading: true,
      messageSnackBar: "",
      modal: false,
      businessDays: 0,
      openSnackBar: false,
      profils: [],
      selectedActivity: null,
      charge: "0.5",
      selectedAbsence: false,
      selectedPhase: null,
      selectedProfil: null,
      selectedProject: null,
      selectedProjectName: "",
      selectedPropale: null,
      selectedPropaleTitle: null,
      selectedTypeActivite: { id: 0, title: "Production" },
      showLibre: false,
      typesActivites: [],
      typeSnackBar: "error",
      dateClicked: "",
      disabledFacturable: false,
      projects: [],
      propales: [],
      selectedCharge: 0.5,
    };

    // Utilisation de "_" au lieu de "this" dans le Jquery pour faire appel au bon context
    const _: any = this;
    this.calendarOptions = {
      aspectRatio: 1.9,
      buttonText: {
        month: "mois",
        today: "aujourd'hui",
        week: "semaine",
      },
      columnHeaderFormat: "ddd DD/MM",
      contentHeight: "auto",
      customButtons: {
        basicWeek: {
          click: () => {
            if ($("#calendar").fullCalendar("getView").name !== "basicWeek") {
              $("#calendar").fullCalendar("changeView", "basicWeek");
              _.getDates();
            }
          },
          text: "semaine",
        },
        month: {
          click: () => {
            if ($("#calendar").fullCalendar("getView").name !== "month") {
              $("#calendar").fullCalendar("changeView", "month");
              _.getDates();
            }
          },
          text: "mois",
        },
        next: {
          click: () => {
            $("#calendar").fullCalendar("next");
            _.getDates();
          },
        },
        prev: {
          click: () => {
            $("#calendar").fullCalendar("prev");
            _.getDates();
          },
        },
        today: {
          click: () => {
            $("#calendar").fullCalendar("today");
            _.getDates();
          },
          text: "aujourd'hui",
        },
      },
      dayNames: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      dayNamesShort: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      dayRender(event: Moment, element: JQuery<HTMLElement>) {
        const daysOff = DaysOff.getDaysOff(event.year());
        const dayOff: IDayOff | undefined = DaysOff.getDayOff(event, daysOff);

        if (dayOff) {
          element.css("background-color", "#DDDDDD");
          element.css("text-align", "center");
          $(element).html("<p>" + dayOff.title + "</p>");
        }
      },
      defaultView: "month",
      eventClick(event: EventObjectInput) {
        const selectedActivity: Activite = _.activites.find(
          (item: Activite) =>
            item.id === event.id && item.idList === event.idList
        );

        if (selectedActivity && _.state.deleteOnClick) {
          _.deleteEvent(selectedActivity);
        } else if (selectedActivity) {
          _.setState({ selectedActivity, modal: true });
        }
      },
      eventRender(
        event: EventObjectInput,
        element: JQuery<HTMLElement>,
        view: { [x: string]: any }
      ) {
        if (view.name === "basicWeek") {
          element[0].innerText = event.description;
        } else {
          element[0].innerText = event.title;
          element[0].title = event.description;
        }
      },
      header: {
        center: "title",
        left: "month, basicWeek",
        right: "today, prev, next",
      },
      eventMouseover(event: EventObjectInput) {
        $(this).find(".fc-content").attr({
          "data-content": event.description,
          "data-toggle": "popover",
          title: event.description,
        });
      },
      dayClick(date: Moment) {
        const daysOffCurrentYear = DaysOff.getDaysOff(date.year());
        const dayOff = DaysOff.getDayOff(date, daysOffCurrentYear);

        if (!dayOff) {
          const d: string = date.format("YYYY-MM-DD");
          _.createEvent(d);
        }
      },
      locale: "fr",
      monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      monthNamesShort: [
        "Janv.",
        "Févr.",
        "Mars",
        "Avr.",
        "Mai",
        "Juin",
        "Juill.",
        "Août",
        "Sept.",
        "Oct.",
        "Nov.",
        "Déc.",
      ],
      selectable: false,
      themeSystem: "standard",
      views: {
        month: {
          columnHeaderFormat: "dddd",
          displayEventTime: false,
        },
        week: {
          displayEventTime: false,
        },
      },
      weekends: false,
    };

    this.calendar = null;
  }

  public componentDidMount() {
    // hide calendar
    $("#calendar").hide();
    this.getTypesActivites();
    this.getProjects();
    this.getActivities();
    this.calendar = $("#calendar").fullCalendar(this.calendarOptions);
  }

  public render() {
    const { classes } = this.props;
    const { projects, dateStart, dateEnd } = this.state;
    const {
      AM,
      PM,
      charge,
      facturable,
      selectedAbsence,
      selectedProject,
      selectedTypeActivite,
    } = this.state;

    return (
      <div style={{ padding: 12 }}>
        <Grid container>
          <Grid item md={3}>
            <Paper className={classes.paper} elevation={1}>
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  <AddCircleTwoTone color="primary" fontSize="small" /> Saisir
                  une activité
                </Typography>
                <div className={classes.root}>
                  {/* Facturable / Imputable */}
                  <FormControlLabel
                    control={
                      <Switch
                        id="facturable"
                        checked={facturable}
                        onChange={this.onChange}
                        disabled={selectedAbsence}
                      />
                    }
                    label="Facturable"
                  />
                  {/* Meridiem */}
                  <FormControlLabel
                    control={
                      <Checkbox id="AM" checked={AM} onChange={this.onChange} />
                    }
                    label="AM"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox id="PM" checked={PM} onChange={this.onChange} />
                    }
                    label="PM"
                  />
                  {/* Service */}
                  <FormControl fullWidth className={classes.margin}>
                    <DropdownList
                      filter="contains"
                      textField="title"
                      valueField="id"
                      placeholder="Service"
                      data={this.state.typesActivites}
                      messages={{
                        open: "Service",
                        emptyList: "Aucun service disponible",
                        emptyFilter: "Aucun service correspondant",
                        filterPlaceholder: "Filtrer les services",
                      }}
                      onChange={this.onChangeTypeActivite}
                      defaultValue={selectedTypeActivite}
                      value={selectedTypeActivite}
                    />
                  </FormControl>
                  {/* Projet ou intitulé */}
                  <FormControl fullWidth className={classes.margin}>
                    {this.state.showLibre ? (
                      <TextField
                        label="Intitulé"
                        id="projetLibre"
                        defaultValue=""
                        margin="dense"
                        onChange={this.onChangeLibre}
                        variant="outlined"
                      />
                    ) : (
                      <DropdownList
                        filter="contains"
                        placeholder="Sélectionner un projet"
                        data={this.state.projects}
                        textField="nomProjet"
                        valueField="idProjet"
                        messages={{
                          open: "Projet",
                          emptyList: "Aucun projet disponible",
                          emptyFilter: "Aucun projet correspondant",
                          filterPlaceholder: "Filtrer les projets",
                        }}
                        groupBy={this.groupByClient}
                        onChange={this.onChangeProjet}
                      />
                    )}
                  </FormControl>
                  {/* Numéro de propale */}
                  {!this.state.showLibre &&
                  selectedProject &&
                  this.state.propales.length !== 0 ? (
                    <FormControl fullWidth className={classes.margin}>
                      <DropdownList
                        filter="contains"
                        placeholder="N° de propale"
                        data={this.state.propales}
                        textField={this.getPropales}
                        valueField="id"
                        messages={{
                          open: "N° de propale",
                          emptyList: "Aucun numéro de propale disponible",
                          emptyFilter: "Aucun numéro de propale correspondant",
                          filterPlaceholder: "Rechercher une propale",
                        }}
                        onChange={this.onChangePropale}
                        defaultValue={this.state.propales[0]}
                        value={this.state.selectedPropale}
                      />
                    </FormControl>
                  ) : null}
                  {/* Phases du projet */}
                  {selectedProject && selectedProject.phases.length !== 0 && (
                    <FormControl fullWidth className={classes.margin}>
                      <DropdownList
                        filter="contains"
                        placeholder="Phase"
                        data={selectedProject.phases}
                        textField="LookupValue"
                        valueField="LookupId"
                        messages={{
                          open: "Phase",
                          emptyList: "Aucune phase disponible",
                          emptyFilter: "Aucune phase correspondante",
                          filterPlaceholder: "Rechercher une phase",
                        }}
                        onChange={this.onChangePhase}
                        defaultValue={selectedProject.phases[0]}
                        value={this.state.selectedPhase}
                      />
                    </FormControl>
                  )}
                  {/* Profils du projet */}
                  {selectedProject && this.state.profils.length !== 0 && (
                    <FormControl fullWidth className={classes.margin}>
                      <DropdownList
                        filter="contains"
                        placeholder="Profil"
                        data={this.state.profils}
                        textField="LookupValue"
                        valueField="LookupId"
                        messages={{
                          open: "Profil",
                          emptyList: "Aucun profil disponible",
                          emptyFilter: "Aucun profil correspondant",
                          filterPlaceholder: "Rechercher un profil",
                        }}
                        onChange={this.onChangeProfil}
                        defaultValue={this.state.profils[0]}
                        value={this.state.selectedProfil}
                      />
                    </FormControl>
                  )}
                  {/* Charge */}
                  <FormControl
                    component={"fieldset" as "div"}
                    className={classes.margin}
                  >
                    <FormLabel component={"legend" as "caption"}>
                      Charge
                    </FormLabel>
                    <RadioGroup
                      aria-label="Charge"
                      name="charge"
                      value={charge}
                      onChange={this.onChangeCharge}
                      row
                    >
                      <FormControlLabel
                        value="0.125"
                        control={<Radio />}
                        label="0.125"
                        disabled={selectedAbsence}
                      />
                      <FormControlLabel
                        value="0.25"
                        control={<Radio />}
                        label="0.25"
                        disabled={selectedAbsence}
                      />
                      <FormControlLabel
                        value="0.375"
                        control={<Radio />}
                        label="0.375"
                        disabled={selectedAbsence}
                      />
                      <FormControlLabel
                        value="0.5"
                        control={<Radio />}
                        label="0.5"
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* Commentaire */}
                  <TextField
                    label="Remarque"
                    id="commentaire"
                    margin="dense"
                    onChange={this.onChangeCommentaire}
                    value={this.state.commentaire}
                    className={classes.margin}
                    fullWidth
                    variant="outlined"
                  />
                </div>
              </React.Fragment>
            </Paper>
            <Paper className={classes.paper} elevation={1}>
              <Export
                dateStart={dateStart}
                dateEnd={dateEnd}
                projects={projects}
                setSnackBar={this.setSnackBar}
                onExportingCRAM={this.onExportingCRAM}
                onExportedCRAM={this.onExportedCRAM}
                noActivitesOnExport={this.noActivitesOnExport}
              />
            </Paper>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h5" gutterBottom>
                <SettingsTwoTone color="primary" fontSize="small" /> Options
              </Typography>
              <FormControlLabel
                control={
                  <Switch id="deleteSwitch" onChange={this.onChangeDelete} />
                }
                label="Supprimer au clic"
              />
              {/* <FormControlLabel control={<Switch id="deleteSwitch" onChange={this.onChangeDelete} />} label="Afficher jours incomplets" /> */}
            </Paper>
            <Paper className={classes.paper} elevation={1}>
              <Badge
                color="secondary"
                showZero={true}
                badgeContent={this.state.chargeActivitiesFacturable}
                className={classes.marginBadge}
              >
                <Chip label="Facturables" color="primary" />
              </Badge>
              <Badge
                color="secondary"
                showZero={true}
                badgeContent={this.state.chargeActivitiesImputable}
                className={classes.marginBadge}
              >
                <Chip label="Imputables" color="primary" />
              </Badge>
              <Badge
                color="secondary"
                showZero={true}
                badgeContent={this.state.chargeActivitiesAbsences}
                className={classes.marginBadge}
              >
                <Chip label="Absences" color="primary" />
              </Badge>
              <Badge
                color="secondary"
                showZero={true}
                badgeContent={this.state.chargeActivities}
                className={classes.marginBadge}
              >
                <Chip label="Total" color="primary" />
              </Badge>
              <Badge
                color="secondary"
                showZero={true}
                badgeContent={this.state.businessDays}
                className={classes.marginBadge}
              >
                <Chip label="Jours ouvrés" color="primary" />
              </Badge>
            </Paper>
          </Grid>
          <Grid item md={9} style={{paddingLeft: "10px"}}>
            {this.state.loading && <Loading />}
            <div className={classes.calendar} id="calendar" />
            <Paper className={classes.paper} elevation={1}>
              <Grid container>
                <Grid item xs sm md>
                  <span className={classes.colorBlockTitle}>Facturable :</span>
                  <div
                    className={classNames(
                      classes.colorBlock,
                      classes.amFacturable
                    )}
                  >
                    <p className={classes.colorBlockText}>AM</p>
                  </div>
                  <div
                    className={classNames(
                      classes.colorBlock,
                      classes.pmFacturable
                    )}
                  >
                    <p className={classes.colorBlockText}>PM</p>
                  </div>
                </Grid>
                <Grid item xs sm md>
                  <span className={classes.colorBlockTitle}>Imputable :</span>
                  <div
                    className={classNames(
                      classes.colorBlock,
                      classes.amImputable
                    )}
                  >
                    <p className={classes.colorBlockText}>AM</p>
                  </div>
                  <div
                    className={classNames(
                      classes.colorBlock,
                      classes.pmImputable
                    )}
                  >
                    <p className={classes.colorBlockText}>PM</p>
                  </div>
                </Grid>
                <Grid item xs sm md>
                  <span className={classes.colorBlockTitle}>Absence :</span>
                  <div
                    className={classNames(
                      classes.colorBlock,
                      classes.amAbsence
                    )}
                  >
                    <p className={classes.colorBlockText}>AM</p>
                  </div>
                  <div
                    className={classNames(
                      classes.colorBlock,
                      classes.pmAbsence
                    )}
                  >
                    <p className={classes.colorBlockText}>PM</p>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {this.state.selectedActivity && (
          <Dialog
            open={this.state.modal}
            onClose={this.toggle}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {this.state.selectedActivity.nomProjet}
            </DialogTitle>
            <DialogContent className={classes.dialogTitle}>
              <Typography gutterBottom>
                {this.state.selectedActivity.service &&
                  "Service : " + this.state.selectedActivity.service}
                {this.state.selectedActivity.service && <br />}
                {this.state.selectedActivity.nomClient &&
                  "Client : " + this.state.selectedActivity.nomClient}
                {this.state.selectedActivity.nomClient && <br />}
                Charge : {this.state.selectedActivity.charge}
                <br />
                {this.state.selectedActivity.nomPhase &&
                  "Phase : " + this.state.selectedActivity.nomPhase}
                {this.state.selectedActivity.nomPhase && <br />}
                {this.state.selectedActivity.nomProfil &&
                  "Profil : " + this.state.selectedActivity.nomProfil}
                {this.state.selectedActivity.nomProfil && <br />}
                {this.state.selectedActivity.numPropale &&
                  "Numéro de Propale : " +
                    this.state.selectedActivity.numPropale}
                {this.state.selectedActivity.numPropale && <br />}
                {this.state.selectedActivity.commentaire &&
                  "Remarque : " + this.state.selectedActivity.commentaire}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.deleteEventFromModal}>
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={this.onCloseSnackBar}
        >
          <MySnackbar
            variant={this.state.typeSnackBar}
            message={this.state.messageSnackBar}
            onClose={this.onClickSnackBar}
          />
        </Snackbar>
      </div>
    );
  }

  private getDates() {
    const start = $("#calendar").fullCalendar("getView").intervalStart;
    let end = $("#calendar").fullCalendar("getView").intervalEnd;
    end = end.add(-1, "days");
    const dateStart = start.toDate();
    const dateEnd = end.toDate();
    this.setState({ dateEnd, dateStart });

    const view: string = $("#calendar").fullCalendar("getView").name;
    this.getBusinessDays(view, start);
    this.countActivities(view);
  }

  private getBusinessDays(view: string, start: Moment) {
    let daysOff: IDayOff[] | null = null;
    const day = $("#calendar").fullCalendar("getDate");
    if (view === "month") {
      const currentMonth = day.month();
      daysOff = DaysOff.getDaysOff(day.year()).filter(
        (item) =>
          item.date.month() === currentMonth &&
          !(item.date.day() === 6 || item.date.day() === 0)
      );

      const firstDayOfMonth = start.day();
      const daysInMonth = start.daysInMonth();
      let businessDays = daysInMonth - daysOff.length - 8;
      // cas si le premier jour du mois est un jeudi
      if (firstDayOfMonth === 4 && daysInMonth === 31) {
        businessDays--;
      }
      // cas si le premier jour du mois est un vendredi
      if (firstDayOfMonth === 5) {
        if (daysInMonth === 30) {
          businessDays--;
        }
        if (daysInMonth === 31) {
          businessDays -= 2;
        }
      }
      // cas si le premier jour du mois est un samedi
      if (firstDayOfMonth === 6) {
        if (daysInMonth === 29) {
          businessDays--;
        } else if (daysInMonth !== 28) {
          businessDays -= 2;
        }
      }
      // cas si le premier jour du mois est un dimanche
      if (firstDayOfMonth === 0) {
        if (daysInMonth !== 28) {
          businessDays--;
        }
      }
      this.setState({ businessDays });
    } else if (view === "basicWeek") {
      const currentWeek = day.week();
      daysOff = DaysOff.getDaysOff(day.year()).filter(
        (item) =>
          item.date.week() === currentWeek &&
          !(item.date.day() === 6 || item.date.day() === 0)
      );
      const businessDays = 5 - daysOff.length;
      this.setState({ businessDays });
    }
  }

  private countActivities(view: string) {
    let activities: Activite[] | null = null;
    const day = $("#calendar").fullCalendar("getDate");
    const currentYear = day.year();

    if (view === "month") {
      const currentMonth = day.month();
      activities = this.activites.filter(
        (item) =>
          moment(item.date).month() === currentMonth &&
          moment(item.date).year() === currentYear
      );
    } else if (view === "basicWeek") {
      const currentWeek = day.week();
      activities = this.activites.filter(
        (item) =>
          moment(item.date).week() === currentWeek &&
          moment(item.date).year() === currentYear
      );
    }

    if (activities) {
      const activitiesFacturable: Activite[] = activities.filter(
        (item) => item.typeProjet === "Facturable"
      );
      const activitiesImputable: Activite[] = activities.filter(
        (item) =>
          item.typeProjet === "Imputable" &&
          item.nomProjet !== process.env.REACT_APP_PROJECTNAMEABSENCES
      );
      const activitiesAbsences: Activite[] = activities.filter(
        (item) => item.nomProjet === process.env.REACT_APP_PROJECTNAMEABSENCES
      );

      const chargeActivitiesFacturable: number =
        activitiesFacturable.length !== 0
          ? activitiesFacturable
              .map((item) => item.charge)
              .reduce((prev, next) => prev + next)
          : 0;
      const chargeActivitiesImputable: number =
        activitiesImputable.length !== 0
          ? activitiesImputable
              .map((item) => item.charge)
              .reduce((prev, next) => prev + next)
          : 0;
      const chargeActivitiesAbsences: number =
        activitiesAbsences.length !== 0
          ? activitiesAbsences
              .map((item) => item.charge)
              .reduce((prev, next) => prev + next)
          : 0;

      const chargeActivities: number =
        chargeActivitiesFacturable +
        chargeActivitiesImputable +
        chargeActivitiesAbsences;

      this.setState({
        chargeActivities,
        chargeActivitiesAbsences,
        chargeActivitiesFacturable,
        chargeActivitiesImputable,
      });
    }
  }

  /**
   * Récupère les activités de l'utilisateur connecté
   */
  private async getActivities(): Promise<void> {
    const user = this.auth.getAccount().name;
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_MEMBRES +
      "/items?";
    const expand = "expand=fields(select=nomMembre,numProjet_x003a_idList)";
    const filter = `&filter=fields/nomMembre eq '${user}'`;
    const promises: Array<Promise<Activite[]>> = [];
    const idListProjects: string[] = [];

    if (process.env.REACT_APP_LISTID_AUTRES) {
      idListProjects.push(process.env.REACT_APP_LISTID_AUTRES);
    }

    try {
      const response = await API.get(url + expand + filter);

      for (const member of response.data.value) {
        idListProjects.push(member.fields.numProjet_x003a_idList);
      }
    } catch (error) {
      // TO DO handle error
    }

    for (const idListProject of idListProjects) {
      promises.push(this.getActivitiesByProject(idListProject));
    }

    return Promise.all(promises).then((activities: Activite[][]) => {
      if (activities.length !== 0) {
        this.activites = activities.reduce((prev, next) => prev.concat(next));
        this.renderActivities();
      } else {
        this.setState({ loading: false });
      }
    });
  }

  private async getActivitiesByProject(
    idListProject: string
  ): Promise<Activite[]> {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      idListProject +
      "/items?";
    const top = "top=5000&";
    const expand =
      "expand=fields(select=id,Title,PNum,NumProjet,Jour,Temps,Meridiem,NomClient,NumClient,Phase,Profil,Commentaire,TypeProjet,Activite_x003a_Couleur,Activite_x003a_Titre)";
    const response = await API.get(url + top + expand);
    const activites: Activite[] = [];

    for (const activity of response.data.value) {
      const fields = activity.fields;

      if (
        activity.createdBy.user.displayName ===
        this.auth.getAccount().name
      ) {
        activites.push({
          charge: +fields.Temps,
          commentaire: fields.Commentaire,
          couleur: fields.Activite_x003a_Couleur,
          date: fields.Jour,
          id: +fields.id,
          idList: idListProject,
          meridiem: fields.Meridiem,
          nomClient: fields.NomClient,
          nomPhase: fields.Phase,
          nomProfil: fields.Profil,
          nomProjet: fields.Title,
          numClient: fields.NumClient,
          numProjet: fields.NumProjet,
          numPropale: fields.PNum,
          service: fields.Activite_x003a_Titre,
          typeProjet: fields.TypeProjet,
        });
      }
    }

    return activites;
  }

  private renderActivity(
    activity: Activite,
    idActivity?: number
  ): EventObjectInput {
    let color: string = "#ffffff";
    let title: string;

    const description =
      activity.nomProjet +
      (activity.nomClient ? "\nClient : " + activity.nomClient : "") +
      "\nCharge : " +
      activity.charge +
      "" +
      (activity.nomPhase ? "\nPhase : " + activity.nomPhase : "") +
      (activity.nomProfil ? "\nProfil : " + activity.nomProfil : "") +
      (activity.service ? "\nService : " + activity.service : "") +
      (activity.numPropale ? "\nN° de Propale : " + activity.numPropale : "");

    title = activity.charge.toFixed(3) + " - " + activity.nomProjet;

    if (activity.nomProjet === process.env.REACT_APP_PROJECTNAMEABSENCES) {
      title = activity.charge.toFixed(3) + " - " + activity.nomPhase;
      switch (activity.meridiem) {
        case "AM":
          color = amAbsence;
          break;
        case "PM":
          color = pmAbsence;
          break;
      }
    } else if (activity.typeProjet === "Facturable") {
      switch (activity.meridiem) {
        case "AM":
          color = amFacturable;
          break;
        case "PM":
          color = pmFacturable;
          break;
      }
    } else {
      switch (activity.meridiem) {
        case "AM":
          color = amImputable;
          break;
        case "PM":
          color = pmImputable;
          break;
      }
    }

    const event: EventObjectInput = {
      color,
      description,
      editable: false,
      id: idActivity ? idActivity : this.idNewEvent--,
      idList: activity.idList,
      service: activity,
      start:
        activity.date +
        (activity.meridiem === "AM" ? "T08:00:00" : "T14:00:00"),
      textColor: "white",
      title,
    };

    $("#calendar").fullCalendar("renderEvent", event, true);

    return event;
  }

  private renderActivities(): void {
    let color: string = "#ffffff";
    let title: string;
    const events: EventObjectInput[] = [];

    $("#calendar").fullCalendar("removeEvents");

    for (const activity of this.activites) {
      const description =
        activity.nomProjet +
        (activity.nomClient ? "\nClient : " + activity.nomClient : "") +
        "\nCharge : " +
        activity.charge +
        "" +
        (activity.nomPhase ? "\nPhase : " + activity.nomPhase : "") +
        (activity.nomProfil ? "\nProfil : " + activity.nomProfil : "") +
        (activity.service ? "\nService : " + activity.service : "") +
        (activity.numPropale ? "\nN° de Propale : " + activity.numPropale : "");

      title = activity.charge.toFixed(3) + " - " + activity.nomProjet;

      if (activity.nomProjet === process.env.REACT_APP_PROJECTNAMEABSENCES) {
        title = activity.charge.toFixed(3) + " - " + activity.nomPhase;
        switch (activity.meridiem) {
          case "AM":
            color = amAbsence;
            break;
          case "PM":
            color = pmAbsence;
            break;
        }
      } else if (activity.typeProjet === "Facturable") {
        switch (activity.meridiem) {
          case "AM":
            color = amFacturable;
            break;
          case "PM":
            color = pmFacturable;
            break;
        }
      } else {
        switch (activity.meridiem) {
          case "AM":
            color = amImputable;
            break;
          case "PM":
            color = pmImputable;
            break;
        }
      }

      const event: EventObjectInput = {
        color,
        charge: activity.charge,
        description,
        service: activity,
        editable: false,
        id: +activity.id,
        idList: activity.idList,
        start:
          activity.date +
          (activity.meridiem === "AM" ? "T08:00:00" : "T14:00:00"),
        textColor: "white",
        title,
      };

      events.push(event);
    }

    // show calendar
    this.setState({ loading: false }, () => {
      $("#calendar").show();
      $("#calendar").fullCalendar("render");
      $("#calendar").fullCalendar("renderEvents", events, true);
    });
    
    this.getDates();
  }

  private createActivity(date: string, meridiem: string): void {
    const projectType = this.state.facturable ? "Facturable" : "Imputable";

    const propaleValue = this.state.selectedPropale
      ? this.state.selectedPropale.Title
      : "";
    const propaleTitle = this.state.selectedPropaleTitle
      ? this.state.selectedPropaleTitle.LookupValue
      : "";
    const phaseValue = this.state.selectedPhase
      ? this.state.selectedPhase.LookupValue
      : "";
    const profilValue = this.state.selectedProfil
      ? this.state.selectedProfil.LookupValue
      : "";

    const data: IActivitePost = {
      ActiviteLookupId: this.state.selectedTypeActivite.id,
      Commentaire: this.state.commentaire,
      Entreprise: localStorage.getItem("company") || "",
      Jour: date,
      Meridiem: meridiem,
      Nom: localStorage.getItem("surname") || "",
      PNum: propaleValue,
      PObjet: propaleTitle,
      Phase: phaseValue,
      Prenom: localStorage.getItem("givenName") || "",
      Profil: profilValue,
      Temps: +this.state.selectedCharge,
      Title: this.state.selectedProjectName,
      TypeProjet: projectType,
    };

    let url = "sites/" + process.env.REACT_APP_SITEID + "/lists/";

    if (this.state.selectedProject) {
      data.ClientFinal = this.state.selectedProject.clientFinal;
      data.NomClient = this.state.selectedProject.nomClient;
      data.NumClient = this.state.selectedProject.numClient;
      data.Technologie = this.state.selectedProject.technologie;
      url += this.state.selectedProject.idList + "/items";
    } else {
      data.ClientFinal = "BSD";
      data.NomClient = "BSD";
      data.PNum = "A000001";
      data.PObjet = "";
      data.NumClient = "C940000";
      data.Phase = "General";
      data.Profil = "Collaborateur";
      data.Technologie = "";
      url += process.env.REACT_APP_LISTID_AUTRES + "/items";
    }

    const options: AxiosRequestConfig = {
      method: "POST",
      data: { fields: data },
      url,
    };

    const activite: Activite = {
      charge: data.Temps,
      commentaire: data.Commentaire,
      couleur: "#ffffff",
      date: data.Jour,
      id: 0,
      idList: this.state.selectedProject
        ? this.state.selectedProject.idList
        : this.state.selectedTypeActivite.title === "Autres" &&
          process.env.REACT_APP_LISTID_AUTRES
        ? process.env.REACT_APP_LISTID_AUTRES
        : "",
      meridiem: data.Meridiem,
      nomClient: "",
      // nomClient: data.NomClient,
      nomPhase: data.Phase,
      nomProfil: data.Profil,
      nomProjet: data.Title,
      numClient: "",
      // numClient: data.NumClient,
      numProjet: this.state.selectedProject
        ? this.state.selectedProject.numProjet
        : "",
      numPropale: data.PNum,
      service: this.state.selectedTypeActivite
        ? this.state.selectedTypeActivite.title
        : "",
      typeProjet: data.TypeProjet,
    };

    this.activites.push(activite);
    const view: string = $("#calendar").fullCalendar("getView").name;
    this.countActivities(view);
    let event: EventObjectInput = this.renderActivity(activite);

    API(options)
      .then((response) => {
        this.activites[this.activites.indexOf(activite)].id = +response.data.id;
        event = $("#calendar").fullCalendar(
          "clientEvents",
          (currentEvent: EventObjectInput) =>
            event.id === currentEvent.id && event.idList === currentEvent.idList
        )[0];
        event.id = +response.data.id;
        $("#calendar").fullCalendar("updateEvent", event);
      })
      .catch((error) => {
        this.activites.splice(this.activites.indexOf(activite), 1);
        // const view: string = $('#calendar').fullCalendar('getView').name;
        this.countActivities(view);
        event = $("#calendar").fullCalendar(
          "clientEvents",
          (currentEvent: EventObjectInput) =>
            event.id === currentEvent.id && event.idList === currentEvent.idList
        )[0];
        $("#calendar").fullCalendar(
          "removeEvents",
          (currentEvent: EventObjectInput) =>
            event.id === currentEvent.id && event.idList === currentEvent.idList
        );
        this.openSnackBar(
          "La saisie de l'activité n'a pas été prise en compte, veuillez recommencer.",
          "error"
        );
      });
  }

  private async getProjects(): Promise<void> {
    const user = this.auth.getAccount().name;
    const filter = `&filter=fields/nomMembre eq '${user}'`;
    const projects: IProject[] = await SharePointDataService.getProjects(
      filter
    );

    this.setState({ projects });
  }

  private createEvent = (date: string) => {
    const { AM, PM } = this.state;

    this.setState({ dateClicked: date });

    if (AM) {
      this.createEventForMeridiem(date, "AM");
    }

    if (PM) {
      this.createEventForMeridiem(date, "PM");
    }
  };

  private createEventForMeridiem(date: string, meridiem: string) {
    const activites: Activite[] = this.activites.filter(
      (item) => item.date === date && item.meridiem === meridiem
    );
    const isFormValid: boolean = this.checkForm();
    let charge = 0;

    if (activites.length !== 0) {
      charge = activites
        .map((item) => item.charge)
        .reduce((prev, next) => prev + next);
    }

    if (isFormValid && charge + +this.state.selectedCharge <= 0.5) {
      this.createActivity(date, meridiem);
    } else if (!isFormValid) {
      this.openSnackBar("Veuillez renseigner tous les champs.", "error");
    } else {
      this.openSnackBar(
        "Impossible d'ajouter cette activité car la charge dépasserait 0.5j.",
        "info"
      );
    }
  }

  private deleteEvent = (selectedActivity: Activite) => {
    const id = selectedActivity.id;

    if (id === 0) {
      this.openSnackBar(
        "La suppression n'a pas été effectuée, veuillez recommencer.",
        "error"
      );
    } else {
      const idList = selectedActivity.idList;
      const url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        idList +
        "/items/" +
        id;

      this.activites.splice(this.activites.indexOf(selectedActivity), 1);
      const view: string = $("#calendar").fullCalendar("getView").name;
      this.countActivities(view);
      $("#calendar").fullCalendar(
        "removeEvents",
        (event: EventObjectInput) => event.id === id && event.idList === idList
      );

      API.delete(url)
        .then()
        .catch((error) => {
          this.activites.push(selectedActivity);
          // const view: string = $('#calendar').fullCalendar('getView').name;
          this.countActivities(view);
          this.renderActivity(selectedActivity, selectedActivity.id);
          this.openSnackBar(
            "La suppression n'a pas été effectuée, veuillez recommencer.",
            "error"
          );
        });
    }
  };

  private deleteEventFromModal = () => {
    if (!this.state.selectedActivity) {
      return;
    }

    const id = this.state.selectedActivity.id;

    if (id === 0) {
      this.openSnackBar(
        "La suppression n'a pas été effectuée, veuillez recommencer.",
        "error"
      );
    } else {
      const idList = this.state.selectedActivity.idList;
      const url =
        "sites/" +
        process.env.REACT_APP_SITEID +
        "/lists/" +
        idList +
        "/items/" +
        id;

      this.activites.splice(
        this.activites.indexOf(this.state.selectedActivity),
        1
      );
      const view: string = $("#calendar").fullCalendar("getView").name;
      this.countActivities(view);
      $("#calendar").fullCalendar(
        "removeEvents",
        (event: EventObjectInput) => event.id === id && event.idList === idList
      );
      this.toggle();

      API.delete(url)
        .then()
        .catch((error) => {
          if (this.state.selectedActivity) {
            this.activites.push(this.state.selectedActivity);
            // const view: string = $('#calendar').fullCalendar('getView').name;
            this.countActivities(view);
            this.renderActivity(
              this.state.selectedActivity,
              this.state.selectedActivity.id
            );
            this.openSnackBar(
              "La suppression n'a pas été effectuée, veuillez recommencer.",
              "error"
            );
          }
        });
    }
  };

  private checkForm(): boolean {
    const projectType = this.state.facturable ? "Facturable" : "Imputable";
    const typeActivite = this.state.selectedTypeActivite.title;
    const title = this.state.selectedProjectName;
    let isValid = true;

    isValid = typeActivite === "Autres" ? title !== "" : isValid;
    isValid =
      typeActivite !== "Autres" && projectType === "Facturable"
        ? this.state.selectedProfil !== null
        : isValid;
    isValid =
      typeActivite !== "Autres" && projectType === "Imputable"
        ? this.state.selectedPhase !== null
        : isValid;

    return isValid;
  }

  private onChangeDelete = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ deleteOnClick: event.currentTarget.checked, modal: false });
  };

  private toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  private openSnackBar(
    messageSnackBar: string,
    typeSnackBar: "success" | "warning" | "error" | "info"
  ) {
    this.setState({ openSnackBar: true, typeSnackBar, messageSnackBar });
  }

  private onCloseSnackBar = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    reason: string
  ) => {
    this.setState({ openSnackBar: false });
  };

  private onClickSnackBar = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ openSnackBar: false });
  };

  private setSnackBar = (open : boolean, snackBarType:SnackBarType = "info", snackBarMessage:string = "") => {
    this.setState({ openSnackBar: open, typeSnackBar: snackBarType, messageSnackBar: snackBarMessage });
  }

  private onExportingCRAM = () => {
    this.setState({
      openSnackBar: true,
      typeSnackBar: "info",
      messageSnackBar: "CRAM en cours d'export...",
    });
  };

  private onExportedCRAM = () => {
    this.setState({ openSnackBar: false });
  };

  private noActivitesOnExport = () => {
    this.setState({
      openSnackBar: true,
      typeSnackBar: "warning",
      messageSnackBar: "Aucune activité déclaré pour cette période.",
    });
  };

  // TO DO - Move to ActivityForm

  private getTypesActivites() {
    const typesActivites: TypeActivite[] = [];
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_SERVICES +
      "/items?";
    const expand = "expand=fields(select=id,Title)";

    API.get(url + expand)
      .then((response) => {
        for (const value of response.data.value) {
          const fields = value.fields;

          const typeActivite: any = {
            id: +fields.id,
            title: fields.Title,
          };

          typesActivites.push(typeActivite);
        }

        typesActivites.sort((a: TypeActivite, b: TypeActivite) =>
          a.title > b.title ? 1 : -1
        );

        const productionTypeActivite: TypeActivite = typesActivites.find(
          (typeActivite) => typeActivite.title === "Production"
        ) as TypeActivite;

        this.setState({
          selectedTypeActivite: productionTypeActivite,
          typesActivites,
        });
      })
      .catch((error) => {
        // TO DO handle error
      });
  }

  private async getPhases(project: IProject): Promise<Lookup[]> {
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PHASES +
      "/items?";
    const expand = "expand=fields(select=id,nomPhase,enabled,IdProjetLookupId)";
    const filter = `&filter=(fields/enabled eq 1) and (fields/IdProjetLookupId eq ${project.idProjet})`;

    try {
      const response = await API.get(url + expand + filter);
      const phases: Lookup[] = [];

      for (const phase of response.data.value) {
        const fields = phase.fields;

        phases.push({
          LookupId: +fields.id,
          LookupValue: fields.nomPhase,
        });
      }

      phases.sort((a: Lookup, b: Lookup) =>
        a.LookupValue > b.LookupValue ? 1 : -1
      );

      return phases;
    } catch (error) {
      // TO DO handle error
      return [];
    }
  }

  private async getProfilsFromPhase(
    idPhase: number,
    project?: IProject
  ): Promise<Lookup[]> {
    const profils: Lookup[] = [];
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_PHASES +
      "/items/" +
      idPhase +
      "?";
    const expand = "expand=fields(select=id,numProfils)";

    try {
      const selectedProject: IProject | null =
        project || this.state.selectedProject;

      if (selectedProject) {
        const response = await API.get(url + expand);

        for (const profil of response.data.fields.numProfils) {
          for (const projectProfils of selectedProject.profils) {
            if (projectProfils.LookupId === profil.LookupId) {
              profils.push(projectProfils);
            }
          }
        }
      }

      profils.sort((a: Lookup, b: Lookup) =>
        a.LookupValue > b.LookupValue ? 1 : -1
      );

      return profils;
    } catch (error) {
      // TO DO handle error
      return [];
    }
  }

  private getPropales = (item: IPropale): string => {
    return item.PObjet == undefined ? item.Title : item.Title + " - " + item.PObjet;
  };

  private onChangeTypeActivite = (typeActivite: TypeActivite) => {
    if (typeActivite.title === "Autres") {
      this.setState({
        selectedPhase: null,
        selectedProfil: null,
        selectedProject: null,
        selectedProjectName: "",
        selectedTypeActivite: typeActivite,
        showLibre: true,
      });
    } else {
      this.setState({ selectedTypeActivite: typeActivite, showLibre: false });
    }
  };

  private onChangeProjet = (project: IProject) => {
    let indexProfil = 0;
    let indexPhase = 0;
    let indexPropale = 0;

    if (!localStorage[project.idList]) {
      const projectData = { propale: 0, phase: 0, profil: 0 };
      const JSONprojectData = JSON.stringify(projectData);
      localStorage.setItem(project.idList, JSONprojectData);
    } else {
      const READProjectData = JSON.parse(localStorage[project.idList]);
      indexProfil = READProjectData.profil > -1 ? READProjectData.profil : 0;
      indexPhase = READProjectData.phase > -1 ? READProjectData.phase : 0;
      indexPropale = READProjectData.propale > -1 ? READProjectData.propale : 0;
    }

    // Getting propales
    SharePointDataService.getActivePropales(project.numPropales.map(_ => _.LookupId))
      .then((response: IPropale[]) => {
        this.setState({
          propales : response
        });
      })

    let { facturable, selectedAbsence, selectedCharge } = this.state;

    if (project.nomProjet === process.env.REACT_APP_PROJECTNAMEABSENCES) {
      const typeActivite = this.state.typesActivites.find(
        (item) => item.title === "Ressources Humaines"
      );

      if (typeActivite) {
        this.setState({ selectedTypeActivite: typeActivite });
      }

      facturable = false;
      selectedAbsence = true;
      selectedCharge = 0.5;
    } else {
      selectedAbsence = false;
    }

    const propaleTitle =
      project.titlePropales.find(
        (titlePropale) =>
          titlePropale.LookupId === project.numPropales[indexPropale].LookupId
      ) || null;

    if (project.phasesLoaded) {
      if (project.phases.length !== 0) {
        this.getProfilsFromPhase(
          project.phases[indexPhase].LookupId,
          project
        ).then((profils: Lookup[]) => {
          this.setState({
            commentaire: "",
            selectedAbsence,
            facturable,
            profils,
            selectedCharge,
            selectedPhase: project.phases[indexPhase],
            selectedProfil: profils[indexProfil],
            selectedProject: project,
            selectedProjectName: project.nomProjet,
            selectedPropale: this.state.propales[indexPropale],
            selectedPropaleTitle: propaleTitle,
          });
        });
      } else {
        this.setState({
          commentaire: "",
          selectedAbsence,
          profils: project.profils,
          selectedCharge,
          selectedPhase: null,
          selectedProject: project,
          selectedProjectName: project.nomProjet,
          selectedPropale: this.state.propales[indexPropale],
          selectedPropaleTitle: propaleTitle,
        });
      }
    } else {
      this.getPhases(project).then((phases: Lookup[]) => {
        project.phases = phases;
        project.phasesLoaded = true;

        if (project.phases.length !== 0) {
          this.getProfilsFromPhase(
            project.phases[indexPhase].LookupId,
            project
          ).then((profils: Lookup[]) => {
            this.setState({
              commentaire: "",
              selectedAbsence,
              facturable,
              profils,
              selectedCharge,
              selectedPhase: project.phases[indexPhase],
              selectedProfil: profils[indexProfil],
              selectedProject: project,
              selectedProjectName: project.nomProjet,
              selectedPropale: this.state.propales[indexPropale],
              selectedPropaleTitle: propaleTitle,
            });
          });
        } else {
          this.setState({
            commentaire: "",
            selectedAbsence,
            facturable,
            profils: project.profils,
            selectedCharge,
            selectedPhase: null,
            selectedProject: project,
            selectedProjectName: project.nomProjet,
            selectedPropale: this.state.propales[indexPropale],
            selectedPropaleTitle: propaleTitle,
          });
        }
      });
    }
  };

  private onChangePhase = (phase: Lookup) => {
    if (this.state.selectedProject) {
      const indexPhase = this.state.selectedProject.phases.indexOf(phase);
      const changeProjectData = JSON.parse(
        localStorage[this.state.selectedProject.idList]
      );
      changeProjectData.phase = indexPhase;
      changeProjectData.profil = 0;
      localStorage.setItem(
        this.state.selectedProject.idList,
        JSON.stringify(changeProjectData)
      );
    }
    this.setState({ selectedPhase: phase });
    this.getProfilsFromPhase(phase.LookupId).then((profils: Lookup[]) => {
      this.setState({
        profils,
        selectedProfil: profils[0],
      });
    });
  };

  private onChangePropale = (propale: IPropale) => {
    let propaleTitle: Lookup | null = null;

    if (this.state.selectedProject) {
      const indexPropale = this.state.propales.indexOf(
        propale
      );
      const changeProjectData = JSON.parse(
        localStorage[this.state.selectedProject.idList]
      );
      changeProjectData.propale = indexPropale;
      localStorage.setItem(
        this.state.selectedProject.idList,
        JSON.stringify(changeProjectData)
      );

      if (propale) {
        const foundPropaleWithTitle = this.state.selectedProject.titlePropales.find(
          (propaleWithTitle) => propaleWithTitle.LookupId === propale.id
        );

        if (foundPropaleWithTitle) {
          propaleTitle = foundPropaleWithTitle;
        }
      }
    }

    this.setState({
      selectedPropale: propale,
      selectedPropaleTitle: propaleTitle,
    });
  };

  private onChangeProfil = (profil: Lookup) => {
    if (this.state.selectedProject) {
      const indexProfil = this.state.profils.indexOf(profil);
      const changeProjectData = JSON.parse(
        localStorage[this.state.selectedProject.idList]
      );
      changeProjectData.profil = indexProfil;
      localStorage.setItem(
        this.state.selectedProject.idList,
        JSON.stringify(changeProjectData)
      );
    }
    this.setState({ selectedProfil: profil });
  };

  private onChangeLibre = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedProjectName: event.currentTarget.value });
  };

  private onChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ [event.currentTarget.id]: event.currentTarget.checked });
  };

  private onChangeCharge = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({ charge: value });
    this.setState({ selectedCharge: value });
  };

  private onChangeCommentaire = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ commentaire: event.currentTarget.value });
  };

  private groupByClient = (project: IProject) => {
    return project.nomClient;
  };
}

export default withStyles(styles)(Calendar);
