import React, { Component } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

interface ITableDisplayProps {
  columns: any[];
  rows: any[];
}

interface ITableDisplayState {
  page: number;
  rowsPerPage: number;
}

interface ILabelDisplayedRowsArgs {
  from: number;
  to: number;
  count: number;
  page: number;
}

class TableDisplay extends Component<ITableDisplayProps, ITableDisplayState> {
  constructor(props: Readonly<ITableDisplayProps>) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
  }

  public render() {
    const overflow = { overflow: 'auto' };

    return (
      <Paper style={overflow}>
        <Table>
          <TableHead>
            <TableRow>{this.props.columns}</TableRow>
          </TableHead>
          <TableBody>
            {this.props.rows.slice(
              this.state.page * this.state.rowsPerPage,
              this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.props.rows.length}
          labelRowsPerPage="Nombre d'éléments par page"
          labelDisplayedRows={this.handleChangelabelDisplayRows}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }

  private handleChangelabelDisplayRows = (
    paginationInfo: ILabelDisplayedRowsArgs
  ): string => {
    return (
      paginationInfo.from +
      '-' +
      paginationInfo.to +
      ' sur ' +
      paginationInfo.count
    );
  };

  private handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    this.setState({ page });
  };

  private handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };
}

export default TableDisplay;
