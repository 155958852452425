import React, { Component } from 'react';

import { AppBar, Tab, Tabs } from '@material-ui/core';

import API from '../../services/Api';

import Loading from '../Loader';
import ArchiveProject from './ArchiveProject';
import DisplayProject from './DisplayProject';
import ManageProject from './ManageProject';
import ProjectAcceptation from './ProjectAcceptation';

import Lookup from '../../interfaces/lookup';
import Owner from '../../interfaces/owner';
import Profil from '../../interfaces/profil';
import { IProject } from '../../interfaces/project';
import Teams from '../../interfaces/teams';

interface IState {
  profils: Profil[];
  projects: IProject[];
  teams: Teams[];
  projectSelected: IProject | null;
  value: number;
}

class ProjectList extends Component<{}, IState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      profils: [],
      projectSelected: null,
      projects: [],
      teams: [],
      value: 0,
    };
  }

  public componentDidMount() {
    this.getProfils();
    this.refreshData();
  }

  public render() {
    const { value } = this.state;

    if (this.state.projects === null) {
      return <Loading />;
    }
    if (this.state.projectSelected != null) {
      return (
        <ManageProject
          project={this.state.projectSelected}
          profils={this.state.profils}
          goBackToProjects={this.goBackToProjects}
        />
      );
    } else {
      const existingProjects = this.state.projects.filter((project) =>
        this.state.teams.map((team) => team.id).includes(project.idTeams)
      );
      const newTeams = this.state.teams.filter(
        (team) =>
          !this.state.projects
            .map((project) => project.idTeams)
            .includes(team.id)
      );

      return (
        <React.Fragment>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="fullWidth"
            >
              <Tab label="Gestion des projets" />
              <Tab label="Gestion des teams" />
              <Tab label="Archiver un projet" />
            </Tabs>
          </AppBar>

          {value === 0 && (
            <DisplayProject
              projects={this.state.projects}
              projectModified={this.refreshData}
              projectSelected={this.projectSelected}
            />
          )}
          {value === 1 && (
            <ProjectAcceptation
              teams={newTeams}
              projectModified={this.refreshData}
            />
          )}
          {value === 2 && (
            <ArchiveProject
              projects={existingProjects}
              projectModified={this.refreshData}
            />
          )}
        </React.Fragment>
      );
    }
  }

  private handleChange = (event: any, value: any) => {
    this.setState({ value });
  };

  private projectSelected = (project: IProject) => {
    this.setState({ projectSelected: project });
  };

  private goBackToProjects = () => {
    this.setState({ projectSelected: null });
    this.refreshData();
  };

  private refreshData = () => {
    const projects: IProject[] = [];
    let url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PROJETS +
      '/items?';
    const expand =
      'expand=fields(select=ID,IdTeams,idList,Title,NumProjet,PNums,PNums_x003a_PObjet,NumClient,NumClientLookupId,NumClient_x003a_Title,enabled,numPhases_x003a_nomPhase,numProfils_x003a_nomProfil,NumClient_x003a_Compte,Technologie)';

    API.get(url + expand)
      .then((response) => {
        for (const projet of response.data.value) {
          const nomClient: string = !projet.fields.NumClient_x003a_Title
            ? ''
            : projet.fields.NumClient_x003a_Title;
          const numClient: string = !projet.fields.NumClient
            ? ''
            : projet.fields.NumClient;
          const numProjet: string = !projet.fields.NumProjet
            ? ''
            : projet.fields.NumProjet;
          const numPropales: Lookup[] = !projet.fields.PNums
            ? []
            : projet.fields.PNums;
          const titlePropales: Lookup[] = !projet.fields.PNums_x003a_PObjet
            ? []
            : projet.fields.PNums_x003a_PObjet;

          projects.push({
            clientFinal: projet.fields.NumClient_x003a_Compte,
            enabled: projet.fields.enabled,
            idClient: projet.fields.NumClientLookupId,
            idList: projet.fields.idList,
            idProjet: +projet.fields.id,
            idTeams: projet.fields.IdTeams,
            nomClient,
            nomProjet: projet.fields.Title,
            numClient,
            numProjet,
            numPropales,
            titlePropales,
            phases: projet.fields.numPhases_x003a_nomPhase,
            profils: projet.fields.numProfils_x003a_nomProfil,
            technologie: projet.fields.Technologie,
            typeProjet: '',
          });
        }

        url = "groups?$filter=resourceProvisioningOptions/Any(x:x eq 'Team')&$top=999";

        API.get(url)
          .then((responseTeams) => {
            const teams: Teams[] = [];
            const promises: Array<Promise<any>> = [];

            for (const value of responseTeams.data.value) {
              promises.push(this.getOwners(value.id));

              const team: Teams = {
                description: value.description,
                displayName: value.displayName,
                id: value.id,
                mail: value.mail,
                owners: [],
              };
              teams.push(team);
            }

            Promise.all(promises).then((responses: any) => {
              for (const responseOwners of responses) {
                const team = teams.find(
                  (item) => item.id === responseOwners.idTeams
                );

                if (team) {
                  team.owners = responseOwners.owners;
                }
              }

              this.setState({ projects, teams });
            });
          })
          .catch((error) => {
            // TO DO : handler error
          });
      })
      .catch((error) => {
        // TO DO : handler error
      });
  };

  private async getOwners(
    idTeams: string
  ): Promise<{ idTeams: string; owners: Owner[] }> {
    const url = 'groups/' + idTeams + '/owners';
    const owners: Owner[] = [];

    try {
      const response = await API.get(url);

      for (const value of response.data.value) {
        owners.push(value);
      }

      return { idTeams, owners };
    } catch (error) {
      // TO DO handle eowners
      return { idTeams, owners };
    }
  }

  /**
   * Récupère la liste de tous les profils existants
   */
  private getProfils() {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PROFILS +
      '/items?';
    const expand = 'expand=fields(select=id,numProfil,nomProfil)';

    API.get(url + expand)
      .then((response) => {
        const profils: Profil[] = [];

        for (const profil of response.data.value) {
          profils.push({
            id: +profil.fields.id,
            nomProfil: profil.fields.nomProfil,
            numProfil: profil.fields.numProfil,
          });
        }
        profils.sort(this.compare);
        this.setState({ profils });
      })
      .catch((error) => {
        // TO DO : handler error
      });
  }

  private compare(a: Profil, b: Profil): number {
    if (a.nomProfil.toLocaleLowerCase() < b.nomProfil.toLocaleLowerCase()) {
      return -1;
    }
    if (a.nomProfil.toLocaleLowerCase() > b.nomProfil.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  }
}

export default ProjectList;
