import React, { Component } from 'react';

import { Grid } from '@material-ui/core';

import CreateProfil from './CreateProfil';
import DisplayProfil from './DisplayProfil';

import Profil from '../../../interfaces/profil';
import ProfilTJM from '../../../interfaces/profil-tjm';
import { IProject } from '../../../interfaces/project';

interface IProfilProps {
  projet: IProject;
  profils: Profil[];
  profilsTJM: ProfilTJM[];
  refreshProject: () => Promise<IProject>;
  refreshProfilTJM: () => void;
}

interface IProfilState {
  profilsTJM: ProfilTJM[];
  refresh: boolean;
}

class ManageProfils extends Component<IProfilProps, IProfilState> {
  constructor(props: Readonly<IProfilProps>) {
    super(props);

    this.state = {
      profilsTJM: [],
      refresh: false,
    };
  }

  public componentDidMount() {
    this.refreshData();
  }

  public render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={8}>
          <CreateProfil
            profilCreated={this.refreshData}
            profils={this.props.profils}
            profilsTJM={this.props.profilsTJM}
            projet={this.props.projet}
          />
        </Grid>
        <Grid item md={8}>
          <DisplayProfil profilsTJM={this.props.profilsTJM} />
        </Grid>
      </Grid>
    );
  }

  private refreshData = () => {
    this.props.refreshProfilTJM();
    this.props.refreshProject();
  };
}

export default ManageProfils;
