import React, { Component } from 'react';
import { Redirect } from 'react-router';

import { Typography, Button, CssBaseline, Paper } from '@material-ui/core';
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';

import Auth from '../services/Auth';

import picture from './Ressources/time_management.jpg';

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    img: {
      maxWidth: '100%',
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(),
      padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    title: {
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
        theme.spacing(3)
      }px`,
    },
  });

interface IProps extends WithStyles<typeof styles> {}

class Connect extends Component<IProps> {
  private authService: Auth;

  constructor(props: Readonly<IProps>) {
    super(props);
    this.authService = new Auth();
  }

  public render() {
    const { classes } = this.props;

    if (!this.authService.isConnected()) {
      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <img className={classes.img} src={picture} />
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.title}
            >
              Se connecter avec votre compte Office 365.
            </Typography>
            <form className={classes.form}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleLoginClick}
              >
                Se connecter
              </Button>
            </form>
          </Paper>
        </main>
      );
    } else {
      return <Redirect to="/" />;
    }
  }

  private handleLoginClick = () => {
    this.authService.login();
  };
}

export default withStyles(styles)(Connect);
