import React, { Component } from 'react';

import error404 from './Ressources/error404.jpg';

class PageNotFound extends Component {
    public render() {
        const alignText: React.CSSProperties = {
            margin: "15vh auto 0",
            textAlign: "center",
            width: "80vw"
        };

        const buttonAlign: React.CSSProperties = {
            marginTop: "3vh"
        };

        const imageSize: React.CSSProperties = {
            width: "50vw"
        };

        const textStyle: React.CSSProperties = {
            width: "100%"
        };

        return (
            <div style={alignText}>
                <img src={error404} style={imageSize} alt="Erreur 404" />
                <h2 style={textStyle}>La page que vous recherchez n'existe pas !</h2>
                <h3 style={buttonAlign}><a className="btn btn-primary" href="/Home" role="button">Retour à l'accueil</a></h3>
            </div>
        );
    }
}

export default PageNotFound;
