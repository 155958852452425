import React, { Component } from 'react';

import API from '../../../services/Api';

import TableMembers from './TableMembers';

import MemberProject from '../../../classes/member-project';

import GraphUser from '../../../interfaces/graph-user';
import { IProject } from '../../../interfaces/project';
import { SharePointDataService } from '../../../services/SharePointDataService';

interface IMembersProps {
  project: IProject;
}

interface IMembersState {
  contributors: GraphUser[];
  chefDeProjets: GraphUser[];
  members: MemberProject[];
}

class Members extends Component<IMembersProps, IMembersState> {
  constructor(props: Readonly<IMembersProps>) {
    super(props);

    this.state = {
      chefDeProjets: [],
      contributors: [],
      members: [],
    };
  }

  public componentDidMount() {
    this.getMembers(this.props.project.idTeams);
    // this.getSharePointMembers(this.props.project.idProjet);
    this.refreshData();
  }

  public render() {
    return (
      <TableMembers
        members={this.state.members}
        membersChanged={this.refreshData}
        contributors={this.state.contributors}
        project={this.props.project}
        chefDeProjets={this.state.chefDeProjets}
      />
    );
  }

  private getSharePointMembers(projectId: number) {
    SharePointDataService.getProjectMembers(projectId)
      .then(response => {
        const data = response.data.value
          .filter((item: any) => {
            return !this.state.members.find(member => {
              return item.fields.nomMembre == member.nomMembre
            });
          });
      })
  }

  private getMembers = (id: string) => {
    // On récupère les propriétaires du groupe Teams
    let url = 'groups/' + id + '/owners?select=displayName,mail';
    const tabOwners: GraphUser[] = [];

    API.get(url)
      .then((owners) => {
        for (const owner of owners.data.value) {
          const user: GraphUser = {
            displayName: owner.displayName,
            mail: owner.mail,
            niveauAcces: 'Propriétaire',
          };

          tabOwners.push(user);
        }

        // On récupère les membres du groupe Teams
        url = 'groups/' + id + '/members?select=displayName,mail';

        API.get(url)
          .then((members) => {
            let tabMembers: GraphUser[] = [];

            for (const member of members.data.value) {
              const user: GraphUser = {
                displayName: member.displayName,
                mail: member.mail,
                niveauAcces: 'Membre',
              };

              tabMembers.push(user);
            }

            tabMembers = tabMembers.filter(
              (item) =>
                !tabOwners.find(
                  (owner) => owner.displayName === item.displayName
                )
            );

            this.setState({
              chefDeProjets: tabOwners,
              contributors: tabMembers,
            });
          })
          .catch((error) => {
            // TO DO : handle error
          });
      })
      .catch((error) => {
        // TO DO : handle error
      });
  };

  private refreshData = (): Promise<void> => {
    const members: MemberProject[] = [];
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_MEMBRES +
      '/items?';
    const expand =
      'expand=fields(select=nomMembre,numProfils,numProjetLookupId,Id)';
    const filter = `&filter=fields/numProjetLookupId eq ${this.props.project.idProjet}`;

    return API.get(url + expand + filter)
      .then((response) => {
        for (const value of response.data.value) {
          const member: MemberProject = new MemberProject();

          member.id = value.fields.id;
          member.nomMembre = value.fields.nomMembre;
          member.numProfils = value.fields.numProfils;
          member.numProjetLookupId = value.fields.numProjetLookupId;

          members.push(member);
        }

        this.setState({ members });
      })
      .catch((error) => {
        // TO DO : handle error
      });
  };
}

export default Members;
