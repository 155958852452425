import { IClient } from '../interfaces/client';

export class Client {
  public static compare(a: IClient, b: IClient): number {
    if (a.nomClient.toLocaleLowerCase() < b.nomClient.toLocaleLowerCase()) {
      return -1;
    }

    if (a.nomClient.toLocaleLowerCase() > b.nomClient.toLocaleLowerCase()) {
      return 1;
    }

    return 0;
  }
}
