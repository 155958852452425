import React, { Component } from 'react';

import { DropdownList } from 'react-widgets';

import {
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Select,
  Typography,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';

import API from '../../../services/Api';


import Profil from '../../../interfaces/profil';
import ProfilTJM from '../../../interfaces/profil-tjm';
import { IProject } from '../../../interfaces/project';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
        padding: theme.spacing(3),
      },
    },
    formControl: {
      margin: theme.spacing(),
      minWidth: 240,
    },
  });

interface ICreateProfilProps extends WithStyles<typeof styles> {
  projet: IProject;
  profilCreated: () => void;
  profils: Profil[];
  profilsTJM: ProfilTJM[];
}

interface ICreateProfilState {
  tjm: string;
  profil: Profil;
}

class CreateProfil extends Component<ICreateProfilProps, ICreateProfilState> {
  constructor(props: Readonly<ICreateProfilProps>) {
    super(props);

    this.state = { profil: { id: 0, nomProfil: '', numProfil: 0 }, tjm: '0' };
  }

  public render() {
    const { classes } = this.props;

    const profilsJsx = this.props.profils.map(profil => {
      return <MenuItem value={profil.numProfil}>{profil.nomProfil}</MenuItem>
    })

    return (
      <Paper className={classes.paper}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item md={12}>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
              Ajouter un profil
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel id="profileLabelId">Intitulé du profil :</InputLabel>
              <Select
                label="Intitulé du profil"
                labelId="profileLabelId"
                onChange={this.onChangeProfil}
              >
                {profilsJsx}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={5} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="tjm-name"
                label="Tarif Journalier Moyen (€)"
                onChange={this.onChangeTJM}
                variant="outlined"
                fullWidth
                required
                size="medium"
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSave}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  private onChangeProfil = (event: any) => {
    const value = event.target!.value;
    const profilTjm = this.props.profilsTJM.find(
      (item) => {
        return item.numProfil === parseInt(value, 10)
      }
    );

    const profil: Profil = this.props.profils.find(
      (item) => item.numProfil === parseInt(value, 10)
    )!;

    if (profilTjm) {
      this.setState({
        profil,
        tjm: profilTjm.prix + '',
      });
    } else {
      this.setState({
        profil,
        tjm: '0',
      });
    }

  }

  private onChangeTJM = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(+event.target.value)) {
      this.setState({ tjm: event.target.value });
    }
  };

  private handleSave = () => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_TJM +
      '/items';

    if (this.state.profil.id === 0) {
      return;
    }

    const data = {
      numProfilLookupId: this.state.profil.id,
      numProjetLookupId: this.props.projet.idProjet,
      prix: this.state.tjm,
    };

    const profilTJM = this.props.profilsTJM.find(
      (item) => item.numProfil === this.state.profil.numProfil
    );

    if (!profilTJM) {
      API.post(url, { fields: data })
        .then(() => this.addProfilToProject(this.state.profil))
        .catch((error) => {
          // TO DO handle error
        });
    } else {
      const urlChange =
        'sites/' +
        process.env.REACT_APP_SITEID +
        '/lists/' +
        process.env.REACT_APP_TJM +
        '/items/' +
        profilTJM.id;
      const dataPatch = {
        prix: this.state.tjm,
      };

      API.patch(urlChange, { fields: dataPatch })
        .then(() => this.props.profilCreated())
        .catch((error) => {
          // TO DO handle error
        });
    }
  };

  private addProfilToProject(profil: Profil) {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PROJETS +
      '/items/' +
      this.props.projet.idProjet;
    const profilsProject = this.props.projet.profils.map(
      (item) => item.LookupId
    );

    profilsProject.push(profil.id);

    const data = {
      numProfilsLookupId: profilsProject,
      'numProfilsLookupId@odata.type': 'Collection(Edm.Int32)',
    };

    API.patch(url, { fields: data })
      .then(() => this.props.profilCreated())
      .catch((error) => {
        // TO DO handle error
      });
  }
}

export default withStyles(styles)(CreateProfil);
