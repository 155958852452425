import React, { Component } from 'react';

import {
  IconButton,
  InputBase,
  Paper,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import API from '../../services/Api';

import TableCellCheckbox from '../Table/TableCellCheckbox';
import TableDisplay from '../Table/TableDisplay';

import { IProject } from '../../interfaces/project';

import MySnackbar from '../MySnackbar';

const styles = (theme: Theme) =>
  createStyles({
    search: {
      flexGrow: 1,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    input: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  });

interface IProps extends WithStyles<typeof styles> {
  projects: IProject[];
  projectModified: () => void;
}

interface IState {
  filter: string;
  messageSnackBar: string;
  openSnackBar: boolean;
  projects: IProject[];
  typeSnackBar: 'success' | 'warning' | 'error' | 'info';
}

class ArchiveProject extends Component<IProps, IState> {
  private columns: any[];

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      filter: '',
      messageSnackBar: '',
      openSnackBar: false,
      projects: this.props.projects,
      typeSnackBar: 'error',
    };

    this.columns = ['Projet', 'Activer/Désactiver'];
    this.columns = this.columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public render() {
    const { classes } = this.props;
    const rows: any[] = [];
    const projects: IProject[] =
      this.state.filter === '' && this.state.projects.length === 0
        ? this.props.projects
        : this.state.projects;
    let i = 0;

    for (const project of projects) {
      const id = project.idProjet;
      const nomProjet = project.nomProjet;
      const enabled = project.enabled;

      rows.push(
        <TableRow key={i}>
          <TableCell component="th" scope="row" align="center">
            {nomProjet}
          </TableCell>
          <TableCellCheckbox
            onChangeClick={this.archiveProject}
            value={{ enabled, id }}
            enabled={enabled}
            key={i}
          />
        </TableRow>
      );

      i++;
    }

    return (
      <React.Fragment>
        <div className={classes.layout}>
          <Typography component="h1" variant="h4" align="center">
            Activer ou désactiver un projet
          </Typography>
          <Paper className={classes.search} elevation={1}>
            <InputBase
              className={classes.input}
              placeholder="Rechercher..."
              onChange={this.onChangeFilter}
              fullWidth
            />
            <IconButton className={classes.iconButton} aria-label="Search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <TableDisplay columns={this.columns} rows={rows} />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={this.onCloseSnackBar}
        >
          <MySnackbar
            variant={this.state.typeSnackBar}
            message={this.state.messageSnackBar}
            onClose={this.onClickSnackBar}
          />
        </Snackbar>
      </React.Fragment>
    );
  }

  private archiveProject = (value: { enabled: boolean; id: number }) => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PROJETS +
      '/items/' +
      value.id;
    let errorMessage: string;

    API.patch(url, { fields: { enabled: !value.enabled } })
      .then(() => this.activatedProject(value.enabled))
      .catch((error) => {
        errorMessage = !value.enabled
          ? "L'activation du projet a échoué. Veuillez recommencer."
          : 'La désactivation du projet a échoué. Veuillez recommencer.';
        this.openSnackBar(errorMessage, 'error');
      });
  };

  private activatedProject = (enabled: boolean) => {
    this.props.projectModified();
    !enabled
      ? this.openSnackBar('Projet activé.', 'success')
      : this.openSnackBar('Projet désactivé.', 'success');
  };

  private refreshTable = (filter: string) => {
    let projects: IProject[] = this.props.projects;

    if (filter !== '') {
      projects = projects.filter((item) =>
        item.nomProjet.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    }

    this.setState({ projects });
  };

  private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.refreshTable(event.currentTarget.value);
    this.setState({ filter: event.currentTarget.value });
  };

  private openSnackBar(
    messageSnackBar: string,
    typeSnackBar: 'success' | 'warning' | 'error' | 'info'
  ) {
    this.setState({ openSnackBar: true, typeSnackBar, messageSnackBar });
  }

  private onCloseSnackBar = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    reason: string
  ) => {
    this.setState({ openSnackBar: false });
  };

  private onClickSnackBar = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ openSnackBar: false });
  };
}

export default withStyles(styles)(ArchiveProject);
