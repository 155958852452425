import * as Msal from 'msal';

import {
  GRAPH_REQUESTS,
  msalApp,
  requiresInteraction,
} from '../services/auth-utils';

const useRedirectFlow = true;

class Auth {
  constructor() {
    msalApp.handleRedirectCallback(this.authCallback);
  }

  public getMsalApp(): Msal.UserAgentApplication {
    return msalApp;
  }

  public login(): void {
    if (useRedirectFlow) {
      return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
    }
  }

  public logout(): void {
    localStorage.clear();
    msalApp.logout();
  }

  public isConnected(): boolean {
    let isConnected = false;

    if (this.getAccount() == null) {
      return isConnected;
    }

    const idToken = this.getAccount().idToken;
    const identityProvider = this.getAccount().homeAccountIdentifier;

    const regexp = new RegExp('.*/([a-z0-9-]+)/');
    const results = regexp.exec(identityProvider);

    if (results) {
      const tenantId = results[1];
      isConnected = tenantId === process.env.REACT_APP_TENANTID;
    }

    const expires = +(idToken as { [key: string]: any }).exp;
    const d = new Date();
    const secondsSinceUnixEpoch = Math.round(d.getTime() / 1000);

    isConnected = secondsSinceUnixEpoch <= expires;

    return isConnected;
  }

  public getAccount(): Msal.Account {
    return msalApp.getAccount();
  }

  public async acquireToken() {
    return msalApp
      .acquireTokenSilent(GRAPH_REQUESTS.LOGIN)
      .catch((error: any) => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
          msalApp.acquireTokenRedirect(GRAPH_REQUESTS.LOGIN);
        }
      });
  }

  public authCallback(error: any): void {
    if (error) {
      
    }
  }
}

export default Auth;
