import axios from "axios";

import { AuthResponse } from "msal";

import jwt from "jsonwebtoken";

import Auth from "../services/Auth";
import Log from "./Log";

const authService = new Auth();

const headers = {
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};

const instance = axios.create({
  baseURL: `https://graph.microsoft.com/beta/`,
  headers,
});

instance.interceptors.request.use(
  async (config) => {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/`;
    if (authService.isConnected()) {
      const decoded = jwt.decode(
        localStorage.getItem("accessToken") as string
      ) as { [key: string]: any };

      if (decoded != null) {
        const expires = decoded.exp;
        const d = new Date();
        const secondsSinceUnixEpoch = Math.round(d.getTime() / 1000);

        if (secondsSinceUnixEpoch >= expires) {
          return authService
            .acquireToken()
            .then((tokenResponse) => {
              const accessToken = (tokenResponse as AuthResponse).accessToken;
              if (accessToken !== "") {
                localStorage.setItem("accessToken", accessToken);
                return config;
              } else {
                window.location.replace(redirectUrl);
                return config;
              }
            })
            .catch((error) => {
              Log.error(error, "acquireToken");
              return config;
            });
        } else {
          return config;
        }
      } else {
        return authService
          .acquireToken()
          .then((tokenResponse) => {
            const accessToken = (tokenResponse as AuthResponse).accessToken;

            Log.trace("acquired new token" + accessToken, "request axios");

            if (accessToken !== "") {
              localStorage.setItem("accessToken", accessToken);
              return config;
            } else {
              window.location.replace(redirectUrl);
              return config;
            }
          })
          .catch((error) => {
            Log.error(error, "acquireToken");
            return config;
          });
      }
    } else {
      Log.trace("User is not connected", "axios.interceptors.request");
      window.location.replace(redirectUrl);

      return config;
    }
  },
  (error) => {
    // Do something with response error

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const redirectUrl = `${window.location.protocol}//${window.location.host}/`;
        window.location.replace(redirectUrl);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
