import React, { Component } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Loading from '../../Loader';

import Lookup from '../../../interfaces/lookup';
import Phase from '../../../interfaces/phase';
import { IProject } from '../../../interfaces/project';

import API from '../../../services/Api';

import TableCellCheckbox from '../../Table/TableCellCheckbox';
import TableCellCheckboxTitle from '../../Table/TableCellCheckboxTitle';
import TableDisplay from '../../Table/TableDisplay';

interface ILiaisonPhaseProps {
  projet: IProject;
  phases: Phase[];
  linkChanged: () => Promise<void>;
  refreshInfo: () => void;
}

interface ILiaisonPhaseState {
  loading: boolean;
}

class LiaisonPhase extends Component<ILiaisonPhaseProps, ILiaisonPhaseState> {
  private rows: any[];
  private columns: any[];

  constructor(props: Readonly<ILiaisonPhaseProps>) {
    super(props);

    this.state = {
      loading: false,
    };
    this.columns = [];
    this.rows = [];
  }

  public render() {
    let i = 0;
    this.columns = [
      <TableCell component="th" scope="row" key={0} align="center">
        {'Phases/Profils'}
      </TableCell>,
    ];
    this.rows = [];
    let contentCheckbox: any[] = [];

    if (this.state.loading) {
      return <Loading />;
    }

    for (const profil of this.props.projet.profils) {
      const phasesNotLinked: Phase[] = [];
      const phasesLinked: Phase[] = [];
      const enabled: boolean = this.getInformationPhaseProfil(
        phasesNotLinked,
        phasesLinked,
        profil
      );
      this.columns.push(
        <TableCellCheckboxTitle
          onChangeClick={this.linkAll}
          value={{ phasesNotLinked, phasesLinked, profil, enabled }}
          enabled={enabled}
          title={profil.LookupValue}
        />
      );
    }

    for (const phase of this.props.phases) {
      if (phase.enabled) {
        contentCheckbox = [];

        for (const profil of this.props.projet.profils) {
          const enabled: boolean = this.hasPhase(phase, profil);
          contentCheckbox.push(
            <TableCellCheckbox
              onChangeClick={this.linkProfil}
              value={{ phase, profil, enabled }}
              enabled={enabled}
              key={i}
            />
          );
          i++;
        }

        this.rows.push(
          <TableRow key={i}>
            <TableCell component="th" scope="row" align="center">
              {phase.nomPhase}
            </TableCell>
            {contentCheckbox}
          </TableRow>
        );
        i++;
      }
    }

    return <TableDisplay columns={this.columns} rows={this.rows} />;
  }

  private refreshData = () => {
    this.props.linkChanged().then(() => {
      this.props.refreshInfo();
      this.setState({ loading: false });
    });
  };

  private linkAll = (value: {
    phasesNotLinked: Phase[];
    phasesLinked: Phase[];
    profil: Lookup;
    enabled: boolean;
  }) => {
    if (!value.enabled) {
      for (const phase of value.phasesNotLinked) {
        this.updateProfil(phase, value.profil);
      }
    } else {
      for (const phase of value.phasesLinked) {
        this.deleteProfil(phase, value.profil);
      }
    }
  };

  private getInformationPhaseProfil = (
    phasesNotLinked: Phase[],
    phasesLinked: Phase[],
    profil: Lookup
  ): boolean => {
    const phasesValidated = this.props.phases.filter((item) => item.enabled);

    for (const phaseValidated of phasesValidated) {
      if (this.hasPhase(phaseValidated, profil)) {
        phasesLinked.push(phaseValidated);
      } else {
        phasesNotLinked.push(phaseValidated);
      }
    }

    return (
      phasesValidated.length === phasesLinked.length &&
      phasesValidated.length !== 0
    );
  };

  private linkProfil = (value: {
    phase: Phase;
    profil: Lookup;
    enabled: boolean;
  }) => {
    if (!value.enabled) {
      this.updateProfil(value.phase, value.profil);
    } else {
      this.deleteProfil(value.phase, value.profil);
    }
  };

  private hasPhase(phase: Phase, profil: Lookup): boolean {
    let results: boolean = false;
    const phaseSelected = this.props.phases.find(
      (item) => item.nomPhase === phase.nomPhase
    );

    if (phaseSelected) {
      results = phaseSelected.numProfils.find(
        (item) => item.LookupId === profil.LookupId
      )
        ? true
        : false;
    }
    return results;
  }

  private updateProfil = (phase: Phase, profil: Lookup) => {
    const phaseSelected = this.props.phases.find(
      (item) => item.nomPhase === phase.nomPhase
    );

    if (phaseSelected) {
      const idProfils: number[] = phaseSelected.numProfils.map(
        (item) => item.LookupId
      );

      idProfils.push(profil.LookupId);
      this.patchProfil(idProfils, phase.id);
    }
  };

  private deleteProfil = (phase: Phase, profil: Lookup) => {
    const phaseSelected = this.props.phases.find(
      (item) => item.nomPhase === phase.nomPhase
    );

    if (phaseSelected) {
      let idProfils: number[] = phaseSelected.numProfils.map(
        (item) => item.LookupId
      );

      idProfils = idProfils.filter((item) => item !== profil.LookupId);
      this.patchProfil(idProfils, phase.id);
    }
  };

  private patchProfil = (idProfils: number[], id: number) => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_PHASES +
      '/items/' +
      id;
    const data = {
      numProfilsLookupId: idProfils,
      'numProfilsLookupId@odata.type': 'Collection(Edm.Int32)',
    };

    this.setState({ loading: true });
    API.patch(url, { fields: data })
      .then(() => this.refreshData())
      .catch((error) => {
        // TO DO handle error
      });
  };
}

export default LiaisonPhase;
