import React, { Component } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TableDisplay from '../../Table/TableDisplay';

import ProfilTJM from '../../../interfaces/profil-tjm';

interface IDisplayProfilProps {
  profilsTJM: ProfilTJM[];
}

class DisplayProfil extends Component<IDisplayProfilProps> {
  private columns: any[];

  constructor(props: Readonly<IDisplayProfilProps>) {
    super(props);

    this.columns = ['Profil', 'TJM (€)'];
    this.columns = this.columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public render() {
    const rows: any[] = [];
    let i = 0;

    for (const profil of this.props.profilsTJM) {
      rows.push(
        <TableRow key={i}>
          <TableCell component="th" scope="row" align="center">
            {profil.nomProfil}
          </TableCell>
          <TableCell align="center">{profil.prix} €</TableCell>
        </TableRow>
      );
      i++;
    }

    return <TableDisplay columns={this.columns} rows={rows} />;
  }
}

export default DisplayProfil;
