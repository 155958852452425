import React, { Component } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import API from '../../../services/Api';

import TableCellCheckbox from '../../Table/TableCellCheckbox';
import TableCellCheckboxTitle from '../../Table/TableCellCheckboxTitle';
import TableDisplay from '../../Table/TableDisplay';

import Loading from '../../Loader';

import MemberProject from '../../../classes/member-project';

import GraphUser from '../../../interfaces/graph-user';
import Lookup from '../../../interfaces/lookup';
import { IProject } from '../../../interfaces/project';

interface ITableMembersProps {
    project: IProject;
    contributors: GraphUser[];
    chefDeProjets: GraphUser[];
    membersChanged: () => Promise<void>;
    members: MemberProject[];
}

interface ITableMembersState {
    membersAdded: any[];
    idMembersAdded: number[];
    loading: boolean;
}

class TableMembers extends Component<ITableMembersProps, ITableMembersState> {
    private rows: any[] = [];
    private columns: any[] = [''];

    constructor(props: Readonly<ITableMembersProps>) {
        super(props);

        this.state = {
            idMembersAdded: [],
            loading: false,
            membersAdded: [],
        };
    }

    public render() {
        let i = 0;
        this.rows = [];
        this.columns = [
            <TableCell component="th" scope="row" key={0} align="center">
                {'Membre / Profil'}
            </TableCell>,
        ];
        let contentCheckbox: any[] = [];

        if (this.state.loading) {
            return <Loading />;
        }

        for (const profil of this.props.project.profils) {
            const membersHasProfil: GraphUser[] = [];
            const memberHasNotProfil: GraphUser[] = [];
            const enabled: boolean = this.getInformationMembreProfil(
                memberHasNotProfil,
                membersHasProfil,
                profil
            );
            this.columns.push(
                <TableCellCheckboxTitle
                    onChangeClick={this.addOrDeleteAllProfil}
                    value={{
                        memberHasNotProfil,
                        membersHasProfil,
                        profil,
                        enabled,
                    }}
                    enabled={enabled}
                    title={profil.LookupValue}
                />
            );
        }

        for (const cdp of this.props.chefDeProjets) {
            contentCheckbox = [];

            for (const profil of this.props.project.profils) {
                const enabled: boolean = this.hasProfil(cdp, profil);
                contentCheckbox.push(
                    <TableCellCheckbox
                        onChangeClick={this.addOrDeleteProfil}
                        value={{ user: cdp, profil, enabled }}
                        enabled={enabled}
                        key={i}
                    />
                );
                i++;
            }

            this.rows.push(
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align="center">
                        {cdp.displayName}
                    </TableCell>
                    {contentCheckbox}
                </TableRow>
            );
            i++;
        }

        for (const contributor of this.props.contributors) {
            contentCheckbox = [];

            for (const profil of this.props.project.profils) {
                const enabled: boolean = this.hasProfil(contributor, profil);
                contentCheckbox.push(
                    <TableCellCheckbox
                        onChangeClick={this.addOrDeleteProfil}
                        value={{ user: contributor, profil, enabled }}
                        enabled={enabled}
                        key={i}
                    />
                );
                i++;
            }

            this.rows.push(
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align="center">
                        {contributor.displayName}
                    </TableCell>
                    {contentCheckbox}
                </TableRow>
            );
            i++;
        }

        const filteredMembers : MemberProject[] = this.props.members.filter(member => {
            return !this.props.contributors.find(cont => member.nomMembre == cont.displayName);
        }).filter(member => {
            return !this.props.chefDeProjets.find(cdp => member.nomMembre == cdp.displayName);
        })
        for (const member of filteredMembers) {
            contentCheckbox = [];

            for (const profil of this.props.project.profils) {
                const enabled: boolean = !!member.numProfils.find(profil => {
                    return profil.LookupId === profil.LookupId;
                });
                const graphUser : GraphUser = {
                    displayName: member.nomMembre,
                    mail: "",
                    niveauAcces: ""
                };
                contentCheckbox.push(
                    <TableCellCheckbox
                        onChangeClick={this.addOrDeleteProfil}
                        value={{ user: graphUser, profil, enabled }}
                        enabled={enabled}
                        key={i}
                    />
                );
                i++;
            }

            this.rows.push(
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align="center">
                        {member.nomMembre}
                    </TableCell>
                    {contentCheckbox}
                </TableRow>
            );
            i++;
        }

        return <TableDisplay columns={this.columns} rows={this.rows} />;
    }

    private addOrDeleteAllProfil = (value: {
        memberHasNotProfil: GraphUser[];
        membersHasProfil: GraphUser[];
        profil: Lookup;
        enabled: boolean;
    }) => {
        if (!value.enabled) {
            for (const member of value.memberHasNotProfil) {
                const valueAdd = {
                    user: member,
                    profil: value.profil,
                    enabled: value.enabled,
                };
                this.addOrDeleteProfil(valueAdd);
            }
        } else {
            for (const member of value.membersHasProfil) {
                const valueAdd = {
                    user: member,
                    profil: value.profil,
                    enabled: value.enabled,
                };
                this.addOrDeleteProfil(valueAdd);
            }
        }
    };

    private getInformationMembreProfil = (
        memberHasNotProfil: GraphUser[],
        membersHasProfil: GraphUser[],
        profil: Lookup
    ): boolean => {
        const members = this.props.chefDeProjets.concat(
            this.props.contributors
        );

        for (const member of members) {
            if (this.hasProfil(member, profil)) {
                membersHasProfil.push(member);
            } else {
                memberHasNotProfil.push(member);
            }
        }

        return (
            members.length === membersHasProfil.length && members.length !== 0
        );
    };

    private hasProfil(user: GraphUser, profil: Lookup): boolean {
        const member = this.props.members.find(
            (item) => item.nomMembre === user.displayName
        );

        if (!member) {
            return false;
        }

        return member.hasProfil(profil);
    }

    private refreshData = () => {
        this.props.membersChanged().then(() => {
            this.setState({ loading: false });
        });
    };

    private addOrDeleteProfil = (value: {
        user: GraphUser;
        profil: Lookup;
        enabled: boolean;
    }) => {
        const url =
            'sites/' +
            process.env.REACT_APP_SITEID +
            '/lists/' +
            process.env.REACT_APP_MEMBRES +
            '/items';
        const member = this.props.members.find(
            (item) => item.nomMembre === value.user.displayName
        );

        if (!value.enabled) {
            if (!member) {
                const data = {
                    niveauAccess: value.user.niveauAcces,
                    nomMembre: value.user.displayName,
                    numProfilsLookupId: [value.profil.LookupId],
                    'numProfilsLookupId@odata.type': 'Collection(Edm.Int32)',
                    numProjetLookupId: this.props.project.idProjet,
                };

                this.setState({ loading: true });
                API.post(url, { fields: data })
                    .then(() => this.refreshData())
                    .catch((error) => {
                        // TO DO handle error
                    });
            } else {
                this.updateProfil(member, value.profil);
            }
        } else {
            if (member) {
                this.deleteProfil(member, value.profil);
            }
        }
    };

    private updateProfil = (member: MemberProject, profil: Lookup) => {
        const profils: number[] = member.numProfils.map(
            (item) => item.LookupId
        );

        profils.push(profil.LookupId);
        this.patchProfil(profils, member.id);
    };

    private deleteProfil = (member: MemberProject, profil: Lookup) => {
        const url =
            'sites/' +
            process.env.REACT_APP_SITEID +
            '/lists/' +
            process.env.REACT_APP_MEMBRES +
            '/items/' +
            member.id;
        let profils: number[] = member.numProfils.map((item) => item.LookupId);

        profils = profils.filter((item) => item !== profil.LookupId);

        if (profils.length !== 0) {
            this.patchProfil(profils, member.id);
        } else {
            this.setState({ loading: true });
            API.delete(url)
                .then(() => this.refreshData())
                .catch((error) => {
                    // TO DO handle error
                });
        }
    };

    private patchProfil = (profils: number[], MemberId: number) => {
        const url =
            'sites/' +
            process.env.REACT_APP_SITEID +
            '/lists/' +
            process.env.REACT_APP_MEMBRES +
            '/items/' +
            MemberId;
        const data = {
            numProfilsLookupId: profils,
            'numProfilsLookupId@odata.type': 'Collection(Edm.Int32)',
        };

        this.setState({ loading: true });
        API.patch(url, { fields: data })
            .then(() => this.refreshData())
            .catch((error) => {
                // TO DO handle error
            });
    };
}

export default TableMembers;
