import React, { Component } from "react";

import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import Edit from "@material-ui/icons/Edit";

interface TableCellEditProps {
    onEditClick: any;
    value: any;
}

class TableCellEdit extends Component<TableCellEditProps> {
    public render() {
        return (
            <TableCell align="center">
                <IconButton onClick={this.handleClick}>
                    <Edit />
                </IconButton>
            </TableCell>
        );
    }

    private handleClick = () => {
        this.props.onEditClick(this.props.value);
    }

}

export default TableCellEdit;
