import React, { Component } from "react";

import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import Delete from "@material-ui/icons/Delete";

interface TableCellDeleteProps {
    onDeleteClick: any;
    value: any;
}

class TableCellDelete extends Component<TableCellDeleteProps> {
    public render() {
        return (
            <TableCell align="center">
                <IconButton onClick={this.handleClick} aria-label="Delete">
                    <Delete color="error" />
                </IconButton>
            </TableCell>
        );
    }

    private handleClick = () => {
        this.props.onDeleteClick(this.props.value);
    }

}

export default TableCellDelete;
