import * as Msal from 'msal'
import { UserAgentApplication } from 'msal'

export const requiresInteraction = (errorMessage: {
    length: any
    indexOf: {
        (arg0: string): number
        (arg0: string): number
        (arg0: string): number
    }
}) => {
    if (!errorMessage || !errorMessage.length) {
        return false
    }

    return (
        errorMessage.indexOf('consent_required') > -1 ||
        errorMessage.indexOf('interaction_required') > -1 ||
        errorMessage.indexOf('login_required') > -1
    )
}

export const fetchMsGraph = async (url: RequestInfo, accessToken: string) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })

    return response.json()
}

export const isIE = () => {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ') > -1
    const msie11 = ua.indexOf('Trident/') > -1

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    const isEdge = ua.indexOf('Edge/') > -1

    return msie || msie11
}

export const GRAPH_SCOPES = {
    USER_READ: 'User.Read',
    SITES_READ: 'Sites.Read.All',
    SITES_WRITE: 'Sites.ReadWrite.All',
    DIRECTORY_READ: 'Directory.Read.All',
    GROUP_READ: 'Group.Read.All',
}

export const GRAPH_ENDPOINTS = {
    ME: 'https://graph.microsoft.com/v1.0/me',
    MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
}

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.USER_READ,
            GRAPH_SCOPES.SITES_READ,
            GRAPH_SCOPES.SITES_WRITE,
            GRAPH_SCOPES.DIRECTORY_READ,
            GRAPH_SCOPES.GROUP_READ,
        ],
    },
}

const tenantConfig = {
    clientId: process.env.REACT_APP_CLIENTID || '',
    scopes: [
        'user.read',
        'sites.read.all',
        'sites.readwrite.all',
        'directory.read.all',
        'group.read.all',
    ],
    tenantId: process.env.REACT_APP_TENANTID,
}

const tenantId = process.env.REACT_APP_TENANTID
const authority = 'https://login.microsoftonline.com/' + tenantId
const redirectUrl = `${window.location.protocol}//${window.location.host}/`

export const msalApp: Msal.UserAgentApplication = new UserAgentApplication({
    auth: {
        clientId: tenantConfig.clientId,
        authority,
        validateAuthority: true,
        postLogoutRedirectUri: redirectUrl,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE(),
    },
    system: {
        navigateFrameWait: 0,
    },
})
