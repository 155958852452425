import React, { Component } from 'react';

import utf8 from 'utf8';

import { DropdownList } from 'react-widgets';


import API from '../../../services/Api';
import Auth from '../../../services/Auth';
import { SharePointDataService } from '../../../services/SharePointDataService';

import ActiviteConso from '../../../interfaces/activite-conso';
import { IProject } from '../../../interfaces/project';
import ConsumptionDisplay from './ConsumptionDisplay';
import { Badge, Chip, createStyles, FormControl, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(),
    },
    paper: {
      ...theme.mixins.gutters(),
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    colorBlock: {
      width: 50,
      height: 20,
      margin: 5,
      marginRight: 50,
      border: '1px solid rgba(0, 0, 0, .2)',
      display: 'inline-block',
    },
    colorBlockTitle: {
      display: 'inline-block',
      width: 90,
    },
    colorBlockText: {
      margin: 0,
      lineHeight: '18px',
      paddingLeft: 55,
    },
    marginBadge: {
      margin: theme.spacing(),
    },
  });

interface IProps extends WithStyles<typeof styles> {}

interface IState {
  activities: ActiviteConso[];
  projects: IProject[];
  selectedProject: IProject | null;
  selectedProjectList: string;
  chargeActivitiesImputable: number;
  chargeActivitiesFacturable: number;
  chargeActivitiesTotal: number;
}

class ProjectConsumption extends Component<IProps, IState> {
  private auth: Auth = new Auth();

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      projects: [],
      activities: [],
      selectedProject: null,
      selectedProjectList: '',
      chargeActivitiesFacturable: 0,
      chargeActivitiesImputable: 0,
      chargeActivitiesTotal: 0,
    };
  }

  public componentDidMount() {
    this.getProjects();
  }

  public render() {
    const { classes } = this.props;
    // TODO : Utiliser ConsumptionForm pour le formulaire
    return (
      <div style={{ padding: 12 }}>
        <ConsumptionDisplay
          activities={this.state.activities}
          project={this.state.selectedProject}
        />
      </div>
    );
  }

  private async getProjects(): Promise<void> {
    const user = this.auth.getAccount().name;
    const filter = `&filter=fields/nomMembre eq '${user}'`;
    const projects: IProject[] = await SharePointDataService.getProjects(
      filter
    );

    this.setState({ projects });
  }

  private async getActivities(idList: string): Promise<void> {
    const activities: ActiviteConso[] = [];
    const url =
      'sites/' + process.env.REACT_APP_SITEID + '/lists/' + idList + '/items?';
    const expand =
      'expand=fields(select=Title,PNum,NumClient,NomClient,TypeProjet,Phase,Profil,Jour,Temps,Meridiem,Activite_x003a_Titre,Technologie)';
    let chargeFacturable: number = 0;
    let chargeImputable: number = 0;
    let chargeTotale: number = 0;

    try {
      const response = await API.get(url + expand);

      for (const activity of response.data.value) {
        const fields = activity.fields;
        const dates: string[] = fields.Jour.split('-');
        const fooActivity: ActiviteConso = {
          nomProjet: fields.Title,
          numPropale: fields.PNum,
          numClient: fields.NumClient,
          nomClient: fields.NomClient,
          typeProjet: fields.TypeProjet,
          nomPhase: fields.Phase,
          nomProfil: fields.Profil,
          date: dates[2] + '/' + dates[1] + '/' + dates[0],
          charge: +fields.Temps,
          meridiem: fields.Meridiem,
          service: fields.Activite_x003a_Titre,
          technologie: fields.Technologie,
        };

        chargeTotale += fields.Temps;

        if (fields.TypeProjet === 'Facturable') {
          chargeFacturable += fields.Temps;
        } else {
          chargeImputable += fields.Temps;
        }
        if (activity.fields.numProjetLookupId === fields.id) {
          activities.push(fooActivity);
        }
      }

      activities.sort((a: ActiviteConso, b: ActiviteConso) =>
        a.nomClient > b.nomClient ? 1 : -1
      );

      this.setState({
        activities,
        chargeActivitiesFacturable: chargeFacturable,
        chargeActivitiesImputable: chargeImputable,
        chargeActivitiesTotal: chargeTotale,
      });
    } catch (error) {
      // TO DO handle error
    }
  }

  private onChangeProjet = (project: IProject) => {
    this.setState({ selectedProject: project });
    this.getActivities(project.idList);
  };

  private groupByClient = (project: IProject) => {
    return project.nomClient;
  };
}

export default withStyles(styles)(ProjectConsumption);
