import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import jwt from "jsonwebtoken";

import ManageProfils from "./Components/administration/ManageProfils";
import ManageTechnologie from "./Components/administration/ManageTechnologie";
import Calendar from "./Components/Calendar/Calendar";
import Clients from "./Components/Clients/Clients";
import Connect from "./Components/Connect";
import ErrorBoundary from "./Components/ErrorBoundary";
import PageNotFound from "./Components/PageNotFound";
import ProjectConsumption from "./Components/Project/Consumption/ProjectConsumption";
import ProjectList from "./Components/Project/ProjectList";

import API from "./services/Api";
import Auth from "./services/Auth";
import PowerBiService from "./services/PowerBiService";
import Log from "./services/Log";

import utf8 from "utf8";

import "./App.css";
import { CssBaseline } from "@material-ui/core";
import Searchbar from "./Components/Searchbar";

interface IState {
  access: string;
  userPhoto: string;
  isProjectManager: boolean;
}

interface IAccessLevelToken {
  accessLevel: string;
  iat: number;
}

class App extends Component<{}, IState> {
  private auth: Auth = new Auth();
  private pbiService! : PowerBiService;

  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      access: "contributeur",
      userPhoto: "",
      isProjectManager: false,
    };
  }

  public componentDidMount() {
    if (this.auth.isConnected()) {
      this.getUserInfos();
      this.getAccessLevel();
      this.isCurrentUserProjectManager();
      this.pbiService = PowerBiService.GetInstance();
    }
  }

  public render() {
    if (!this.auth.isConnected()) {
      return <Connect />;
    } else {
      return (
        <ErrorBoundary>
          <CssBaseline />
          <Searchbar
            access={this.state.access}
            userPhoto={this.state.userPhoto}
            isProjectManager={this.state.isProjectManager}
          />
          <Switch>
            <Route exact={true} path="/" component={(props: any) => <Calendar {...props}></Calendar>} />
            <Route path="/Home" component={(props: any) => <Calendar {...props}></Calendar>} />
            <Route path="/Connect" component={ (props: any) => <Connect {...props}></Connect> } />
            <Route path="/mc" component={Clients} />
            <Route path="/mp" component={ProjectList} />
            <Route path="/ManageProfils" component={ (props: any) => <ManageProfils {...props}></ManageProfils> } />
            <Route path="/ManageTechno" component={ (props: any) => <ManageTechnologie {...props}></ManageTechnologie> } />
            <Route path="/ProjectConsumption" component={ (props: any) => <ProjectConsumption {...props}></ProjectConsumption> } />
            <Route component={PageNotFound} />
          </Switch>
        </ErrorBoundary>
      );
    }
  }

  /**
   * Récupère les groupes Azure AD de l'utilisateur connecté pour déterminer son niveau d'accès à l'application
   */
  private getUserInfos() {
    const url = "me";
    const urlPhoto = "me/photo/$value";

    API.get(url).then(
      (response: {
        data: { givenName: string; surname: string; companyName: string };
      }) => {
        localStorage.setItem("givenName", response.data.givenName);
        localStorage.setItem("surname", response.data.surname);
        localStorage.setItem("company", response.data.companyName);
      }
    );

    API.get(urlPhoto, { responseType: "blob" }).then(
      (response: { data: Blob }) => {
        const reader = new FileReader();

        reader.onload = (event: Event) => {
          const binaryString = reader.result;
          const base64textString = btoa(binaryString as string);
          this.setState({ userPhoto: base64textString });
        };

        reader.readAsBinaryString(response.data);
      }
    )
    .catch (error => {
      Log.error(error, "User photo");
    });
  }

  /**
   * Récupère les groupes Azure AD de l'utilisateur connecté pour déterminer son niveau d'accès à l'application
   */
  private getAccessLevel() {
    const url = "me/memberOf?select=displayName";

    API.get(url).then((response: { data: { value: string | any[] } }) => {
      let accessLevel = "contributeur";
      let cpt = 0;
      let stop = false;

      while (cpt < response.data.value.length && !stop) {
        const displayName = response.data.value[cpt].displayName;

        if (displayName === "GTA Superviseurs") {
          accessLevel = "superviseur";
          stop = true;
        } else if (
          displayName === "GTA Managers" &&
          (accessLevel === "contributeur" || accessLevel === "cdp")
        ) {
          accessLevel = "manager";
        } else if (
          displayName === "GTA Chefs de projets" &&
          accessLevel === "contributeur"
        ) {
          accessLevel = "cdp";
        }
        cpt++;
      }

      const accessToken = localStorage.getItem("accessToken") as string;
      const accessLevelToken = jwt.sign({ accessLevel }, accessToken);

      localStorage.setItem("accessLevel", accessLevelToken);

      const res: IAccessLevelToken = jwt.verify(
        localStorage.getItem("accessLevel") as string,
        accessToken
      ) as IAccessLevelToken;

      if (res.accessLevel !== "contributeur") {
        this.setState({ access: res.accessLevel });
      }
    });
  }

  /**
   * Requete permettant de vérifier si l'utilisateur connecté est un chef de projet
   */
  private async isCurrentUserProjectManager() {
    const user = this.auth.getAccount().name;
    const url =
      "sites/" +
      process.env.REACT_APP_SITEID +
      "/lists/" +
      process.env.REACT_APP_MEMBRES +
      "/items?";
    const expand =
      "expand=fields(select=nomMembre,numProjetLookupId,numProfils_x003a_nomProfil)";
    const filter = `&filter=fields/nomMembre eq '${user}' and fields/niveauAccess eq 'Propriétaire'`;

    try {
      const response = await API.get(url + expand + filter);
      if (response.data.value.length > 0) {
        this.setState({ isProjectManager: true });
      }
    } catch (error) {
      // TO DO handle error
    }
  }
}

export default App;
