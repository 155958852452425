import React, { Component } from "react";

import Loader from 'react-loader-spinner';

class Loading extends Component {
    public render() {
        return <div className="loading">
            <Loader type="ThreeDots" color="#B0E0E6" height="100" width="100" />
        </div>
    }
}

export default Loading;
