import React, { Component } from 'react';

import { Grid } from '@material-ui/core';

import { SharePointDataService } from '../../services/SharePointDataService';

import Loading from '../Loader';
import CreateClient from './CreateClient';
import DisplayClient from './DisplayClient';

import { IClient } from '../../interfaces/client';

interface IClientState {
  clients: IClient[];
  nbClients: number | null;
  refresh: boolean;
}

class Clients extends Component<{}, IClientState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      clients: [],
      nbClients: null,
      refresh: false,
    };
  }

  public componentDidMount() {
    this.refreshData();
  }

  public render() {
    if (this.state.nbClients === null) {
      return <Loading />;
    } else {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item md={12}>
            <CreateClient clientCreated={this.refreshData} />
          </Grid>
          <Grid item md={12}>
            <DisplayClient
              nbClients={this.state.nbClients}
              clients={this.state.clients}
              clientDeleted={this.refreshData}
            />
          </Grid>
        </Grid>
      );
    }
  }

  private refreshData = async () => {
    const clients: IClient[] = await SharePointDataService.getClients();
    this.setState({ clients, nbClients: clients.length });
  };
}

export default Clients;
