import React, { Component } from 'react';

import classNames from 'classnames';

import { IconButton, SnackbarContent } from '@material-ui/core';

import { amber, green } from '@material-ui/core/colors';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600]
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.primary.dark
    },
    warning: {
      backgroundColor: amber[700]
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing()
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  message: string;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  variant: 'success' | 'warning' | 'error' | 'info';
}

class MySnackbar extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { classes, message, onClose, variant } = this.props;
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    );
  }
}

export default withStyles(styles)(MySnackbar);
