import React, { Component } from "react";

import axios from 'axios';
import utf8 from 'utf8';

import { IconButton, InputBase, Paper, Snackbar, TableCell, TableRow, Typography } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import API from '../../services/Api';

import TableCellCheckbox from "../Table/TableCellCheckbox";
import TableDisplay from "../Table/TableDisplay";

import Teams from '../../interfaces/teams';

import MySnackbar from "../MySnackbar";

const styles = (theme: Theme) => createStyles({
    search: {
        flexGrow: 1,
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6)
    },
    input: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    }
});

interface Props extends WithStyles<typeof styles> {
    teams: Teams[];
    projectModified: () => void;
}

interface State {
    filter: string;
    messageSnackBar: string;
    openSnackBar: boolean;
    teams: Teams[];
    typeSnackBar: 'success' | 'warning' | 'error' | 'info';
}

class ProjectAcceptation extends Component<Props, State> {
    private columns: any[];

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            filter: "",
            messageSnackBar: "",
            openSnackBar: false,
            teams: this.props.teams,
            typeSnackBar: 'error'
        };

        this.columns = ["Teams", "Valider"];
        this.columns = this.columns.map(column => { return <TableCell component="th" scope="row" key={column} align="center">{column}</TableCell> });
    }

    public componentWillReceiveProps(newProps: Props) {
        const oldProps = this.props;
        if (oldProps.teams !== newProps.teams)
            this.setState({ teams: newProps.teams });
    }

    public render() {
        const { classes } = this.props;
        const rows: any[] = [];
        const teams: Teams[] = (this.state.filter === "" && this.state.teams.length === 0) ? this.props.teams : this.state.teams;
        let i = 0;

        for (const team of teams) {
            rows.push(
                <TableRow key={i}>
                    <TableCell component="th" scope="row" align="center">{team.displayName}</TableCell>
                    <TableCellCheckbox onChangeClick={this.enableTeams} value={team} enabled={false} key={i} />
                </TableRow>
            );

            i++;
        }

        return (
            <React.Fragment>
                <div className={classes.layout}>
                    <Typography component="h1" variant="h4" align="center">Valider une teams en tant que projet</Typography>
                    <Paper className={classes.search} elevation={1}>
                        <InputBase className={classes.input} placeholder="Rechercher..." onChange={this.onChangeFilter} fullWidth />
                        <IconButton className={classes.iconButton} aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <TableDisplay columns={this.columns} rows={rows} />
                </div>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} open={this.state.openSnackBar} onClose={this.onCloseSnackBar}>
                    <MySnackbar variant={this.state.typeSnackBar} message={this.state.messageSnackBar} onClose={this.onClickSnackBar} />
                </Snackbar>
            </React.Fragment>
        );
    }

    private enableTeams = (team: Teams) => {
        let url = "sites/" + process.env.REACT_APP_SITEID + "/lists/" + process.env.REACT_APP_PROJETS + "/items";
        const data = {
            IdTeams: team.id,
            Title: team.displayName,
            enabled: true
        };

        this.openSnackBar("Projet en cours de validation...", "info");
        API.post(url, { fields: data }).then(response => this.createActivitesList(response.data.fields.Title, response.data.fields.id, team))
            .catch(error => {
                this.openSnackBar("La validation du projet a échoué. Veuillez recommencer.", "error");
            });
    }

    private createActivitesList(project: string, projectId: string, team: Teams): void {
        let url = process.env.REACT_APP_AZ_CREATELIST_URL || "";
        const headers = {
            "Content-Type": "application/json",
            "x-functions-key": process.env.REACT_APP_AZ_CREATELIST_KEY
        };
        const data = {
            "managerGroup": process.env.REACT_APP_MANAGERSGROUP,
            "managers": team.owners.map(owner => owner.mail),
            "projectName": project,
            "supervisorGroup": process.env.REACT_APP_SUPERVISORSGROUP,
            "teamsMembers": team.mail,
        };
        const options = {
            data,
            headers,
            method: 'POST',
            url
        };

        axios(options).then(response => {
            url = "sites/" + process.env.REACT_APP_SITEID + "/lists/" + process.env.REACT_APP_PROJETS + "/items/" + projectId;

            API.patch(url, { fields: { idList: response.data } }).then(() => {
                this.openSnackBar("Projet validé", "success");
                this.props.projectModified();
            }).catch(error => {
                API.patch(url, { fields: { idList: response.data } }).then(() => {
                    this.openSnackBar("Projet validé", "success");
                    this.props.projectModified();
                }).catch(error => {
                    this.openSnackBar("La validation du projet a échoué. Veuillez recommencer.", "error");
                });
            });
        }).catch(error => {
            url = "sites/" + process.env.REACT_APP_SITEID + "/lists/" + process.env.REACT_APP_PROJETS + "/items/" + projectId;
            API.delete(url).then(() => {
                this.props.projectModified();
            });
            this.openSnackBar("La validation du projet a échoué. Veuillez recommencer.", "error");
        });
    }

    private refreshTable = (filter: string) => {
        let teams: Teams[] = this.props.teams;

        if (filter !== "") {
            teams = teams.filter(item => item.displayName.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
        }

        this.setState({ teams });
    }

    private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.refreshTable(event.currentTarget.value);
        this.setState({ filter: event.currentTarget.value });
    }

    private openSnackBar(messageSnackBar: string, typeSnackBar: 'success' | 'warning' | 'error' | 'info') {
        this.setState({ openSnackBar: true, typeSnackBar, messageSnackBar });
    }

    private onCloseSnackBar = (event: React.SyntheticEvent<HTMLElement, Event>, reason: string) => {
        this.setState({ openSnackBar: false });
    }

    private onClickSnackBar = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ openSnackBar: false });
    }
}

export default withStyles(styles)(ProjectAcceptation);
