import React, { Component } from 'react';

import {
  Button,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Snackbar,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import API from '../../services/Api';

import TableCellDelete from '../Table/TableCellDelete';
import TableCellEdit from '../Table/TableCellEdit';
import TableDisplay from '../Table/TableDisplay';

import Lookup from '../../interfaces/lookup';
import { IProject } from '../../interfaces/project';

import MySnackbar from '../MySnackbar';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    search: {
      flexGrow: 1,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    input: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    paper: {
      position: 'absolute',
      width: theme.spacing(50),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
    },
  });

interface IProps extends WithStyles<typeof styles> {
  projects: IProject[];
  projectModified: () => void;
  projectSelected: (project: IProject) => void;
}

interface IDisplayProjectState {
  filter: string;
  messageSnackBar: string;
  modalIsOpen: boolean;
  openSnackBar: boolean;
  projects: IProject[];
  projectSelected: IProject | null;
  typeSnackBar: 'success' | 'warning' | 'error' | 'info';
}

class DisplayProject extends Component<IProps, IDisplayProjectState> {
  private columns: any[];

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      filter: '',
      modalIsOpen: false,
      messageSnackBar: '',
      openSnackBar: false,
      projects: this.props.projects,
      projectSelected: null,
      typeSnackBar: 'success',
    };

    this.columns = [
      'Nom du projet',
      'Client',
      'N° de propales',
      'Gérer',
      'Supprimer',
    ];

    this.columns = this.columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public componentWillReceiveProps(newProps: IProps) {
    const oldProps = this.props;

    if (oldProps.projects !== newProps.projects) {
      this.setState({ projects: newProps.projects });
    }
  }

  public render() {
    const { classes } = this.props;
    const rows: any = [];
    const projets: IProject[] =
      this.state.filter === '' && this.state.projects.length === 0
        ? this.props.projects
        : this.state.projects;
    let i = 0;

    for (const projet of projets) {
      if (projet.enabled) {
        rows.push(
          <TableRow key={i}>
            <TableCell component="th" scope="row" align="center">
              {projet.nomProjet}
            </TableCell>
            <TableCell align="center">
              {projet.nomClient} / {projet.numClient}
            </TableCell>
            <TableCell align="center">
              {projet.numPropales
                .map((numPropale) => numPropale.LookupValue)
                .join(' / ')}
            </TableCell>
            <TableCellEdit value={projet} onEditClick={this.callEditProject} />
            <TableCellDelete
              value={projet}
              onDeleteClick={this.callDeleteProject}
            />
          </TableRow>
        );

        i++;
      }
    }

    return (
      <div className={classes.layout}>
        <Typography component="h1" variant="h4" align="center">
          Gestion des projets
        </Typography>
        <Paper className={classes.search} elevation={1}>
          <InputBase
            className={classes.input}
            placeholder="Rechercher..."
            onChange={this.onChangeFilter}
            fullWidth
          />
          <IconButton className={classes.iconButton} aria-label="Search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <TableDisplay columns={this.columns} rows={rows} />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
        >
          <div style={{ top: '40%', left: '40%' }} className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              {' '}
              Confirmer la suppresion du projet{' '}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {this.state.projectSelected
                ? this.state.projectSelected.nomProjet
                : ''}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.deleteProject}
              className={classes.button}
            >
              Supprimer
            </Button>
            <Button
              variant="contained"
              onClick={this.handleCloseModal}
              className={classes.button}
            >
              Annuler
            </Button>
          </div>
        </Modal>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.openSnackBar}
          onClose={this.onCloseSnackBar}
        >
          <MySnackbar
            variant={this.state.typeSnackBar}
            message={this.state.messageSnackBar}
            onClose={this.onClickSnackBar}
          />
        </Snackbar>
      </div>
    );
  }

  private refreshTable = (filter: string) => {
    let projects: IProject[] = this.props.projects;

    if (filter !== '') {
      projects = projects.filter(
        (item) =>
          item.nomProjet
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.nomClient
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.numProjet
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.numClient
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.numPropales
            .map((numPropale) => numPropale.LookupValue)
            .join(' ')
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase())
      );
    }

    this.setState({ projects });
  };

  private callDeleteProject = (project: IProject) => {
    this.handleOpenModal(project);
  };

  private deleteProject = () => {
    if (this.state.projectSelected) {
      this.handleCloseModal();
      this.openSnackBar('Suppresion en cours...', 'info');
      let errorDelete: boolean = false;

      let url: string = '';

      // Suppresion des propales
      const numPropales: Lookup[] = this.state.projectSelected.numPropales;
      const promisesPropales: Array<Promise<any>> = [];
      for (const numPropale of numPropales) {
        url =
          'sites/' +
          process.env.REACT_APP_SITEID +
          '/lists/' +
          process.env.REACT_APP_PROPALES +
          '/items/' +
          numPropale.LookupId;
        promisesPropales.push(API.delete(url));
      }

      // Suppression des phases du projet

      const phases: Lookup[] = this.state.projectSelected.phases;
      const promisesPhases: Array<Promise<any>> = [];
      for (const phase of phases) {
        url =
          'sites/' +
          process.env.REACT_APP_SITEID +
          '/lists/' +
          process.env.REACT_APP_PHASES +
          '/items/' +
          phase.LookupId;
        promisesPhases.push(API.delete(url));
      }

      // Suppression des membres du projet
      const promisesMembres: Array<Promise<any>> = [];
      url =
        'sites/' +
        process.env.REACT_APP_SITEID +
        '/lists/' +
        process.env.REACT_APP_MEMBRES +
        '/items?';
      let filter = `&filter=fields/numProjetLookupId eq ${this.state.projectSelected.idProjet}`;
      API.get(url + filter)
        .then((response) => {
          for (const member of response.data.value) {
            url =
              'sites/' +
              process.env.REACT_APP_SITEID +
              '/lists/' +
              process.env.REACT_APP_MEMBRES +
              '/items/' +
              member.id;
            promisesMembres.push(API.delete(url));
          }
        })
        .catch((error) => {
          errorDelete = true;
        });

      // Suppression des profilsTJM
      const promisesProfils: Array<Promise<any>> = [];
      url =
        'sites/' +
        process.env.REACT_APP_SITEID +
        '/lists/' +
        process.env.REACT_APP_TJM +
        '/items?';
      filter = `&filter=fields/numProjetLookupId eq ${this.state.projectSelected.idProjet}`;
      API.get(url + filter)
        .then((response) => {
          for (const profilTJM of response.data.value) {
            url =
              'sites/' +
              process.env.REACT_APP_SITEID +
              '/lists/' +
              process.env.REACT_APP_TJM +
              '/items/' +
              profilTJM.id;
            promisesProfils.push(API.delete(url));
          }
        })
        .catch((error) => {
          errorDelete = true;
        });

      // Suppression du projet
      const title = this.state.projectSelected.nomProjet;
      url =
        'sites/' +
        process.env.REACT_APP_SITEID +
        '/lists/' +
        process.env.REACT_APP_PROJETS +
        '/items/' +
        this.state.projectSelected.idProjet;

      const promises: Array<Array<Promise<any>>> = [];
      promises.push(promisesMembres);
      promises.push(promisesPhases);
      promises.push(promisesProfils);
      promises.push(promisesPropales);

      Promise.all(promises)
        .then(() => {
          API.delete(url)
            .then(() => {
              this.props.projectModified();
              if (errorDelete) {
                this.openSnackBar(
                  "Erreur lors de la suppresion des données liées au projet, veuillez contacter l'administrateur",
                  'error'
                );
              } else {
                this.openSnackBar(
                  'Le projet : ' + title + ' a bien été supprimé',
                  'success'
                );
              }
              // TO DO - fix delete list permission
              // url = "sites/" + process.env.REACT_APP_SITEID + "/lists/" + project.idList;
              // API.delete(url).then(() => this.props.projectModified()).catch(error => {
              //   // TO DO handle error
              // });
            })
            .catch((error) => {
              if (errorDelete) {
                this.openSnackBar(
                  "Erreur lors de la suppresion des données liées au projet, veuillez contacter l'administrateur",
                  'error'
                );
              } else {
                this.openSnackBar(
                  'Erreur lors de la suppression du projet : ' +
                    title +
                    '. Veuillez recommencer',
                  'error'
                );
              }
            });
        })
        .catch(() => {
          API.delete(url)
            .then(() => {
              this.props.projectModified();
              this.openSnackBar(
                "Erreur lors de la suppresion des données liées au projet, veuillez contacter l'administrateur",
                'error'
              );
              // TO DO - fix delete list permission
              // url = "sites/" + process.env.REACT_APP_SITEID + "/lists/" + project.idList;
              // API.delete(url).then(() => this.props.projectModified()).catch(error => {
              //   // TO DO handle error
              // });
            })
            .catch(() => {
              this.openSnackBar(
                "Erreur lors de la suppression des données liées au projet, veuillez contacter l'administrateur",
                'error'
              );
            });
        });
    }
  };

  private callEditProject = (project: IProject) => {
    this.props.projectSelected(project);
  };

  private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.refreshTable(event.currentTarget.value);
    this.setState({ filter: event.currentTarget.value });
  };

  private handleOpenModal = (projectSelected: IProject) => {
    this.setState({ modalIsOpen: true, projectSelected });
  };

  private handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };
  private openSnackBar(
    messageSnackBar: string,
    typeSnackBar: 'success' | 'warning' | 'error' | 'info'
  ) {
    this.setState({ openSnackBar: true, typeSnackBar, messageSnackBar });
  }

  private onCloseSnackBar = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    reason: string
  ) => {
    this.setState({ openSnackBar: false });
  };

  private onClickSnackBar = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ openSnackBar: false });
  };
}

export default withStyles(styles)(DisplayProject);
