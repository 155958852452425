import React, { Component } from 'react';

import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Snackbar,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import API from '../../services/Api';

import { ITechnologie } from '../../interfaces/technologie';

import TableCellDelete from '../Table/TableCellDelete';
import TableDisplay from '../Table/TableDisplay';

import MySnackbar from '../MySnackbar';

import Loading from '../Loader';

const styles = (theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(),
    },
    root: {
      flexGrow: 1,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    paper: {
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      marginTop: 15,
    },
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: 200,
    },
    input: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  });

interface IProps extends WithStyles<typeof styles> {}

interface IState {
  filter: boolean;
  loading: boolean;
  maxNumTechnologies: number;
  messageSnackBar: string;
  modal: boolean;
  nameTechnologie: string;
  nameTechnologieModal: string;
  openSnackBar: boolean;
  selectedTechnologies: null | ITechnologie;
  technologies: ITechnologie[];
  technologiesFilter: ITechnologie[];
  typeSnackBar: 'success' | 'warning' | 'error' | 'info';
}

class ManageTechnologie extends Component<IProps, IState> {
  public columns: any[];

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      filter: false,
      loading: true,
      maxNumTechnologies: 1,
      messageSnackBar: '',
      modal: false,
      nameTechnologie: '',
      nameTechnologieModal: '',
      openSnackBar: false,
      selectedTechnologies: null,
      technologies: [],
      technologiesFilter: [],
      typeSnackBar: 'error',
    };

    this.columns = ['Technologie', 'Supprimer'];
    this.columns = this.columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public componentDidMount() {
    this.refreshData();
  }

  public render() {
    const { classes } = this.props;
    const rows: any[] = [];
    let i: number = 0;
    const technologies = this.state.filter
      ? this.state.technologiesFilter.sort(this.compare)
      : this.state.technologies.sort(this.compare);

    for (const technologie of technologies) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const isNew = new Date(technologie.createdDateTime) >= yesterday;
      rows.push(
        <TableRow key={i}>
          <TableCell align="center">
            {technologie.nomTechnologie}{' '}
            {isNew ? (
              <Chip
                label="Nouveau"
                color="secondary"
                className={classes.chip}
              />
            ) : null}
          </TableCell>
          <TableCellDelete
            value={technologie}
            onDeleteClick={this.deleteTechnologie}
          />
        </TableRow>
      );

      i++;
    }

    return (
      <div style={{ padding: 12 }}>
        <Typography variant="h6" gutterBottom>
          Ajouter une nouvelle technologie
        </Typography>
        <Grid container direction="row" alignItems="center">
          <Grid item md="auto">
            <TextField
              id="technologie"
              label="Technologie"
              className={classes.textField}
              value={this.state.nameTechnologie}
              onChange={this.onChangeNameTechnologie}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item md="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={this.createTechnologie}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
        {this.state.loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Paper className={classes.root} elevation={1}>
              <InputBase
                className={classes.input}
                placeholder="Rechercher..."
                onChange={this.onChangeFilter}
              />
              <IconButton className={classes.iconButton} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <TableDisplay rows={rows} columns={this.columns} />
          </React.Fragment>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={this.onCloseSnackBar}
        >
          <MySnackbar
            variant={this.state.typeSnackBar}
            message={this.state.messageSnackBar}
            onClose={this.onClickSnackBar}
          />
        </Snackbar>
      </div>
    );
  }

  private compare(a: ITechnologie, b: ITechnologie) {
    if (
      a.nomTechnologie.toLocaleLowerCase() <
      b.nomTechnologie.toLocaleLowerCase()
    ) {
      return -1;
    }

    if (
      a.nomTechnologie.toLocaleLowerCase() >
      b.nomTechnologie.toLocaleLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  private refreshData = () => {
    this.getMaxNumTechnologie().then(() => this.getTechnologies());
  };

  private async getMaxNumTechnologie(): Promise<number> {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_TECHNOLOGIES +
      '/items?expand=fields(select=numTechnologie)';
    const orderby = '&$orderby=id asc';

    return API.get(url + orderby).then((response) => {
      const numTechnologie: number[] = response.data.value.map(
        (value: { fields: { numTechnologie: string | number } }) =>
          +value.fields.numTechnologie
      );
      const maxNumTechnologies =
        numTechnologie.length === 0 ? 1 : Math.max(...numTechnologie) + 1;

      this.setState({ maxNumTechnologies });

      return response.data.value;
    });
  }

  private getTechnologies() {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_TECHNOLOGIES +
      '/items?';
    const expand = 'expand=fields(select=id,numTechnologie,nomTechnologie)';

    API.get(url + expand)
      .then((response) => {
        const technologies: ITechnologie[] = [];

        for (const technologie of response.data.value) {
          const createdDateTime: Date = technologie.createdDateTime;
          technologies.push({
            createdDateTime,
            id: +technologie.fields.id,
            nomTechnologie: technologie.fields.nomTechnologie,
            numTechnologie: technologie.fields.numTechnologie,
          });
        }
        this.setState({ loading: false, technologies });
      })
      .catch((error) => {
        this.openSnackBar(
          'Erreur lors du chargement des technologies, veuillez rafraichir la page.',
          'error'
        );
      });
  }

  private createTechnologie = () => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_TECHNOLOGIES +
      '/items';
    const data = {
      nomTechnologie: this.state.nameTechnologie,
      numTechnologie: this.state.maxNumTechnologies,
    };

    API.post(url, { fields: data })
      .then(() => this.successCreateTechno())
      .catch((error) => {
        this.openSnackBar(
          "La technologie n'a pas été ajoutée, veuillez recommencer.",
          'error'
        );
      });
  };

  private successCreateTechno = () => {
    this.refreshData();
    this.openSnackBar(
      'La technologie ' + this.state.nameTechnologie + ' a bien été ajoutée',
      'success'
    );
  };

  private deleteTechnologie = (technologie: ITechnologie) => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_TECHNOLOGIES +
      '/items/' +
      technologie.id;

    API.delete(url)
      .then(() => this.successDeleteTechno(technologie))
      .catch((error) => {
        this.openSnackBar(
          'La suppression de la technologie' +
            technologie.nomTechnologie +
            " n'a pas été effectuée, veuillez recommencer",
          'error'
        );
      });
  };

  private successDeleteTechno = (technologie: ITechnologie) => {
    this.refreshData();
    this.openSnackBar(
      'La technologie ' + technologie.nomTechnologie + ' a bien été supprimée.',
      'success'
    );
  };

  private refreshTable = (filter: string) => {
    const technologiesFilter: ITechnologie[] = this.state.technologies.filter(
      (item) =>
        item.nomTechnologie
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
    );
    this.setState({ technologiesFilter });
  };

  private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter: boolean = event.currentTarget.value === '' ? false : true;
    this.setState({ filter });
    this.refreshTable(event.currentTarget.value);
  };

  private onChangeNameTechnologie = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ nameTechnologie: event.currentTarget.value });
  };

  private openSnackBar(
    messageSnackBar: string,
    typeSnackBar: 'success' | 'warning' | 'error' | 'info'
  ) {
    this.setState({ openSnackBar: true, typeSnackBar, messageSnackBar });
  }

  private onCloseSnackBar = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    reason: string
  ) => {
    this.setState({ openSnackBar: false });
  };

  private onClickSnackBar = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ openSnackBar: false });
  };
}

export default withStyles(styles)(ManageTechnologie);
