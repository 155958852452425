import IMemberProject from "../interfaces/member-project";
import Lookup from '../interfaces/lookup';

export default class MemberProject implements IMemberProject {
    public nomMembre: string;
    public numProfils: Lookup[];
    public numProjetLookupId: number;
    public id: number;

    constructor() {
        this.nomMembre = '';
        this.numProfils = [];
        this.numProjetLookupId = 0;
        this.id = 0;
    }

    public hasProfil(profil: Lookup): boolean {
        return this.numProfils.find(value => value.LookupId === profil.LookupId) ? true : false;
    }
}