import React, { Component } from 'react';
import Axios, { AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';
import { Button, FormLabel, Grid, Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Moment from 'moment';
import moment from 'moment';
import 'moment/locale/fr';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import utf8 from 'utf8';
import API from '../../services/Api';
import Auth from '../../services/Auth';
import { SharePointDataService } from '../../services/SharePointDataService';
import ActiviteExport from '../../interfaces/activite-export';
import { IProject } from '../../interfaces/project';
import 'react-widgets/dist/css/react-widgets.css';
import { env } from 'process';
import LoadingButton from '../LoadingButton';

const styles = (theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(),
        },
    });

type SnackBarType = 'success' | 'error' | 'warning' | 'info';

interface IExportProps extends WithStyles<typeof styles> {
    dateStart: Date;
    dateEnd: Date;
    projects: IProject[];
    setSnackBar: (
        open: boolean,
        snackBarType?: SnackBarType,
        snackBarMessage?: string
    ) => void;
    onExportingCRAM: () => void;
    onExportedCRAM: () => void;
    noActivitesOnExport: () => void;
}

interface IExportState {
    userAccess: string;
    debut: moment.Moment;
    dateDebutChanged: boolean;
    datefinChanged: boolean;
    fin: moment.Moment;
    activites: ActiviteExport[];
    name: string;
}

class Export extends Component<IExportProps, IExportState> {
    private auth: Auth;

    constructor(props: Readonly<IExportProps>) {
        super(props);

        this.state = {
            userAccess: '',
            activites: [],
            dateDebutChanged: false,
            datefinChanged: false,
            debut: moment(this.props.dateStart, 'YYYY-MM-DD'),
            fin: moment(this.props.dateEnd, 'YYYY-MM-DD'),
            name: 'CRAM ',
        };

        this.auth = new Auth();
        this.getUserAccess();
        Moment.locale('fr');
        momentLocalizer();
    }

    private getUserAccess() {
        SharePointDataService.getAccessLevel().then((accessLevel) => {
            this.setState({ userAccess: accessLevel });
        });
    }

    private exportCramToutBSD = () => {
        // Variables
        var debut: Moment.Moment = this.state.debut;
        var fin: Moment.Moment = this.state.fin;

        // Setting date parameters
        if (!this.state.dateDebutChanged) {
            debut = moment(this.props.dateStart, 'YYYY-MM-DD');
        }
        if (!this.state.datefinChanged) {
            fin = moment(this.props.dateEnd, 'YYYY-MM-DD');
        }

        // Period name
        const namePeriod =
            debut.isSame(fin, 'month') &&
            debut.isSame(fin, 'year') &&
            debut.date() === 1 &&
            debut.daysInMonth() === fin.date()
                ? fin.format('MMMM YYYY').charAt(0).toUpperCase() +
                  fin.format('MMMM YYYY').slice(1)
                : 'du ' + debut.format('L') + ' au ' + fin.format('L');
        const exportFilename = 'CRAM tout BSD ' + namePeriod + '.xlsx';

        const azureFunctionUrl = process.env.REACT_APP_AZ_EXPORTACTIVITIES_URL;
        const azureFunctionKey = process.env.REACT_APP_AZ_EXPORTACTIVITIES_KEY;
        const url = azureFunctionUrl;
        const headers = {
            'Content-Type': 'application/json',
            'x-functions-key': azureFunctionKey,
        };

        const options: AxiosRequestConfig = {
            responseType: 'blob',
            headers,
            method: 'POST',
            data: {
                startDate: debut.format('YYYY-MM-DD'),
                endDate: fin.format('YYYY-MM-DD'),
            },
            url,
        };
        return Axios(options)
            .then((response) => {
                saveAs(response.data, exportFilename);
            })
            .catch((error) => {
                // Error notification in UI
                this.props.setSnackBar(
                    true,
                    'error',
                    "Une erreur est survenue lors de l'exportation"
                );

                console.log(error);
            });
    };

    private cramDataSuccess(response: any) {
        // Variables
        var debut: Moment.Moment = this.state.debut;
        var fin: Moment.Moment = this.state.fin;

        // Setting date parameters
        if (!this.state.dateDebutChanged) {
            debut = moment(this.props.dateStart, 'YYYY-MM-DD');
        }
        if (!this.state.datefinChanged) {
            fin = moment(this.props.dateEnd, 'YYYY-MM-DD');
        }

        // Period name
        const namePeriod =
            debut.isSame(fin, 'month') &&
            debut.isSame(fin, 'year') &&
            debut.date() === 1 &&
            debut.daysInMonth() === fin.date()
                ? fin.format('MMMM YYYY').charAt(0).toUpperCase() +
                  fin.format('MMMM YYYY').slice(1)
                : 'du ' + debut.format('L') + ' au ' + fin.format('L');
        const exportFilename = 'CRAM tout BSD ' + namePeriod + '.xlsx';

        // Save file
        saveAs(response.data, exportFilename);

        // Success notification in UI
        this.props.onExportedCRAM();
        this.props.setSnackBar(true, 'success', 'Exportation terminée');
    }

    private cramDataError(error: any) {
        console.log(error);
        // Error notification in UI
        this.props.setSnackBar(
            true,
            'error',
            "Une erreur est survenue lors de l'exportation"
        );
    }

    public componentWillReceiveProps(newProps: IExportProps) {
        const oldProps = this.props;

        if (oldProps.dateStart !== newProps.dateStart) {
            this.setState({ dateDebutChanged: false });
        }

        if (oldProps.dateEnd !== newProps.dateEnd) {
            this.setState({ datefinChanged: false });
        }
    }

    public render() {
        const { classes } = this.props;
        let debut: Date = this.state.debut.toDate();
        let fin: Date = this.state.fin.toDate();

        if (!this.state.dateDebutChanged) {
            debut = this.props.dateStart;
        }

        if (!this.state.datefinChanged) {
            fin = this.props.dateEnd;
        }

        return (
            <React.Fragment>
                <Typography variant="h5" gutterBottom>
                    <ImportExportIcon color="primary" fontSize="small" />{' '}
                    Exporter mon activité
                </Typography>
                <form className={classes.margin}>
                    <Grid container justify="flex-start" alignItems="baseline">
                        <Grid item md={1} xs={1} style={{ textAlign: 'center' }}>
                            <FormLabel>
                                Du
                            </FormLabel>
                        </Grid>
                        <Grid item md={5} xs={11}>
                            <DateTimePicker
                                culture={'fr'}
                                time={false}
                                value={debut}
                                max={fin}
                                onChange={this.onChangeDebut}
                                inputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item md={1} xs={1} style={{ textAlign: 'center' }}>
                            <FormLabel>
                                Au
                            </FormLabel>
                        </Grid>
                        <Grid item md={5} xs={11}>
                            <DateTimePicker
                                culture={'fr'}
                                time={false}
                                value={fin}
                                min={debut}
                                onChange={this.onChangeFin}
                                inputProps={{ readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.buttons}>
                        {this.state.userAccess == 'superviseur' && (
                            <LoadingButton
                                text="Exporter CRAM BSD"
                                beforeJob={() => {
                                    this.props.setSnackBar(
                                        true,
                                        'info',
                                        'Exportation en cours...'
                                    );
                                }}
                                jobFunction={this.exportCramToutBSD}
                                successHandler={() => {
                                    this.cramDataSuccess;
                                }}
                                errorHandler={() => {
                                    this.cramDataError;
                                }}
                                className={classes.button}
                            />
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.downloadExport}
                            className={classes.button}
                        >
                            Exporter mon CRAM
                        </Button>
                    </div>
                </form>
            </React.Fragment>
        );
    }

    private onChangeDebut = (date?: Date, dateStr?: string) => {
        this.setState({
            dateDebutChanged: true,
            debut: moment(date, 'YYYY-MM-DD'),
        });
    };

    private onChangeFin = (date?: Date, dateStr?: string) => {
        this.setState({
            datefinChanged: true,
            fin: moment(date, 'YYYY-MM-DD'),
        });
    };

    private downloadExport = (): void => {
        const promises: Array<Promise<ActiviteExport[]>> = [];
        const username = this.auth.getAccount().name;
        const exportURL = process.env.REACT_APP_AZ_EXPORTCRAM_URL || '';
        let debut: moment.Moment = this.state.debut;
        let fin: moment.Moment = this.state.fin;

        this.props.onExportingCRAM();

        if (!this.state.dateDebutChanged) {
            debut = moment(this.props.dateStart, 'YYYY-MM-DD');
        }

        if (!this.state.datefinChanged) {
            fin = moment(this.props.dateEnd, 'YYYY-MM-DD');
        }

        for (const project of this.props.projects) {
            promises.push(this.getActivitiesByProject(debut, fin, project));
        }

        // ajout des activites Autres
        promises.push(this.getActivitiesByProject(debut, fin));

        const namePeriod =
            debut.isSame(fin, 'month') &&
            debut.isSame(fin, 'year') &&
            debut.date() === 1 &&
            debut.daysInMonth() === fin.date()
                ? fin.format('MMMM YYYY').charAt(0).toUpperCase() +
                  fin.format('MMMM YYYY').slice(1)
                : 'du ' + debut.format('L') + ' au ' + fin.format('L');
        const exportFilename = 'CRAM ' + username + ' ' + namePeriod + '.xlsx';

        Promise.all(promises)
            .then((activities: ActiviteExport[][]) => {
                const activites: ActiviteExport[] = activities.reduce(
                    (prev, next) => prev.concat(next)
                );

                const url = exportURL + '?name=' + username;
                const headers = {
                    'Content-Type': 'application/json',
                    'x-functions-key': process.env.REACT_APP_AZ_EXPORTCRAM_KEY,
                };
                const data = activites.sort(this.compare);
                const options: AxiosRequestConfig = {
                    responseType: 'blob',
                    data,
                    headers,
                    method: 'POST',
                    url,
                };

                if (activites.length !== 0) {
                    Axios(options).then((response) => {
                        this.props.onExportedCRAM();
                        saveAs(response.data, exportFilename);
                    });
                } else {
                    this.props.noActivitesOnExport();
                }
            })
            .catch((error) => {
                // TO DO handle error
            });
    };

    private async getActivitiesByProject(
        debut: moment.Moment,
        fin: moment.Moment,
        project?: IProject
    ): Promise<ActiviteExport[]> {
        const url =
            'sites/' +
            process.env.REACT_APP_SITEID +
            '/lists/' +
            (project ? project.idList : process.env.REACT_APP_LISTID_AUTRES) +
            '/items?';
        const top = 'top=5000&';
        const expand =
            'expand=fields(select=Title,Jour,Temps,Meridiem,TypeProjet,Commentaire,Profil,Phase,PNum,PObjet,NumProjet,NumClient,NomClient,Entreprise,Nom,Prenom,Technologie,ClientFinal,Activite_x003a_Titre)';
        const response = await API.get(url + top + expand);
        const activites: ActiviteExport[] = [];

        for (const activity of response.data.value) {
            const fields = activity.fields;
            const date = moment(fields.Jour, 'YYYY-MM-DD');
            const user = activity.createdBy.user.displayName;

            if (
                user === this.auth.getAccount().name &&
                (date.isBetween(debut, fin, 'day') ||
                    date.isSame(debut, 'day') ||
                    date.isSame(fin, 'day'))
            ) {
                activites.push({
                    Entreprise: fields.Entreprise,
                    Nom: fields.Nom,
                    Prénom: fields.Prenom,
                    Année: moment(date).format('YYYY'),
                    Mois:
                        moment(date).format('MMMM').charAt(0).toUpperCase() +
                        moment(date).format('MMMM').slice(1),
                    Date: moment(fields.Jour, 'YYYY-MM-DD').format('L'),
                    Créneau: fields.Meridiem,
                    Imputation: fields.TypeProjet,
                    Client: fields.NomClient,
                    'Client Final': fields.ClientFinal,
                    'Numéro Propale': fields.PNum,
                    'Objet Propale': fields.PObjet,
                    'Numéro Client': fields.NumClient,
                    Activité: fields.Activite_x003a_Titre,
                    'Projet Client': fields.Title,
                    'Phase Projet Client': fields.Phase,
                    Technologie: fields.Technologies,
                    Profil: fields.Profil,
                    Charge: +fields.Temps,
                    Commentaires: fields.Commentaire,
                });
            }
        }

        return activites;
    }

    private compare(a: ActiviteExport, b: ActiviteExport): number {
        if (
            moment(a.Date, 'DD/MM/YYYY').isBefore(moment(b.Date, 'DD/MM/YYYY'))
        ) {
            return -1;
        }
        if (
            moment(a.Date, 'DD/MM/YYYY').isAfter(moment(b.Date, 'DD/MM/YYYY'))
        ) {
            return 1;
        }
        if (a.Créneau < b.Créneau) {
            return -1;
        }
        if (a.Créneau > b.Créneau) {
            return 1;
        }
        return 0;
    }
}

export default withStyles(styles)(Export);
