import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles'

// Styles
const styles = (theme: Theme) =>
    createStyles({
        progress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: '-12px'
        }
    })

interface ILoadingButtonState {
    loading: boolean;
    success: boolean;
}

interface ILoadingButtonProps extends WithStyles<typeof styles> {
    text: string;
    successHandler: (message: any) => void;
    errorHandler: (message: any) => void;
    beforeJob : () => void;
    jobFunction : () => Promise<any>;
    className : string | undefined;
}

class LoadingButton extends React.Component<ILoadingButtonProps, ILoadingButtonState> {
    constructor(props : ILoadingButtonProps) {
        super(props);
        this.state = {
            loading: false,
            success: false
        };
    }

    private handleButtonClick = () => {

        // Before job
        this.props.beforeJob();

        this.setState({
            loading: true
        });
        this.props.jobFunction()
        .then(response => {
            this.setState({
                loading: false,
                success: true
            });

            // Notify success for parent
            this.props.successHandler(response);
        })
        .catch(error => {
            this.setState({
                loading: false,
                success: false
            });

            // Notify error for parent
            this.props.errorHandler(error);
        })
    }

    public render() {
        const { classes } = this.props
        const buttonSx = {
            ...(this.state.success && {
              bgcolor: green[500],
              '&:hover': {
                bgcolor: green[700],
              },
            }),
          };
        
          return (
            <Box sx={{ position: 'relative' }}>
                <Button
                    {
                        ...{
                            variant     : "contained",
                            disabled    : this.state.loading,
                            className   : this.props.className,
                            color       : "primary",
                            onClick     : this.handleButtonClick
                        }
                    }
                    
                >
                    {this.props.text}
                </Button>
                {this.state.loading && (
                    <CircularProgress
                        size={24}
                        className={classes.progress}
                    />
                )}
            </Box>
          );
    }

}

export default withStyles(styles)(LoadingButton);