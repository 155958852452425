import React, { Component } from 'react';

// import {
//   IconButton,
//   InputBase,
//   Paper,
//   TableCell,
//   TableRow,
//   Typography,
// } from '@material-ui/core';
// import {
//   createStyles,
//   Theme,
//   withStyles,
//   WithStyles,
// } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import TableDisplay from '../../Table/TableDisplay';

import { IProject } from '../../../interfaces/project';

import ActiviteConso from '../../../interfaces/activite-conso';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles, withStyles, WithStyles } from '@material-ui/styles';
import {
    IconButton,
    InputBase,
    Paper,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
// import MaterialTable from 'material-table';

import {
    service,
    factories,
    models,
    IEmbedConfiguration,
} from 'powerbi-client';
import { useStyles } from '@material-ui/pickers/views/Calendar/Day';
import PowerBiService from '../../../services/PowerBiService';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        search: {
            flexGrow: 1,
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(6),
        },
        input: {
            marginLeft: theme.spacing,
            marginRight: theme.spacing,
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        paper: {
            position: 'absolute',
            width: theme.spacing(50),
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: 'none',
        },
        pbi: {
            width: '100%',
            height: '800px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    activities: ActiviteConso[];
    project: IProject | null;
}

interface ConsumptionDisplayState {
    filter: string;
    activities: ActiviteConso[];
    project: IProject | null;
    embedUrl: string;
    accessToken: string;
    error: string[];
}

class ConsumptionDisplay extends Component<Props, ConsumptionDisplayState> {
    private columns: any[];
    private pbiService: PowerBiService;
    private embedContainer: HTMLElement | null = null;
    private service: service.Service;

    constructor(props: Readonly<Props>) {
        super(props);

        this.pbiService = PowerBiService.GetInstance();

        this.state = {
            filter: '',
            activities: this.props.activities,
            project: this.props.project,
            embedUrl: '',
            accessToken: '',
            error: [],
        };

        this.service = new service.Service(
            factories.hpmFactory,
            factories.wpmpFactory,
            factories.routerFactory
        );

        this.columns = [
            'Nom du projet',
            'Client',
            'N° de propales',
            'Phase',
            'Profil',
            'Date',
            'Meridiem',
            'Charge',
            'Service',
            'Facturabilité',
            'Technologie',
        ];
        this.columns = this.columns.map((column) => {
            return (
                <TableCell
                    component="th"
                    scope="row"
                    key={column}
                    align="center"
                >
                    {column}
                </TableCell>
            );
        });
    }

    public componentWillReceiveProps(newProps: Props) {
        const oldProps = this.props;

        if (oldProps.activities !== newProps.activities) {
            this.setState({ activities: newProps.activities });
        }
    }

    public componentDidMount() {
        const thisInstance = this;
        this.pbiService.ready.then(function () {
            thisInstance.pbiService
                .getEmbedUrl()
                .then(function (url: string) {
                    const accessToken =
                        thisInstance.pbiService.getAccessToken();
                    // config object
                    const config = {
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: process.env.REACT_APP_PBI_REPORT_ID,
                        embedUrl: url,
                        accessToken: accessToken,
                        tokenType: models.TokenType.Aad,
                    };

                    thisInstance.service.embed(
                        thisInstance.embedContainer!,
                        config
                    );
                })
                .catch(function (error: any) {
                    const errorArray = [
                        "Erreur lors de la récupération de l'url d'imbrication",
                    ];
                    errorArray.push(error.message);
                    thisInstance.setState({ error: errorArray });
                });
        });
    }

    public render() {
        const { classes } = this.props;
        const thisInstance = this;
        if (this.state.error.length > 0) {
            this.embedContainer!.textContent = '';
            this.state.error.forEach(function (line) {
                thisInstance.embedContainer!.appendChild(
                    document.createTextNode(line)
                );
                thisInstance.embedContainer!.appendChild(
                    document.createElement('br')
                );
            });
        }
        return (
            <div className={classes.layout}>
                <div
                    id="embedContainer"
                    className="embedContainer"
                    powerbi-type="report"
                    style={{
                        height: '800px',
                        maxWidth: '1500px',
                        margin: 'auto',
                    }}
                    ref={(div) => {
                        if (div) {
                            this.embedContainer = div;
                        }
                    }}
                />
            </div>
        );
    }

    private refreshTable = (filter: string) => {
        let activities: ActiviteConso[] = this.props.activities;

        if (filter !== '') {
            activities = activities.filter(
                (item) =>
                    item.nomProjet
                        .toLocaleLowerCase()
                        .includes(filter.toLocaleLowerCase()) ||
                    item.nomClient
                        .toLocaleLowerCase()
                        .includes(filter.toLocaleLowerCase()) ||
                    item.numClient
                        .toLocaleLowerCase()
                        .includes(filter.toLocaleLowerCase())
            );
        }

        this.setState({ activities });
    };

    private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.refreshTable(event.currentTarget.value);
        this.setState({ filter: event.currentTarget.value });
    };
}

export default withStyles(styles)(ConsumptionDisplay);
