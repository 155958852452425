import React, { Component } from 'react';

import {
  Chip,
  IconButton,
  InputBase,
  Paper,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import API from '../../services/Api';

import TableCellDelete from '../Table/TableCellDelete';
import TableDisplay from '../Table/TableDisplay';

import { IClient } from '../../interfaces/client';

const styles = (theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    search: {
      flexGrow: 1,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      marginBottom: theme.spacing(2),
    },
    input: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  });

interface IProps extends WithStyles<typeof styles> {
  clients: IClient[];
  nbClients: number;
  clientDeleted: any;
}

interface IState {
  clients: IClient[];
}

class DisplayClient extends Component<IProps, IState> {
  private columns: any[];

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      clients: this.props.clients,
    };

    this.columns = [
      'Nom du client',
      'Numéro du client',
      'Client final',
      'Supprimer',
    ];
    this.columns = this.columns.map((column) => {
      return (
        <TableCell component="th" scope="row" key={column} align="center">
          {column}
        </TableCell>
      );
    });
  }

  public componentWillReceiveProps(newProps: IProps) {
    const oldProps = this.props;

    if (oldProps.clients !== newProps.clients) {
      this.setState({ clients: newProps.clients });
    }
  }

  public render() {
    const { classes } = this.props;
    const rows: any[] = [];
    let i = 0;

    for (const client of this.state.clients) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const isNew = new Date(client.createdDateTime) >= yesterday;

      rows.push(
        <TableRow key={i}>
          <TableCell component="th" scope="row" align="center">
            {client.nomClient}{' '}
            {isNew && (
              <Chip
                label="Nouveau"
                color="secondary"
                className={classes.chip}
              />
            )}
          </TableCell>
          <TableCell align="center">{client.numClient}</TableCell>
          <TableCell align="center">{client.compte}</TableCell>
          <TableCellDelete
            value={client}
            onDeleteClick={this.callDeleteClient}
          />
        </TableRow>
      );

      i++;
    }

    return (
      <div className={classes.layout}>
        <Paper className={classes.search} elevation={1}>
          <InputBase
            className={classes.input}
            placeholder="Rechercher..."
            onChange={this.onChangeFilter}
            fullWidth
          />
          <IconButton className={classes.iconButton} aria-label="Search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <TableDisplay columns={this.columns} rows={rows} />
      </div>
    );
  }

  private refreshTable = (filter: string) => {
    let clients: IClient[] = this.props.clients;

    if (filter !== '') {
      clients = clients.filter(
        (item) =>
          item.nomClient
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.numClient
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          item.compte.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    }

    this.setState({ clients });
  };

  private callDeleteClient = (client: IClient) => {
    const url =
      'sites/' +
      process.env.REACT_APP_SITEID +
      '/lists/' +
      process.env.REACT_APP_CLIENTS +
      '/items/' +
      client.id;

    API.delete(url)
      .then(() => this.props.clientDeleted())
      .catch((error) => {
        // TO DO handle error
      });
  };

  private onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.refreshTable(event.currentTarget.value);
  };
}

export default withStyles(styles)(DisplayClient);
