import API from './Api';

import { Client } from '../classes/client';

import { IClient } from '../interfaces/client';
import { IProject } from '../interfaces/project';
import { ITechnologie } from '../interfaces/technologie';
import IPropale from '../interfaces/IPropale';

export class SharePointDataService {
  public static getProjects = async (
    filter: string = ''
  ): Promise<IProject[]> => {
    const projects: IProject[] = [];
    let url =
      SharePointDataService.listsURL +
      process.env.REACT_APP_MEMBRES +
      '/items?';
    let expand =
      'expand=fields(select=nomMembre,numProjetLookupId,numProfils_x003a_nomProfil)';

    const response = await API.get(url + expand + filter);

    url =
      SharePointDataService.listsURL +
      process.env.REACT_APP_PROJETS +
      '/items?';
    expand =
      'expand=fields(select=ID,NumProjet,PNums,PNums_x003a_PObjet,Title,NumClient,NumClientId,NumClient_x003a_Title,numProfils,numPhases_x003a_nomPhase,enabled,IdTeams,idList,NumClient_x003a_Compte,Technologie)';

    const responseProjects = await API.get(url + expand);

    for (const projectMember of response.data.value) {
      for (const project of responseProjects.data.value) {
        const fields = project.fields;

        if (
          fields.enabled &&
          fields.id === projectMember.fields.numProjetLookupId
        ) {
          const fooProject: IProject = {
            clientFinal: fields.NumClient_x003a_Compte,
            enabled: fields.enabled,
            idClient: +fields.NumClientId,
            idList: fields.idList,
            idProjet: +fields.id,
            idTeams: fields.IdTeams,
            nomClient: fields.NumClient_x003a_Title,
            nomProjet: fields.Title,
            numClient: fields.NumClient,
            numProjet: fields.NumProjet,
            numPropales: fields.PNums,
            titlePropales: fields.PNums_x003a_PObjet || [],
            phases: fields.numPhases_x003a_nomPhase,
            profils: projectMember.fields.numProfils_x003a_nomProfil,
            technologie: fields.Technologie,
            typeProjet: '',
            phasesLoaded: false,
          };

          projects.push(fooProject);
        }
      }
    }

    return projects.sort((a: IProject, b: IProject) =>
      a.nomClient > b.nomClient ? 1 : -1
    );
  };

  public static getClients = async (): Promise<IClient[]> => {
    const url =
      SharePointDataService.listsURL +
      process.env.REACT_APP_CLIENTS +
      '/items?';
    const expand =
      'expand=fields(select=ID,Title,Compte,NumClient,createdDateTime)';

    return await API.get(url + expand).then((response) => {
      const clients: IClient[] = [];

      for (const item of response.data.value) {
        clients.push({
          compte: item.fields.Compte,
          createdDateTime: item.createdDateTime,
          id: +item.fields.id,
          nomClient: item.fields.Title,
          numClient: item.fields.NumClient,
        });
      }

      return clients.sort(Client.compare);
    });
  };

  public static getTechnologies = async (): Promise<ITechnologie[]> => {
    const url =
      SharePointDataService.listsURL +
      process.env.REACT_APP_TECHNOLOGIES +
      '/items?';
    const expand =
      'expand=fields(select=id,createdDateTime,numTechnologie,nomTechnologie)';

    return await API.get(url + expand).then((response) => {
      const technologies: ITechnologie[] = [];

      for (const technologie of response.data.value) {
        technologies.push({
          createdDateTime: technologie.createdDateTime,
          id: +technologie.fields.id,
          numTechnologie: technologie.fields.numTechnologie,
          nomTechnologie: technologie.fields.nomTechnologie,
        });
      }

      return technologies;
    });
  };

  public static async getPropaleById(idPropale : number) {
    const url = SharePointDataService.listsURL + process.env.REACT_APP_PROPALES + `/items('${idPropale}')?`;
    const expand = "expand=fields(select=id,Title,enabled,PObjet)"

    return await API.get(url + expand).then((response) => {
      const result : IPropale = {
        id : response.data.id,
        Title : response.data.fields.Title,
        enabled : response.data.fields.enabled,
        PObjet : response.data.fields.PObjet,
      }
      return result;
    })
  }

  public static changePropaleStatus(idPropale : number, status : boolean) {
    return API.patch(SharePointDataService.listsURL + process.env.REACT_APP_PROPALES + `/items('${idPropale}')`, {
      fields : {
        enabled : status
      }
    });
  }

  public static getActivePropales(ids : number[]): Promise<IPropale[]> {
    const propales = ids.map(id => {
      const url = SharePointDataService.listsURL + process.env.REACT_APP_PROPALES + `/items('${id}')`;
      const expand = "?expand=fields(select=id,Title,enabled,PObjet)"
      const filter = "&?filter=fields/enabled eq true"
      return API.get(url + expand + filter).then(response => {
        return {
          id : response.data.id,
          Title : response.data.fields.Title,
          enabled : response.data.fields.enabled,
          PObjet : response.data.fields.PObjet,
        }
      }) as Promise<IPropale>;
    })

    return Promise.all(propales).then((propales: IPropale[]) => {
      const activePropales = propales.filter(propale => propale.enabled);
      return activePropales;
    });
  }

  public static async getAccessLevel(): Promise<string> {
    const url = "me/memberOf?select=displayName";

    return API.get(url).then((response: { data: { value: string | any[] } }) => {
      let accessLevel = "contributeur";
      let cpt = 0;
      let stop = false;

      while (cpt < response.data.value.length && !stop) {
        const displayName = response.data.value[cpt].displayName;

        if (displayName === "GTA Superviseurs") {
          accessLevel = "superviseur";
          stop = true;
        } else if (
          displayName === "GTA Managers" &&
          (accessLevel === "contributeur" || accessLevel === "cdp")
        ) {
          accessLevel = "manager";
        } else if (
          displayName === "GTA Chefs de projets" &&
          accessLevel === "contributeur"
        ) {
          accessLevel = "cdp";
        }
        cpt++;
      }
      return accessLevel;
    });
  }

  public static async updateProject(projectId: number, properties: any) {
    return API.patch(SharePointDataService.listsURL + process.env.REACT_APP_PROJETS + `/items('${projectId}')`, {
      fields: properties,
    });
  }

  public static async getProjectMembers(projectId: number) {
    const expand : string = "expand=fields(select=Id,numProjet,nomMembre,niveauAccess,numProfils_x003a_nomProfil, )";
    const filter : string = "&filter=fields/numProjetLookupId eq " + projectId;

    return API.get(
      SharePointDataService.listsURL + 
      process.env.REACT_APP_MEMBRES + "/items?" + 
      expand + 
      filter
    );
  } 

  private static listsURL = 'sites/' + process.env.REACT_APP_SITEID + '/lists/';
}
